import React from "react";
import {
  BarcodeScanner,
  enableBarcodeScanner,
  disableBarcodeScanner,
  setHistoryInfo,
} from "react-usb-barcode-scanner";
import { connect } from "react-redux";
import config from "../../config";
import { useEffect, useState } from "react";

const regex = /\n|\r\n|\n\r|\r/gm;
const defaultUserName = "John Doe";

const Scanner2 = (props) => {
  const getHtml = (data) => data.replace(regex, "<br>");
  const { setScanCode } = props;
  console.log("props?.data", props?.data);
  const [barcodeScannerState, setBarcodeScannerState] = useState("enabled");
  const [showHistory, setShowHistory] = useState(false);
  const [inputState, setInputState] = useState("");
  const [userName, setUserName] = useState(defaultUserName); // this user works with scanner

  useEffect(() => {
    props.setHistoryInfo({
      username: defaultUserName,
    });
  }, []); // once on mount
  useEffect(() => {
    setScanCode(props?.data);
  }, [props]); // once on mount

  const handleChange = (e) => {
    if (e.target.value === "enabled") {
      props.enableBarcodeScanner();
    } else {
      props.disableBarcodeScanner();
    }
    setBarcodeScannerState(e.target.value);
  };
  return (
    <div className="App">
      <div
        className="App-scanning-container"
        style={{
          visibility: props.isBusy ? "visible" : "hidden",
          opacity: props.isBusy ? 1 : 0,
        }}
      >
        <div className="App-scanning-center">
          <div className="App-scanning">Scanning...</div>
        </div>
      </div>

      <div className="App-info">
        <BarcodeScanner config={config} />

        {/* {props.isBusy ? (
          <p>Scanning...</p>
        ) : props.data === "" ? (
          <p>Use barcode scanner</p>
        ) : (
          <>
            <p>You are scanned:</p>
            <div
              className="App-result"
              dangerouslySetInnerHTML={{ __html: getHtml(props.data) }}
            />
          </>
        )} */}
      </div>
    </div>
  );
};

const mapStateToProps = ({ barcodeScanner: { isBusy, data, history } }) => ({
  isBusy,
  data,
  barcodeHistory: history,
});

export default connect(mapStateToProps, {
  enableBarcodeScanner,
  disableBarcodeScanner,
  setHistoryInfo,
})(Scanner2);

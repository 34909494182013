import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { showToast } from "../../utils/ToastHelper";
import axios from "axios";
import { BASE_URL } from "./Url";

const RedeemPoint = ({ state, setState, point, number ,success, setSuccess}) => {
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(false);
  const [pointValue,setPointValue] = useState(0);
  const verifyPoint = async () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/redeem-point/`;
    setIsloading(true);
    setError(null);
    setSuccess(false);

    try {
      const res = await axios.post(url, {
        point: parseInt(pointValue),
        mobile: number,
      });
      if (res.data.status) {
        showToast("success", "Customer Verified.");
        setState(pointValue);
        setSuccess(true);
      }
    } catch (err) {
      let message = "An error occurred";
      if (err.response && err.response.data) {
        const errorData = err.response.data;
        if (errorData.errors && errorData.errors.message) {
          message = errorData.errors.message;
        } else if (errorData.message) {
          message = errorData.message;
        }
      }
      setError(message);
      setSuccess(false);
      showToast("error", message);
    } finally {
      setIsloading(false);
    }
  };
  
  return (
    <div className="col-12 col-md-6">
      <p>Redeem Point </p>
      <div className="d-flex position-relative ">
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="Username"
            type="number"
            name="redeem_point"
            disabled={success}
            value={pointValue}
            onChange={(e) => setPointValue(e.target.value)}
            aria-describedby="basic-addon1"
            style={{ backgroundColor: "#FAFAFA" }}
            min={100}
            max={point}
            step={50}
          />
        </InputGroup>

        <div className="ms-2 mb-3 d-flex gap-1">
          {success ? (
            <>
              <button disabled className="btn btn-small btn-success">
                Verified
              </button>
              <button
                onClick={() => {
                  setState(0);
                  setPointValue(0)
                  setError("");
                  setSuccess(false);
                }}
                className="btn btn-small btn-danger"
              >
                Reset
              </button>
            </>
          ) : (
            <>
              {isLoading ? (
                <button disabled className="btn btn-small btn-danger">
                  Verifying...
                </button>
              ) : (
                <button
                  onClick={verifyPoint}
                  className="btn btn-small btn-danger"
                >
                  Verify
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {success && (
        <>
          <span className="text-primary" style={{ fontSize: "14px" }}>
            You will get{" "}
          </span>
          <span
            className="text-dark"
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            {state} TK{" "}
          </span>
          <span className="text-primary" style={{ fontSize: "14px" }}>
            discount against your{" "}
          </span>

          <span
            className="text-dark"
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            {state}{" "}
          </span>
          <span className="text-primary" style={{ fontSize: "14px" }}>
            point .
          </span>
        </>
      )}
    </div>
  );
};

export default RedeemPoint;

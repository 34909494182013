(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-redux"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactRedux"], factory);
	else if(typeof exports === 'object')
		exports["react-usb-barcode-scanner"] = factory(require("react"), require("react-redux"));
	else
		root["react-usb-barcode-scanner"] = factory(root["React"], root["ReactRedux"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_redux__) {
return 
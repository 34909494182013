import { showToast } from "../../utils/ToastHelper";
export const logout_func2 = (setOnlogoutHide,history) => {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("access_token");
  localStorage.removeItem("userData");
  setOnlogoutHide(1);
  showToast("success", "Invalid Token");

  history.push("/login");
};
export const logout_func3 = (history) => {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("access_token");
  localStorage.removeItem("userData");
//   showToast("success", "Invalid Token");
  history.push("/login");
};
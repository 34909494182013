export const filterMainState = (data) => {
    if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
        return [];
    }
    const result = [];

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const item = { ...data[key] };
            item.name = key;
            // Filter and modify the variants
            item.variant = item.variant.filter(variant => Number(variant.quantity) > 0);
            item.variant = item.variant.map(variant => {
                const { quantity, ...rest } = variant;
                return { ...rest, quantity: Number(quantity) };
            });

            // Modify variantObj
            item.variantObj = Object.fromEntries(
                Object.entries(item.variantObj)
                    .filter(([_, variant]) => Number(variant.quantity) > 0)
                    .map(([name, variant]) => {
                        const { quantity, ...rest } = variant;
                        return [name, { ...rest, quantity: Number(quantity) }];
                    })
            );

            // Remove thumb property
            delete item.thumb;
            result.push(item);
        }
    }

    return result;
}

import React from "react";
import RequiredLabel from "../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { handleInputs } from "../../utils/HandleInputs";

const InputComponent = ({
  label,
  setState,
  name,
  type,
  placeholder,
  required,
  value
}) => {
  return (
    <div className="col-12 col-md-6">
      {required ? <RequiredLabel text={label} /> : <p>{label}</p>}
      <div className="position-relative">
        <InputGroup className="mb-3">
          <Form.Control
            type={type}
            aria-label="Username"
            aria-describedby="basic-addon1"
            style={{ backgroundColor: "#FAFAFA" }}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={(e) => handleInputs(e, setState)}
          />
        </InputGroup>
      </div>
    </div>
  );
};

export default InputComponent;

import React from 'react'
import CustomInvoiceTable from '../invoice/CustomInvoiceTable'

const CustomInvoiceList = () => {
  return (
    <div>
        <CustomInvoiceTable/>
    </div>
  )
}

export default CustomInvoiceList
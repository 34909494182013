import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";

import Page404 from "../errorPages/Page404";
import ForgetPassword from "../forgetpass/ForgetPassword";
import ResetPassword from "../resetpass/ResetPassword";
import AddCategory from "../inventory/category/AddCategory";
import CategoryList from "../inventory/category/CategoryList";
import AddProduct from "../inventory/product/AddNewProduct";
import Attribute from "../inventory/product/Attribute";
import BulkImport from "../inventory/product/BulkImport";
import ProductList from "../inventory/product/ProductList";
import ViewProduct from "../inventory/product/ViewProduct";
import CreateInvoice from "../invoice/CreateInvoice";
import InvoiceList from "../invoice/InvoiceList";
import ViewInvoice from "../invoice/ViewInvoice";
import Login from "../login/Login";
import Notification from "../notification/Notification";
import Reports from "../Reports/Reports";
import AddGroup from "../user/AddGroup";
import AddRole from "../user/AddRole";
import AddUser from "../user/AddUser";
import UserList from "../user/UserList";
import ViewUser from "../user/ViewUser";
import CustomerList from "../customers/CustomerList";
import * as path from "./RoutePaths";
import { User } from "phosphor-react";
import DailySalesReport from "../Reports/DailySalesReport";
import UserProfile from "../user/UserProfile";
import EditCategory from "../inventory/category/EditCategory";
import EditInvoice from "../invoice/EditInvoice";
import EditProduct from "../inventory/product/EditProduct";
import EditUser from "../user/EditUser";
import EditCustomer from "../customers/EditCustomer";
import CustomerView from "../customers/CustomerView";
import AddCustomer from "../customers/AddCustomer";
import CustomerBulk from "../customers/CustomerBulk";
import CustomInvoiceList from "../Custom/CustomInvoiceList";
import CustomSalesReport from "../Custom/CustomSalesReport";
import CustomCreateInvoice from "../Custom/CustomCreateInvoice";
import CustomInvoiceView from "../Custom/CustomInvoiceView";
import CustomInvoiceEdit from "../Custom/CustomInvoiceEdit.jsx";
import Loader from "../CustomCommons/Loader";
// import Offer from "../Promotion/Offer";
// import Cupon from "../Promotion/Cupon";
// import Reviews from "../Promotion/Reviews";
// import ContactUs from "../Promotion/ContactUs";
// import GlobalSettings from "../WebSettings/GlobalSettings";
// import MainSettings from "../WebSettings/MainSettings";
// import TeamMember from "../WebSettings/TeamMember";
// import Testimonials from "../WebSettings/Testimonials";
// import AddOffer from "../Promotion/AddOffer";
// import EditOffer from "../Promotion/EditOffer";
// import ViewOffer from "../Promotion/ViewOffer";
// import AddCupon from "../Promotion/Cupon/AddCupon";
// import WorkWithUs from "../Promotion/WorkWithUs";
// import AddTestiminial from "../WebSettings/Testimonial/AddTestiminial";
// import EditTestimonial from "../WebSettings/Testimonial/EditTestimonial";
// import ViewTestimonial from "../WebSettings/Testimonial/ViewTestimonial";
// import AddTeamMember from "../WebSettings/Team/AddTeamMember";
// import EditTeamMember from "../WebSettings/Team/EditTeamMember";
// import AddVideo from "../WebSettings/Slider/AddVideo";
// import DisplaySettings from "../WebSettings/DisplaySettings";
// import AddDisplayCenter from "../WebSettings/DisplayCenter/AddDisplayCenter";
// import SocialMedia from "../WebSettings/SocialMedia";
// import AddMedia from "../WebSettings/Media/AddMedia";
// import EditMedia from "../WebSettings/Media/EditMedia";
// import EditCupon from "../Promotion/Cupon/EditCupon";
import EditWebInvoice from "../invoice/EditWebInvoice";
import ViewWebInvoice from "../invoice/ViewWebInvoice";
// import EditDisplayCenter from "../WebSettings/DisplayCenter/EditDisplayCenter";
// import EditVideo from "../WebSettings/Slider/EditVideo";
// import Banner from "../Promotion/Banner";
// import AddBanner from "../Promotion/Banner/AddBanner";
// import EditBanner from "../Promotion/Banner/EditBanner";
// import PurchaceInvoiceList from "../invoice/PurchaceInvoiceList";
// import AddPurchaceOrder from "../invoice/AddPurchaceOrder";
// import EditPurchaceOrder from "../invoice/EditPurchaceOrder";
import AreaInvoiceReport from "../Reports/AreaInvoiceReport";
// import PurchaceReport from "../Reports/PurchaceReport";
// import AddRequisition from "../invoice/AddRequisition";
// import EditRequisition from "../invoice/EditRequisition";
// import RequisitionList from "../invoice/RequisitionList";
// import RequisitionReport from "../Reports/RequisitionReport";
// import AddOutLet from "../Outlet/AddOutLet";
// import EditOutlet from "../Outlet/EditOutlet";
// import OutletList from "../Outlet/OutletList";
// import ViewOutlet from "../Outlet/ViewOutlet";
import AddReturnProduct from "../inventory/ReturnProduct/AddReturnProduct";
import ReturnProductList from "../inventory/ReturnProduct/ReturnProductList";
import EditReturnProduct from "../inventory/ReturnProduct/EditReturnProduct";
import ViewReturnProduct from "../inventory/ReturnProduct/ViewReturnProduct";
import CreateRefund from "../invoice/CreateRefund.jsx";
import CreateExchange from "../invoice/CreateExchange.jsx";
import ExchangeList from "../invoice/ExchangeList.jsx";
import CashReport from "../Reports/CashReport.jsx";
import ViewExchangeInvoice from "../invoice/ViewExchangeInvoice.jsx";
import OutletChalanList from "../OutletChalan/OutletChalanList.jsx";
import OutletChalanView from "../OutletChalan/OutletChalanView.jsx";
import DiscountReport from "../Reports/DiscountReport.jsx";
import ViewReturnChalan from "../inventory/ReturnProduct/ViewReturnChalan.jsx";
import AddRelocation from "../Relocation/AddRelocation.jsx";
import OutgoingList from "../Relocation/OutgoingList.jsx";
import IncommingList from "../Relocation/IncommingList.jsx";
import RelocationView from "../Relocation/RelocationView.jsx";
import SendRelocationView from "../Relocation/SendRelocationView.jsx";
import ProductReportFromDashboard from "../Reports/ProductReportFromDashboard.jsx";
import ReportFromInv from "../Reports/ReportFromInv.jsx";
import CreateInvoicelClub from "../invoice/CreateInvoiceLClub.js";
import CreateInvoiceModify from "../invoice/CreateInvoiceModify.jsx";

const LazyCustomer = React.lazy(() => import("../customers/CustomerList"));
const LazyProducts = React.lazy(() =>
  import("../inventory/product/ProductList")
);
const LazyDasboard = React.lazy(() => import("../dashboard/Dashboard"));
const LazyCategory = React.lazy(() =>
  import("../inventory/category/CategoryList")
);
const LazyInvoice = React.lazy(() => import("../invoice/InvoiceList"));
const LazyCustomInvoice = React.lazy(() =>
  import("../Custom/CustomInvoiceList")
);
const LazyUser = React.lazy(() => import("../user/UserList"));
const WebUser = React.lazy(() => import("../user/WebsiteUser"));

const RoutesFile = ({ setHideToolbar }) => {
  return (
    <div>
      {/* <Router> */}
      <Switch>
        <Route exact path={path.add_category}>
          <AddCategory />
        </Route>
        <Route exact path={path.category_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyCategory />
          </React.Suspense>
        </Route>
        {/* <Route exact path={path.category_list}>
          <CategoryList />
        </Route> */}
        <Route exact path={"/inventory/edit-category/:id"}>
          <EditCategory />
        </Route>
        <Route exact path={path.add_product}>
          <AddProduct />
        </Route>
        <Route exact path={"/inventory/edit-product/:id"}>
          <EditProduct />
        </Route>
        <Route exact path={path.product_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyProducts />
          </React.Suspense>
        </Route>
        {/* <Route exact path={path.product_list}>
          <ProductList />
        </Route> */}
        <Route exact path={"/inventory/product-view/:id"}>
          <ViewProduct />
        </Route>
        <Route exact path={path.add_attribute}>
          <Attribute />
        </Route>
        <Route exact path={path.bulk_import}>
          <BulkImport />
        </Route>
        <Route exact path={path.user_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyUser />
          </React.Suspense>
        </Route>
        <Route exact path={path.web_user_list}>
          <React.Suspense fallback={<Loader />}>
            <WebUser />
          </React.Suspense>
        </Route>
        {/* <Route exact path={path.user_list}>
          <UserList />
        </Route> */}
        <Route exact path={path.add_user}>
          <AddUser />
        </Route>
        <Route exact path={path.add_role}>
          <AddRole />
        </Route>
        <Route exact path={path.add_group}>
          <AddGroup />
        </Route>
        <Route exact path={"/user/user-View/:id"}>
          <ViewUser />
        </Route>
        <Route exact path={"/user/edit-user/:id"}>
          <EditUser />
        </Route>
        <Route exact path={"/user-profile"}>
          <UserProfile />
        </Route>

        <Route exact path={path.notification}>
          <Notification />
        </Route>
        {/* <Route exact path={path.create_invoice}>
          <CreateInvoice />
        </Route> */}
        <Route exact path={path.create_invoice}>
          <CreateInvoicelClub />
        </Route>
        <Route exact path={path.create_invoice_mo}>
          <CreateInvoiceModify />
        </Route>

        <Route exact path={path.invoice_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyInvoice />
          </React.Suspense>
        </Route>
        <Route exact path={path.create_refund}>
          <CreateRefund/>
        </Route>
        <Route exact path={path.create_exchange}>
          <CreateExchange/>
        </Route>
        <Route exact path={path.exchange_list}>
          <ExchangeList/>
        </Route>
        <Route exact path={"/sales/view-invoice/:id"}>
          <ViewInvoice />
        </Route>
        <Route exact path={"/sales/view-exchange-invoice/:id"}>
          <ViewExchangeInvoice />
        </Route>
        <Route exact path={"/sales/view-web-invoice/:id"}>
          <ViewWebInvoice />
        </Route>
        <Route exact path={"/sales/view-custom-invoice/:id"}>
          <CustomInvoiceView />
        </Route>
        <Route exact path={"/sales/edit-custom-invoice/:id"}>
          <CustomInvoiceEdit />
        </Route>
        <Route exact path={"/sales/edit-invoice/:id"}>
          <EditInvoice />
        </Route>
        <Route exact path={"/sales/edit-web-invoice/:id"}>
          <EditWebInvoice/>
        </Route>
        <Route exact path={path.customer_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyCustomer />
          </React.Suspense>
        </Route>
        <Route exact path={path.chalan_list}>
          <OutletChalanList/>
        </Route>
        <Route exact path={'/sales/chalan-view/:id'}>
          <OutletChalanView/>
        </Route>
        <Route exact path={path.sales_report_inv}>
          <ReportFromInv/>
        </Route>
        <Route exact path={path.sales_report_dash}>
          <ProductReportFromDashboard/>
        </Route>
        {/* <Route exact path={path.add_customer}>
          <AddCustomer />
        </Route>
        <Route exact path={path.customer_bulk_import}>
          <CustomerBulk />
        </Route>
        <Route exact path={"/customer/customer-view/:id"}>
          <CustomerView />
        </Route>
        <Route exact path={`/customer/edit-customer/:id`}>
          <EditCustomer />
        </Route> */}
        <Route exact path="/">
          <React.Suspense fallback={<Loader />}>
            <LazyDasboard />
          </React.Suspense>
        </Route>
        {/* <Route exact path="/">
          <Dashboard />
        </Route> */}
        <Route exact path={path.reports}>
          <Reports />
        </Route>
        <Route exact path={path.sales_report}>
          <DailySalesReport />
        </Route>
        <Route exact path={path.cash_report}>
          <CashReport/>
        </Route>
        <Route exact path={path.discount_report}>
          <DiscountReport/>
        </Route>
        <Route exact path={path.area_report}>
          <AreaInvoiceReport />
        </Route>

        {/* custom */}
        <Route exact path={path.custom_invoice_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyCustomInvoice />
          </React.Suspense>
        </Route>

        {/* relocation */}
        <Route exact path={path.add_relocation}>
          <AddRelocation/>
        </Route>
        <Route exact path={path.relocation_send}>
          <OutgoingList/>
        </Route>
        <Route exact path={path.relocation_received}>
          <IncommingList/>
        </Route>
        <Route exact path={'/Relocation/view_relocation/:id'}>
          <RelocationView/>
        </Route>
        <Route exact path={'/Relocation/sended_view_relocation/:id'}>
          <SendRelocationView/>
        </Route>
        {/* relocation */}
        {/* <Route exact path={path.custom_invoice_list}>
          <CustomInvoiceList />
        </Route> */}
        {/* <Route exact path={path.custom_sales_report}>
          <CustomSalesReport />
        </Route>
        <Route exact path={path.custom_create_invoice}>
          <CustomCreateInvoice />
        </Route>
        <Route exact path={path.purchace_order_list}>
          <PurchaceInvoiceList />
        </Route>
        <Route exact path={'/custom/add-purchace-order'}>
          <AddPurchaceOrder />
        </Route>
        <Route exact path={path.purchace_order_report}>
          <PurchaceReport/>
        </Route>
        <Route exact path={'/custom/edit-purchace-order/:id'}>
          <EditPurchaceOrder />
        </Route> */}
        {/* Requisition */}
        {/* <Route exact path={path.add_requisition_order}>
          <AddRequisition />
        </Route>
        <Route exact path={'/custom/edit-requisition-order/:id'}>
          <EditRequisition />
        </Route>
        <Route exact path={path.requisition_order_list}>
          <RequisitionList />
        </Route>
        <Route exact path={path.requisition_order_report}>
          <RequisitionReport/>
        </Route> */}
        {/* promotion */}
        {/* <Route exact path={path.offer}>
          <Offer />
        </Route>
        <Route exact path={"/promotions/add-offer"}>
          <AddOffer />
        </Route>
        <Route exact path={"/promotions/edit-offer/:id"}>
          <EditOffer />
        </Route>
        <Route exact path={"/promotions/view-offer/:id"}>
          <ViewOffer />
        </Route>
        <Route exact path={path.cupons}>
          <Cupon />
        </Route>
        <Route exact path={"/promotions/add-cupon"}>
          <AddCupon />
        </Route>
        <Route exact path={"/promotions/edit-cupon/:id"}>
          <EditCupon />
        </Route>
        <Route exact path={path.review}>
          <Reviews />
        </Route>
        <Route exact path={path.contactUs}>
          <ContactUs />
        </Route>
        <Route exact path={path.work_with_us}>
          <WorkWithUs />
        </Route>
        <Route exact path={path.banner}>
          <Banner />
        </Route>
        <Route exact path={'/promotions/add-banner'}>
          <AddBanner />
        </Route>
        <Route exact path={'/promotions/edit-banner/:id'}>
          <EditBanner />
        </Route> */}
        {/* promotion */}
        {/* web settings */}
        {/* <Route exact path={path.global_setting}>
          <GlobalSettings />
        </Route>
        <Route exact path={path.main_setting}>
          <MainSettings />
        </Route>
        <Route exact path={'/web-settings/add-display-center'}>
          <AddDisplayCenter />
        </Route>
        <Route exact path={'/web-settings/edit-display-center/:id'}>
          <EditDisplayCenter/>
        </Route>
        <Route exact path={path.display_center}>
          <DisplaySettings />
        </Route>
        <Route exact path={"/web-settings/add-video-slider"}>
          <AddVideo />
        </Route>
        <Route exact path={"/web-settings/edit-video-slider/:id"}>
          <EditVideo/>
        </Route>
        <Route exact path={path.teammember}>
          <TeamMember />
        </Route> */}
        {/* social media */}
        {/* <Route exact path={path.social_media}>
          <SocialMedia />
        </Route>
        <Route exact path={path.add_social_media}>
          <AddMedia />
        </Route>
        <Route exact path={'/web-settings/edit-social-media/:id'}>
          <EditMedia />
        </Route>
        <Route exact path={"/web-settings/add-teammember"}>
          <AddTeamMember />
        </Route>
        <Route exact path={"/web-settings/edit-teammember/:id"}>
          <EditTeamMember />
        </Route>
        <Route exact path={path.testimonials}>
          <Testimonials />
        </Route>
        <Route exact path={"/web-settings/add-testimonials"}>
          <AddTestiminial />
        </Route>
        <Route exact path={"/web-settings/edit-testimonials/:id"}>
          <EditTestimonial />
        </Route>
        <Route exact path={"/web-settings/view-testimonials/:id"}>
          <ViewTestimonial />
        </Route> */}
        {/* web settings */}
        {/* outlets */}
        {/* <Route exact path={path.add_outlet}>
          <AddOutLet/>
        </Route>
        <Route exact path={'/outlet/edit_outlet/:id'}>
          <EditOutlet/>
        </Route>
        <Route exact path={path.outlet_list}>
          <OutletList/>
        </Route>
        <Route exact path={'/outlet/view_outlet/:id'}>
          <ViewOutlet/>
        </Route> */}
        {/* outlets */}
        {/* return */}
        <Route exact path={path.add_return_products}>
          <AddReturnProduct/>
        </Route>
        <Route exact path={path.edit_return_products}>
          <EditReturnProduct/>
        </Route>
        <Route exact path={path.return_products}>
          <ReturnProductList/>
        </Route>
        <Route exact path={'/outlet/return_products_chalan_view/:id'}>
          <ViewReturnChalan/>
        </Route>
        <Route exact path={'/outlet/return_products_view/:id'}>
          <ViewReturnProduct/>
        </Route>
        <Route exact path="/login">
          <Login setHideToolbar={setHideToolbar} />
        </Route>
        <Route exact path="/forget-password">
          <ForgetPassword setHideToolbar={setHideToolbar} />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword setHideToolbar={setHideToolbar} />
        </Route>
        <Route>
          <Page404 setHideToolbar={setHideToolbar} />
        </Route>
      </Switch>
      {/* </Router> */}
    </div>
  );
};

export default RoutesFile;

import {
  BellRinging,
  ChartBar,
  Cube,
  HouseLine,
  ShoppingCart,
  Users,
  User,
  SpeakerHigh,
  GearSix,
  Storefront,ShareNetwork
} from "phosphor-react";
import { add_category } from "../Routes/RoutePaths";
import * as path from "../Routes/RoutePaths";

export const Navbar_items = {
  Dashboard: {
    main: "Dashboard",
    to: "/",
    icon: HouseLine,
    
  },

  Notification: {
    main: "Notification",
    to: path.notification,
    icon: BellRinging,
  },
  Reports: {
    main: "Reports",
    // to: path.reports,
    icon: ChartBar,
    sublinks: [
      {
        name: "Report",
        to: path.reports,
      },
      // {
      //   name: "Product Reports",
      //   to: path.sales_report,
      // },
      {
        name: "Product Reports",
        to: path.sales_report_dash,
      },
      {
        name: "Sales Reports",
        to: path.sales_report_inv,
      },
      {
        name: "Cash Reports",
        to: path.cash_report,
      },
      {
        name: "Discount Report",
        to: path.discount_report,
      },
      
    ],
  },
  
 
  
 

  Inventory: {
    main: "Inventory",
    icon: Cube,

    sublinks: [
     
      {
        name: "Chalan List",
        to: path.chalan_list,
      },
      {
        name: "Product List",
        to: path.product_list,
      },
     
      {
        name: "Return Products",
        to: path.return_products,
      },
    ],
  },
  Sales: {
    main: "Sales",
    icon: ShoppingCart,

    sublinks: [
      {
        name: "Create Invoice",
        to: path.create_invoice,
      },
      {
        name: "Create Refund",
        to: path.create_refund,
      },
      {
        name: "Create Exchange",
        to: path.create_exchange,
      },
      // {
      //   name: "Exchange List",
      //   to: path.exchange_list,
      // },
      {
        name: "Invoice List",
        to: path.invoice_list,
      },
    ],
  },
  
  
  User: {
    main: "User",
    icon: Users,

    sublinks: [
      
      {
        name: "Users List",
        to: path.user_list,
      },
      
    ],
  },
  Relocation: {
    main: "Relocation",
    icon: ShareNetwork,

    sublinks: [
      {
        name: "Send Products",
        to: path.add_relocation,
      },
      {
        name: "Sended List",
        to: path.relocation_send,
      },
      {
        name: "Received List",
        to: path.relocation_received,
      },
      
    ],
  },
  
};

import React, { useState } from "react";
import "./css/category.css";
import plus from "../../../assets/Icon/plus.svg";
import thumb1 from "../../../assets/Icon/thumb1.svg";
import thumb2 from "../../../assets/Icon/thumb2.svg";
import thumb3 from "../../../assets/Icon/thumb3.svg";
import thumb4 from "../../../assets/Icon/thumb4.svg";
import thumb5 from "../../../assets/Icon/thumb5.svg";
import thumb6 from "../../../assets/Icon/thumb6.svg";
import thumb7 from "../../../assets/Icon/thumb7.svg";
import delet from "../../../assets/Icon/delete.svg";
import edit from "../../../assets/Icon/edit.svg";
import next from "../../../assets/Icon/next.svg";
import pre from "../../../assets/Icon/pre.svg";
import Table from "react-bootstrap/Table";
import { useHistory } from "react-router-dom";
import Paggination from "../../CustomCommons/Paggination";
import EditIcon from "../../CustomCommons/EditIcon";
import DeleteIcon from "../../CustomCommons/DeleteIcon";
import swal from "sweetalert";
import * as path from "../../Routes/RoutePaths";
import getFilterData from "../../CustomCommons/Filter";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { showToast } from "../../../utils/ToastHelper";
import CustpmPaggination from "../../CustomCommons/CustpmPaggination";
import ReactPaginate from "react-paginate";
import UseData from "../../Const/UseData";
import Loader from "../../CustomCommons/Loader";
import PreviousIcon from "../../CustomCommons/PreviousIcon";
import NextIcon from "../../CustomCommons/NextIcon";
import {
  can_add_category,
  can_change_category,
  can_delete_category,
  can_view_category,
  has_permissions,
} from "../../CustomCommons/utils";
import Permission from "../../CustomCommons/Permission";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import kaarujLogo from "../../../assets/Icon/KAARUJ 1.png";
import { logout_func2 } from "../../Const/logoutCommon";



const CategoryList = () => {
  // const [data,loader]= UseData(`${BASE_URL}api/v1/inventory/inventory/category/`)
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const history = useHistory();
  const [categoryList, setcategoryList] = useState([]);
  const updatedPageNumber = parseInt(
    localStorage.getItem("categoryPageNbr")
      ? localStorage.getItem("categoryPageNbr")
      : 0
  );
  const [pageNumber, setPageNumber] = useState(updatedPageNumber);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  
  const cRef = useRef();

  const keys = ["name", "updated_at", "tree", "products"];
  const getData = categoryList?.filter((p) =>
    keys.some((key) => p[key]?.toString().toLowerCase()?.includes(query))
  );
  const pageCount = Math.ceil(getData?.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    localStorage.setItem("categoryPageNbr", selected);
  };
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Daily Sales Report",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });
  const handlePrints = () => {
    HandlePrint();
  };

  const getCategory = () => {
    
    let url;
    if (date1 && date2) {
      url = `${BASE_URL}api/v1/inventory/inventory/category/filter/?start=${date1}&end=${date2}&limit=20000`;
    } else {
      url = `${BASE_URL}api/v1/inventory/inventory/category/?limit=2000`;
    }

    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data.results);
        const result = res.data.data.results;
        setcategoryList(result);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  useEffect(() => {
    getCategory();
  }, []);
  const [checkedIds, setCheckedIds] = useState([]);
  const handleCheckboxChange = (id) => {
    // Check if the ID is already in the state
    const isChecked = checkedIds.includes(id);

    if (isChecked) {
      // If checked, remove the ID from the state
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      // If unchecked, add the ID to the state
      setCheckedIds([...checkedIds, id]);
    }
  };
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked) {
      const data = getData.map((item) => item.slug);
      setCheckedIds(data);
    } else {
      setCheckedIds([]);
    }
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/inventory/inventory/category/${id}/`;

        axios
          .delete(url)
          .then(() => {
            showToast("Delele", "Data Deleted");
            getCategory();
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          });
        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  const deleteDataMulti = (ids) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((id) => {
          const url = `${BASE_URL}api/v1/inventory/inventory/category/${id}/`;
          return axios.delete(url);
        });

        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been deleted!", {
              icon: "success",
            });
            setCheckedIds([]);
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {
            // Call getProduct and apiFunction regardless of success or failure
            getCategory();
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  var options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    // day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const generateData = () => {
    if (!date1 && !date2) {
      swal("Please select two dates");
    } else if (
      (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") ||
      (date1 === "" && date2 === "")
    ) {
      swal("Please select two dates");
    } else if (date1 === "undefined/undefined/" || date1 === "") {
      swal("Please select  start Date");
    } else if (date2 === "undefined/undefined/" || date2 === "") {
      swal("Please select  end Date");
    } else {
      getCategory();
    }
  };
  useEffect(() => {
    // getCategory();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
  }, []);
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${month}-${day}-${year}`;
  if (!has_permissions(can_view_category)) {
    return <Permission />;
  }
  if (isLoading) {
    return <Loader />;
  }

  const catType = ["Main Category","Sub Main Category","Other Category"]

  return (
    <div>
      <div className="categorey-parent">
        <div className="cotagorey-head mb-4">
          <div>
            <h3>Category List </h3>
            <input
              style={{ color: "black" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              onChange={(e) => {
                const selected = 0;
                changePage({ selected });
                setQuery(e.target.value.toLocaleLowerCase());
                localStorage.setItem("categoryPageNbr", selected);
                // updatedPageNumber = selected;
              }}
            />
          </div>
          <div className="filter_div ">
            <div className="my-2 my-lg-0">
              <input
                type="date"
                format="DD-MM-YYYY"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;
                  setDate1(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <span className="mx-2 my-auto" placeholder="09-09-2022">
                {" "}
                To
              </span>
              <input
                type="date"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;

                  setDate2(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
            </div>
            <div
              className="button-div my-auto mx-2 "
              style={{ background: "black", cursor: "pointer" }}
              onClick={generateData}
            >
              <span> Generate</span>
            </div>
            {getData?.length > 0 && (
              <div
                className="button-div my-auto me-2 "
                style={{ background: "black", cursor: "pointer" }}
                onClick={() => handlePrints()}
              >
                <span> Print</span>
              </div>
            )}
            {has_permissions(can_add_category) && (
              <div
                className="button-div"
                onClick={() => history.push(path.add_category)}
              >
                <img src={plus} alt="" />
                <span>Add Category</span>
              </div>
            )}
          </div>
        </div>
        {checkedIds?.length > 0 && (
          <div>
            <button
              className="btn text-light"
              style={{
                background: "black",
                cursor: "pointer",
                maxWidth: "120px",
                color: "white",
              }}
              onClick={() => deleteDataMulti(checkedIds)}
            >
              Delete
            </button>
          </div>
        )}
        {/* categorey list table */}
        <div className="table-responsive">
          <table className="table_my pdf-table">
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px", marginTop: "20px" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "99px",
                    textAlign: "center",
                    borderTopLeftRadius: "4px ",
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <input
                      style={{
                        margin: "0 auto",
                        opacity: "1",
                        width: "17px",
                        height: "17px",
                      }}
                      type="checkbox"
                      checked={checkedIds?.length > 0}
                      onChange={(event) => handleCheckboxChangeAll(event)}
                    />
                  </div>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                    minWidth: "110px",
                  }}
                >
                  Thumbnail
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                    minWidth: "180px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "220px",
                    minWidth: "220px",
                  }}
                >
                   Category
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Products
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "270px",
                    minWidth: "270px",
                  }}
                >
                  Last Modified
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "141px",
                    minWidth: "141px",
                  }}
                >
                  Status
                </th>
                <th
                  className="action"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopRightRadius: "4px ",
                    width: "141px",
                    minWidth: "141px",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {getData?.length > 0 ? (
                getData
                  .slice(pagesVisited, pagesVisited + usersPerPage)
                  .map((category, index) => (
                    <tr className="table-row " key={category?.slug}>
                      <td className="text-center">
                        <div className="d-flex justify-content-center">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            style={{ width: "17px", height: "17px" }}
                            checked={checkedIds.includes(category.slug)}
                            onChange={() =>
                              handleCheckboxChange(category?.slug)
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <img
                          width={50}
                          style={{ margin: "6px 0px !important" }}
                          height={50}
                          src={
                            category.thumb_url !== ""
                              ? category.thumb_url
                              : thumb1
                          }
                          alt=""
                        />
                      </td>
                      <td style={{ textTransform: "uppercase" }}>
                        {category.name}
                      </td>
                      <td >
                        {catType[category.category_type]}
                      </td>
                      {/* <td>
                        {convertToObjectFormat(category)}
                      </td> */}
                      <td>{category?.products ? category?.products : "0"}</td>
                      <td>
                        {new Date(category.updated_at).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>
                      <td className="">
                        <button
                          className={`status ${
                            category.is_active === true ? "c1" : "c2"
                          }`}
                        >
                          {category.is_active === true ? "Active" : "Disable"}
                        </button>
                      </td>
                      <td className="action">
                        <div className="d-flex gap-3">
                          {has_permissions(can_change_category) ? (
                            <div
                              className="edit-del green"
                              onClick={() =>
                                history.push(
                                  `/inventory/edit-category/${category?.slug}`
                                )
                              }
                            >
                              <EditIcon />
                            </div>
                          ) : (
                            <div
                              className="edit-del green"
                              onClick={() =>
                                swal(
                                  "Sorry,You have not able to  edit category."
                                )
                              }
                            >
                              <EditIcon />
                            </div>
                          )}

                          {has_permissions(can_delete_category) ? (
                            <div
                              className="edit-del red"
                              onClick={() => deleteData(category?.slug)}
                            >
                              <DeleteIcon />
                            </div>
                          ) : (
                            <div
                              className="edit-del red"
                              onClick={() =>
                                swal(
                                  "Sorry,You have not able to delete category."
                                )
                              }
                            >
                              <DeleteIcon />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* ==============2nd================ */}
        <div style={{ display: "none" }} className="pt-4">
          <table
            className="table_my pdf-table my-3 pt-4 print_table"
            ref={cRef}
          >
            <thead
              className="d-none  thed w-100 text-center me-4"
              style={{ marginTop: "20px" }}
            >
              <div className="mb-4 pb-4 d-flex justify-content-center align-items-center">
                <img className="pb-3" src={kaarujLogo} width={150} alt="" />
                <p className="mt-2 ms-4">
                  Category List -
                  <span className="ms-3">Print Date : {currentDate} </span>
                  <span className="ms-3">Total Item : {getData?.length}</span>
                </p>
              </div>
            </thead>
            <thead
              className="bg-dark text-white head-row mt-3 main-head-print"
              style={{ height: "50px", marginTop: "50px" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                    textAlign: "center",
                    borderTopLeftRadius: "4px ",
                    color: "black",
                  }}
                >
                  S/N 
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                    textAlign: "center",
                  }}
                >
                  S/N
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  S/N s/n s/n
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                    minWidth: "110px",
                    marginLeft: "20px",
                  }}
                >
                  Thumbnail
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                    minWidth: "180px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "220px",
                    minWidth: "220px",
                  }}
                >
                  Parent Category
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Products
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "270px",
                    minWidth: "270px",
                  }}
                >
                  Last Modified
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "141px",
                    minWidth: "141px",
                  }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {getData?.length > 0 ? (
                getData
                  .slice(pagesVisited, pagesVisited + usersPerPage)
                  .map((category, index) => (
                    <tr className="table-row " key={category?.slug}>
                      <td
                        className="text-center"
                        style={{ margin: "0px 20px", color: "white" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center"
                        style={{ margin: "0px 20px" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-center"
                        style={{ margin: "0px 20px", color: "white" }}
                      >
                        {index + 1}
                      </td>
                      <td>
                        <img
                          width={50}
                          style={{ margin: "6px 0px !important" }}
                          height={50}
                          src={category.thumb !== "" ? category.thumb : thumb1}
                          alt=""
                        />
                      </td>
                      <td>{category.name}</td>
                      <td>
                        {category?.tree === "" || null ? "N/A" : category?.tree}
                      </td>
                      <td>{category?.products ? category?.products : "0"}</td>
                      <td>
                        {new Date(category.updated_at).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>
                      <td className="">
                        <button
                          className={`status ${
                            category.is_active === true ? "c1" : "c2"
                          }`}
                        >
                          {category.is_active === true ? "Active" : "Disable"}
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* entites and paggination */}
        {getData?.length > 0 && (
          <div className="row pag" style={{ marginTop: "30px" }}>
            <div className="col-6 col-md-5">
              <p
                className="inter"
                style={{ color: "#AEAEB2", fontSize: "16px" }}
              >
                Total Entries:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {categoryList?.length}
                </span>
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex paggination-button">
              <ReactPaginate
                previousLabel={<PreviousIcon />}
                nextLabel={<NextIcon />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"a"}
                nextLinkClassName={"a"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                forcePage={query?.length > 0 ? 0 : updatedPageNumber}
              />
            </div>
          </div>
        )}

        {/* <CustpmPaggination allData={getData} /> */}
      </div>
    </div>
  );
};

export default CategoryList;

export const deliveryStatus = {
    0 : 'Returned',1:'Recieved',2:'Delivered'
  }
 export const paymentStatus = {
    0 : "Paid",
    1:'Unpaid',
    2:'Due'
  }

  export const memshipLabel = ["NONE", "PRO", "PREMIUM", "ELITE", "SUPREME"]



import React, { useState } from "react";
import { showToast } from "../../utils/ToastHelper";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import swal from "sweetalert";

const ProToPremium = ({ saveInvoice, success, membershipInfo, total }) => {
  console.log(membershipInfo, "premium info");
  console.log(total(), " info");
  const totalm = total();
  const [success1, setSuccess1] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [message, setMessege] = useState("");
  const verifyMembership = async () => {
    if (membershipInfo?.membership_type > 1 || totalm >= 5000) {
      saveInvoice();
    } else if (
      membershipInfo?.mobile &&
      totalm < 5000 &&
      membershipInfo?.membership_type < 2
    ) {
      const url = `${BASE_URL}api/v1/sales/inventory/next-membership-check/`;
      setIsloading(true);
      setSuccess1(false);
      try {
        const res = await axios.post(url, {
          total_amount: totalm,
          mobile: membershipInfo?.mobile,
        });

        if (res.data.status) {
          setSuccess1(true);
          const result = res.data.data;
          setData(result);
          setMessege(result.message);
          if (result.message === null) {
            saveInvoice();
          } else {
            callApi(result.message);
          }
        }
      } catch (err) {
        let message = "An error occurred";

        if (err.response && err.response.data) {
          const errorData = err.response.data;
          if (errorData.errors && errorData.errors.message) {
            message = errorData.errors.message;
          } else if (errorData.message) {
            message = errorData.message;
          }
        }

        setSuccess1(false);
        showToast("error", message);
      } finally {
        setIsloading(false);
      }
    } else {
      saveInvoice();
    }
  };

  const callApi = (messege2) => {
    swal({
      title: "Membership Update Notification",
      text: `${messege2}`,
      icon: "warning",
      buttons: {
        cancel: {
          text: "Explore",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Create",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((willCreate) => {
      if (willCreate) {
        saveInvoice();
      } else {
        swal("You can continue shopping");
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <button className="btn rounded border me-2 mt-2">Loading ...</button>
      ) : (
        <button
          onClick={verifyMembership}
          className="btn rounded border me-2 mt-2"
        >
          Save Invoice
        </button>
      )}
    </>
  );
};

export default ProToPremium;

import React, { useEffect, useState } from "react";
import qrCode from "../../../assets/Icon/qrcode.svg";
import print from "../../../assets/Icon/print.svg";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Const/Url.js";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Loader from "../../CustomCommons/Loader";
import cImg from "../../../assets/Icon/KAARUJ 1.png";
import Modal from "react-bootstrap/Modal";
import { Button } from "bootstrap";

const ViewReturnChalan = () => {
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [variants, setVariants] = useState([]);
  const [isLoading, SetisLoading] = useState(true);
  const [outletDetail, setOutletDetail] = useState({});
  let url = `${BASE_URL}api/v1/inventory/admin/return-chalan/${id}/`;
  const [data, setData] = useState({});
  const [Jdata, setJData] = useState([]);
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [dataDetails, setDataDetails] = useState([]);
  const [subTotal, setSubTotal] = useState(0);

  console.log(dataDetails, "dd");

  const productArray = (data, chalanFullData) => {
    const newProducts = [];
    data.forEach((product, index) => {
      product.outletVariant.forEach((variant) => {
        const newProduct = {
          name: product.name + " { " + variant.name + "}",
          quantity: variant.initial_stock ? variant.initial_stock : "",
          stock: variant.admin_stock ? variant.admin_stock : "",
          price: parseFloat(product.price) + parseFloat(variant.price),
          sku: chalanFullData?.outlet_product_sku[product?.sku],
          sku2: product?.sku,
          image:
            variant?.images_details?.length > 0
              ? variant?.images_details[0]?.resized_doc_url
              : product?.thumb_resized_url,
        };
        newProducts.push(newProduct);
      });
    });
    return newProducts;
  };

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        setDataDetails(res.data?.data?.return_products);
        // setDataDetails(
        //   productArray(
        //     res.data.data?.return_products,
        //     res.data.data
        //   )
        // );
        setOutletDetail(JSON.parse(localStorage.getItem("userData")));
        SetisLoading(false);
      })
      .catch((err) => {
        // const message = JSON.parse(err.request.response).message;
        // console.log(err.request.response);
        // if (
        //   message === "Invalid token." ||
        //   JSON.parse(err?.request?.response)?.code === 401
        // ) {
        //   // history.push('/login')
        //   logout_func2(setOnlogoutHide, history);
        // }
      });
  }, []);

  const paymentStatus = ["Paid", "Unpaid", "Due"];
  const DeliveryTypeStatus = ["Regular", "Urgent"];

  const cRef = useRef();
  const history = useHistory();

  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Invoice",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });
  const handlePrints = () => {
    HandlePrint();
  };
  var options = {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: 'numeric', minute: 'numeric', hour12: true
  };

  if (isLoading) {
    return <Loader />;
  }

  const handleClick = (category) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=212px, initial-scale=1.0">
          <title>#${data.number}</title>
          <style>
            /* Add your CSS styles here */
            table, tr, td {
              border: none;
          }
          body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              width: 212px;
              font-weight: normal;
          }
  
          .container {
              width: 100%;
          }
  
          .header {
              text-align: center;
          }
  
          .address {
              margin: 5px 0;
              text-align: center;
          }
  
          .address p {
              margin: 3px;
              font-size: 10px;
          }
  
          .invoice-details {
              margin: 5px 0;
          }
  
          .invoice-details p {
              margin: 3px 0;
              font-size: 10px;
          }
  
          .invoice-table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 5px;
          }
  
          .invoice-table th,
          .invoice-table td {
              border: 1px solid #3327272b;
              padding: 3px;
              text-align: center;
              font-size: 10px;
          }
  
          .invoice-table th {
              background-color: #f2f2f2;
          }
  
          .total-section {
              margin-top: 5px;
          }
  
          .total-section p {
              margin: 3px 0;
              text-align: right;
              font-size: 10px;
          }
          .header {
              text-align: center;
              margin-top: 5px; 
          }
  
          .address {
              margin: 5px 0 0 0; 
              text-align: center;
          }
  
          @media print {
              .invoice-table {
                  page-break-inside: avoid;
              }
          }
          </style>
        </head>
        <body>
          <div class="">
            <div class="header" style="margin-bottom: 5px; font-weight:bolder;">
              <p>Return Receipt</p>
              <p>${category.number}</p>
            </div>
  
            <div class="address">
            <span>From:  </span>
              <div class="address" style="margin-bottom: 0px;font-weight:bolder;">
                <p4>${outletDetail?.outlet_name}</p4>
              </div>
              <p> Email: ${outletDetail.email ? outletDetail?.email : ""}</p>
              <p>Date: ${new Date(data.created_at).toLocaleDateString(
                "en-US",
                options
              )}</p>
            </div>
            <div class="address">
            <span>To:  </span>
              <div class="address" style="margin-bottom: 0px;font-weight:bolder;">
                <p4>${"Kaaruj Bangladesh"}</p4>
              </div>
              <p>Email: ${"kaarujbangladesh@gmail.com"}</p>
            </div>
            <table class="invoice-table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>SKU</th>
                  <th>Qty</th>
                  
                </tr>
              </thead>
              <tbody>
                ${dataDetails
                  ?.map(
                    (item) => `
                      <tr>
                        <td><p class='text-align: start;'>${item?.product_details?.name}</p></td>
                        <td><p>${item?.product_details.sku}</p></td>
                        <td><p>${item.stock}</p></td>
                        
                      </tr>
                    `
                  )
                  .join("")}
              </tbody>
            </table>
            <div style="margin-top: 10px; font-size: 8px;">
              <span>
              Special Note : Please check out this product list. Any questions,
              ask the logistics team. No complaint shall be entertained after the
              goods  left the outlet.
            </span> 
           </div>
            <div style="margin-top: 10px; font-size: 12px; display:flex; justify-content: space-between;">
              <div>
              <span>${data?.created_by_name}</span> <br />
              <span>----------------------</span> <br />
              <span>Prepared By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span>Recieved By</span>
            </div>
           </div>

            <div style="margin-top: 10px; font-size: 12px; display:flex; justify-content: space-between;">
              <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span>Checked By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span >Authorized By</span>
            </div>
           </div>

            
  
           
          </div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="categorey-parent pd-right2" style={{ height: "100%" }}>
      <div className="bg-white rounded printe" ref={cRef}>
        <div className="company-img ">
          <div className="w-100 d-flex justify-content-center align-items-center">
            <img width={200} className="mt-4" height={40} src={cImg} alt="" />
          </div>
        </div>
        <div className=" row py-4 px-lg-4 px-md-3 px-3 top-m ds-topm">
          {/* d-flex flex-column flex-md-row justify-content-between */}
          <div className="col-6 view-inv dd col-md-6 mb-2 mb-md-0">
            <div>
              <h4 className="mt-1">#{data.number}</h4>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex   justify-content-end  align-items-start gap-2 invoice_qr inv_qr2 extra-inv">
            <div>
              <span>Return Date: </span>
              <br />
              <span>
                {new Date(data.created_at).toLocaleDateString("en-US", options)}
              </span>
              {/* {new Date(data.invoice_date).toLocaleDateString("en-US", options)} */}
              <br />
              {/* <span style={{ marginRight: "4px" }}> Due Date: </span> <span>22 June, 2022</span> */}
            </div>
            <img
              className="pointer"
              style={{ marginTop: "-10px" }}
              src={data?.qr_code ? data.qr_code : qrCode}
              alt=""
            />
          </div>
        </div>
        {/* border */}
        <div className="mx-2 mx-md-3 w-100">
          <div className="border-ex"></div>
        </div>
        {/* address */}
        <div className="address">
          <div className="row  justify-content-between an">
            <div className="col-12 col-md-6 col-lg-5 mt-3 mt-md-0 ddd">
              <h5>From:</h5>
              <span>
                {outletDetail?.outlet_name
                  ? outletDetail?.outlet_name
                  : "Zakariya Rahman"}{" "}
              </span>{" "}
              <br />
              <span className="to_address">
                {outletDetail?.outlet_location}
              </span>{" "}
              <br />
              {/* <span>Dhaka, Bangladesh</span> <br /> */}
              <span>
                Phone:{" "}
                {outletDetail.mobile ? outletDetail?.mobile : "+4480976645"}
              </span>{" "}
              <br />
              {outletDetail?.email && <span>Email:{outletDetail?.email}</span>}
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <h5>To:</h5>
              <span>{data?.bill_from ? data?.bill_from : "Kaaruj"} </span>{" "}
              <br />
              <span>
                {data?.from_address
                  ? data?.from_address
                  : "Road: 42, Gulshan 2, Dhaka, Bangladesh"}{" "}
              </span>{" "}
              <br />
              {/* <span>Dhaka, Bangladesh</span> <br /> */}
              <span>
                Phone:{" "}
                {data?.from_mobile ? data?.from_mobile : "+8801980907892"}
              </span>{" "}
              <br />
              <span>Email: kaarujbangladesh@gmail.com</span>
            </div>
          </div>
          <div className="table-cont">
            <h5 className="mb-4">Return Summary</h5>
            <div className=" ">
              <table className="w w1" style={{ width: "100%" }}>
                <thead>
                  <tr
                    className={`bg-dark text-white `}
                    style={{ height: "50px" }}
                  >
                    <th className="ps-4" style={{ minWidth: "80px" }}>
                      No
                    </th>

                    <th className="minw minw1" style={{ minWidth: "150px" }}>
                      Item
                    </th>
                    <th
                      className="minw minw1 ps-3"
                      style={{ minWidth: "100px" }}
                    >
                      SKU
                    </th>
                    <th className="minw minw1 ps-2">Image</th>

                    <th className="minw minw1 " style={{ maxWidth: "100px" }}>
                      <p className="text-center my-auto">Curr Stock</p>
                    </th>
                    <th
                      className="pe-4"
                      style={{ minWidth: "130px", textAlign: "right" }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data !== undefined &&
                    data !== null &&
                    dataDetails.map((curr, index) => {
                      return (
                        <tr
                          className={`tab-b ${
                            Number(curr?.quantity) < 1 && ""
                          }`}
                          style={{ height: "50px", cursor: "pointer" }}
                          onClick={() => {
                            setVariants(curr?.outletVariant);
                            setModalShow(true);
                          }}
                        >
                          <td className="ps-4">{index + 1}</td>

                          <td>{curr?.product_details?.name}</td>
                          <td className="ps-3">{curr?.product_details.sku}</td>
                          <td className="ps-2">
                            <img
                              className="rounded"
                              width={40}
                              height={40}
                              src={curr?.product_details.thumb_url2}
                              alt=""
                            />
                          </td>

                          <td>
                            <p className="text-center my-auto">
                              {Number(curr?.stock)}
                            </p>
                          </td>
                          <td>
                            <p className="text-end my-auto me-2">
                              {curr?.status === 0
                                ? "Pending"
                                : curr?.status === 1
                                ? "Accepted"
                                : "Rejected"}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="row d4 d5 calc-main d-none">
              <div className="col-12 col-md-4 col-lg-6 calc-empty"></div>
              <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-end calc">
                <div className=" ">
                  <table>
                    <tbody>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td
                          style={{ minWidth: "230px" }}
                          className="tab-b ps-3"
                        >
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Sub Total : </div>
                            <div>
                              ৳{" "}
                              {dataDetails
                                ?.map(
                                  (curr) =>
                                    Number(curr.price) * Number(curr?.stock)
                                )
                                .reduce((a, b) => a + b, 0)}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Delivery Charge : </div>
                            <div>৳ {0.0}</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Discount : </div>
                            <div>৳ {data.total_discount}</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Total : </div>
                            <div>
                              ৳{" "}
                              {dataDetails
                                ?.map(
                                  (curr) =>
                                    Number(curr.price) * Number(curr?.stock)
                                )
                                .reduce((a, b) => a + b, 0)}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Paid Amount: </div>
                            <div>৳ {data?.total_paid}</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Due Amount : </div>
                            <div style={{ color: "red" }}>
                              ৳ {data.total_due}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="calc-main2 d-none">
              <div className=" calc2">
                <div className="t-class">
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Sub Total : </div>
                      <div>
                        ৳{" "}
                        {dataDetails
                          ?.map(
                            (curr) => Number(curr.price) * Number(curr?.stock)
                          )
                          .reduce((a, b) => a + b, 0)}
                      </div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Delivery Charge : </div>
                      <div>৳ {data.delivery_charge}</div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Discount : </div>
                      <div>৳ {data.total_discount}</div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Total : </div>
                      <div>
                        ৳{" "}
                        {dataDetails
                          ?.map(
                            (curr) => Number(curr.price) * Number(curr?.stock)
                          )
                          .reduce((a, b) => a + b, 0)}
                      </div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Paid Amount: </div>
                      <div>৳ {data?.total_paid}</div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Due Amount : </div>
                      <div style={{ color: "red" }}>৳ {data.total_due}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* table */}
        {/* <div className="mx-2 mx-md-3 my-2">
          Total Products : {data?.products_count}
        </div> */}
        <div className="mx-2 mx-md-3 ">
          <div className="border-ex"></div>
          <div className="mx-2 my-4 mx-md-3 d-flex justify-content-between">
            <div>
              <span>{data?.created_by_name}</span> <br />
              <span>----------------------</span> <br />
              <span>Prepared By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span>Recieved By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span>Checked By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span className="">Authorized By</span>
            </div>
          </div>
          <div style={{ height: "50px" }} className="m-2">
            Special Note : Please check out this product list. Any questions,
            ask the logistics team. No complaint shall be entertained after the
            goods  left the outlet.
          </div>
        </div>
      </div>
      <div className=" invoice-button">
        <button
          className="me-2 my-2 button-1"
          onClick={() => handleClick(data)}
        >
          <img src={print} alt="" /> Small Print
        </button>
        <button className="me-2 my-2 button-1" onClick={handlePrints}>
          <img src={print} alt="" /> Print
        </button>
        {/* <button
      onClick={sendPdfToEmail}
      className={`my-2 button-2 ${!data.to_email && "d-none"}`}
    >
      <img src={send} alt="" /> Send Invoice
    </button> */}
        <>
          <Modal
            // {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Product Variants
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {variants?.map((data) => (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Name : {data?.variant_details?.name}</h5>
                    <h5>Stock : {data?.stock}</h5>
                  </div>
                </>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-primary"
                onClick={() => setModalShow(false)}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    </div>
  );
};

export default ViewReturnChalan;

import React, { useEffect, useState } from "react";
import qrCode from "../../assets/Icon/qrcode.svg";
import zakaria from "../../assets/Icon/Zakariya Rahman.svg";
import shafin from "../../assets/Icon/Shafin Ahmed.svg";
import print from "../../assets/Icon/print.svg";
import send from "../../assets/Icon/send.svg";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../Const/Url.js";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import swal from "sweetalert";
import Loader from "../CustomCommons/Loader";
import cImg from "../../assets/Icon/KAARUJ 1.png";
import { showToast } from "../../utils/ToastHelper";
import * as path from "./../Routes/RoutePaths";
import "./invoice.css";
import { logout_func2 } from "../Const/logoutCommon";

const ViewExchangeInvoice = () => {
  const { id } = useParams();
  const [isLoading, SetisLoading] = useState(true);
  let url = `${BASE_URL}api/v1/sales/inventory/invoice/${id}`;
  const [data, setData] = useState({});
  const [Jdata, setJData] = useState([]);
  const [OnlogoutHide, setOnlogoutHide] = useState(0);

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data, "---------single invoice---------");
        // console.log(res.data.data.product_list_json);
        const temp1 = res.data.data.product_list_json.mainstate;
        var output = [];
        for (let i in temp1) {
          console.log(temp1[i]["variantObj"]);
          for (let j in temp1[i]["variantObj"]) {
            console.log(j);
            console.log(temp1[i]["variantObj"][j]);
            const d = temp1[i]["variantObj"][j];
            d.total !== 0 &&
              output.push({
                name: i,
                basePrice: temp1[i].price,
                discount: temp1[i].discount,
                thumb: temp1[i].thumb,
                price: d["price"],
                quantity: d["quantity"],
                id: d["id"],
                total: d["total"],
                variant: d["name"],
              });
          }
        }
        setJData(output);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  }, []);
  console.log("Jdata", Jdata);
  const paymentStatus = ["Paid", "Unpaid", "Due"];
  const DeliveryTypeStatus = ["Regular", "Urgent"];

  const cRef = useRef();
  const history = useHistory();

  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Invoice",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });
  const handlePrints = () => {
    HandlePrint();
  };
  var options = {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: 'numeric', minute: 'numeric', hour12: true
  };

  const sendPdfToEmail = () => {
    const url = `${BASE_URL}api/v1/sales/inventory/invoice/${id}/`;
    const data = new FormData();
    data.append("send_pdf", true);
    SetisLoading(true);
    axios
      .patch(url, data)
      .then((res) => {
        console.log("calling api");
        SetisLoading(false);
        showToast("success", "Invoice sent successfully..");
        history.push(path.invoice_list);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };

  if (isLoading) {
    return <Loader />;
  }
  console.log(
    data?.invoice_products_formatted,
    "----------------------status--------------"
  );
  const paymentType = ["C.O.D", "Card", "Bank Transfer", "bKash"];

  const handleClick = (category) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=212px, initial-scale=1.0">
          <title>${category.number}</title>
          <style>
            /* Add your CSS styles here */
            table, tr, td {
              border: none;
          }
          body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              width: 212px;
              font-weight: normal;
          }
  
          .container {
              width: 100%;
          }
  
          .header {
              text-align: center;
          }
  
          .address {
              margin: 5px 0;
              text-align: center;
          }
  
          .address p {
              margin: 3px;
              font-size: 10px;
          }
  
          .invoice-details {
              margin: 5px 0;
          }
  
          .invoice-details p {
              margin: 3px 0;
              font-size: 10px;
          }
  
          .invoice-table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 5px;
          }
  
          .invoice-table th,
          .invoice-table td {
              border: 1px solid #3327272b;
              padding: 3px;
              text-align: center;
              font-size: 10px;
          }
  
          .invoice-table th {
              background-color: #f2f2f2;
          }
  
          .total-section {
              margin-top: 5px;
          }
  
          .total-section p {
              margin: 3px 0;
              text-align: right;
              font-size: 10px;
          }
          .header {
              text-align: center;
              margin-top: 5px; 
          }
  
          .address {
              margin: 5px 0 0 0; 
              text-align: center;
          }
  
          @media print {
              .invoice-table {
                  page-break-inside: avoid;
              }
          }
          </style>
        </head>
        <body>
          <div class="">
            <div class="header" style="margin-bottom: 5px; font-weight:bolder;">
              <p>Bill Receipt</p>
            </div>
  
            <div class="address">
              <div class="address" style="margin-bottom: 0px;font-weight:bolder;">
                <p4>${category.bill_from}</p4>
              </div>
              <p>${category.from_address}</p>
              <p>Mobile: ${category.from_mobile}</p>
    <p> Email: ${category.from_email}</p>
              <p>Date: ${category.invoice_date}</p>
            </div>
  
            <div class="invoice-details">
              <p>Invoice No: ${category.number}</p>
              <p>Name: ${category.bill_to}</p>
              <p>Address: ${category.to_address ? category.to_address : "Dhaka"
      }</p>
              <p>Phone: ${category.to_mobile} </p>
              ${category.to_email && `<p>Email: ${category.to_email}</p>`}
            
            </div>
  
            <table class="invoice-table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                ${category.invoice_products
        .map(
          (item) => `
                      <tr>
                        <td><p>${item.product_name}(${item.variant_name
            })</p></td>
                        <td><p>${Number(item.total) / Number(item?.quantity)
            }</p></td>
                        <td><p>${item.quantity}</p></td>
                        <td><p>${item.total}</p></td>
                      </tr>
                    `
        )
        .join("")}
              </tbody>
            </table>
  
            <div class="total-section">
              <p>Total Item: ${category.invoice_products.length}</p>
              <p>Total Amount: ${category.total_amount}</p>
        
              <p>Discount: ${data?.discount_type === 0 ? "৳" : ""}
              ${category.total_discount}
              ${data?.discount_type === 1 ? "%" : ""} </p>
              <p>Paid Amount: ${category.total_paid}</p>
              <p>Due Amount: ${category.total_due}</p>
            </div>
          </div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
    history.push(path.invoice_list);
  };

  return (
    <div className="categorey-parent pd-right2" style={{ height: "100%" }}>
      <div className="bg-white rounded printe" ref={cRef}>
        <div className="company-img ">
          <div className="w-100 d-flex justify-content-center align-items-center">
            <img width={200} className="mt-4" height={40} src={cImg} alt="" />
          </div>
        </div>
        <div className=" row py-4 px-lg-4 px-md-3 px-3 top-m ds-topm">
          {/* d-flex flex-column flex-md-row justify-content-between */}
          <div className="col-6 view-inv dd col-md-6 mb-2 mb-md-0">
            <div>
              <h4 className="mt-1">#{data.number}</h4>
              <div className="mt-2">
                <span>Status:</span>{" "}
                <span
                // className={`px-2 py-1 rounded  text-white ${
                //   (data.payment_status === 0 && "i1") ||
                //   (data.payment_status === 1 && "c4 text-white") ||
                //   (data.payment_status === 2 && "c3 text-white")
                // }`}
                >
                  {paymentStatus[data.payment_status]}
                </span>
              </div>
              <div className="mt-2">
                <span>Delivery:</span>{" "}
                <span>{DeliveryTypeStatus[data.delivery_type]}</span>
              </div>
              <div className="mt-2">
                <span>Payment Type:</span>{" "}
                <span>{paymentType[data?.payment_type]}</span>
              </div>
              <div className="mt-2">
                <span>Invoice Type:</span>{" "}
                <span>{data.is_exchanged ? "Exchange" : data.is_returned ? "Refund" : "Regular"}</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex   justify-content-end  align-items-start gap-2 invoice_qr inv_qr2 extra-inv">
            <div>
              <span>Invoice Date: </span>
              <br />
              <span>
                {new Date(data.invoice_date).toLocaleDateString(
                  "en-US",
                  options
                )}
              </span>
              {/* {new Date(data.invoice_date).toLocaleDateString("en-US", options)} */}
              <br />
              {/* <span style={{ marginRight: "4px" }}> Due Date: </span> <span>22 June, 2022</span> */}
            </div>
            <img
              className="pointer"
              style={{ marginTop: "-10px" }}
              src={data?.qr_code ? data.qr_code : qrCode}
              alt=""
            />
          </div>
        </div>
        {/* border */}
        <div className="mx-2 mx-md-3 w-100">
          <div className="border-ex"></div>
        </div>
        {/* address */}
        <div className="address">
          <div className="row  justify-content-between an">
            <div className="col-12 col-md-6 col-lg-5">
              <h5>From:</h5>
              <span>{data?.bill_from ? data?.bill_from : ""} </span> <br />
              <span>{data?.from_address ? data?.from_address : ""} </span>{" "}
              <br />
              {/* <span>Dhaka, Bangladesh</span> <br /> */}
              <span>
                Phone: {data?.from_mobile ? data?.from_mobile : ""}
              </span>{" "}
              <br />
              <span>Email: {data.from_email}</span>
            </div>
            <div className="col-12 col-md-6 col-lg-5 mt-3 mt-md-0 ddd">
              <h5>To:</h5>
              <span>{data?.bill_to ? data?.bill_to : ""} </span> <br />
              <span className="to_address">
                {data.to_address === "undefined" || "" ? "" : data.to_address}
              </span>{" "}
              <br />
              {/* <span>Dhaka, Bangladesh</span> <br /> */}
              <span>Phone: {data?.to_mobile ? data?.to_mobile : ""}</span>{" "}
              <br />
              {data?.to_email && <span>Email:{data.to_email}</span>}
            </div>
          </div>
          <div className="table-cont">
            <h5 className="mb-4">Order Summary</h5>
            <div className=" ">
              <table className="w w1" style={{ width: "100%" }}>
                <thead>
                  <tr
                    className={`bg-dark text-white `}
                    style={{ height: "50px" }}
                  >
                    <th className="ps-4" style={{ minWidth: "80px" }}>
                      No
                    </th>
                    <th className="minw minw1" style={{ minWidth: "150px" }}>
                      Item
                    </th>
                    <th className="minw minw1 ps-2">Image</th>

                    <th className="minw minw1 ">
                      <p className="text-center my-auto">Price</p>
                    </th>
                    <th className="minw minw1 ">
                      <p className="text-center my-auto">Quantity</p>
                    </th>
                    <th
                      className="pe-4"
                      style={{ minWidth: "230px", textAlign: "right" }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    // Object.keys(temp0).map(name=>temp0[name]["variantObj"]).map(curr=>Object.values(curr))
                  }
                  {data !== undefined &&
                    data !== null &&
                    data?.invoice_products.map((curr, index) => {
                      return (
                        <tr
                          className={`tab-b ${Number(curr?.quantity) < 1 && "d-none"
                            }`}
                          style={{ height: "50px" }}
                        >
                          <td className="ps-4">{index + 1}</td>
                          <td>
                            {curr.product_name} -({curr?.variant_name})
                          </td>
                          <td className="ps-2">
                            <img
                              className="rounded"
                              width={40}
                              height={40}
                              src={curr?.thumb}
                              alt=""
                            />
                          </td>
                          <td>
                            <p className="text-center my-auto">
                              ৳{" "}
                              {/* {curr?.discount === 0
                                ? parseInt(curr?.basePrice) +
                                  parseInt(curr?.price)
                                : parseInt(curr?.discount) +
                                  parseInt(curr.price)} */}
                              {Number(curr.total) / Number(curr?.quantity)}
                            </p>
                          </td>

                          <td>
                            <p className="text-center my-auto">
                              {Number(curr?.quantity)}
                            </p>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end me-3">
                              ৳ {curr.total}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="row d4 d5 calc-main">
              <div className="col-12 col-md-4 col-lg-6 calc-empty"></div>
              <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-end calc">
                <div className=" ">
                  <table>
                    <tbody>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td
                          style={{ minWidth: "230px" }}
                          className="tab-b ps-3"
                        >
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Sub Total : </div>
                            <div>
                              ৳{" "}
                              {data?.invoice_products
                                .map((curr) => Number(curr.total))
                                .reduce((a, b) => a + b, 0)}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Delivery Charge : </div>
                            <div>৳ {data.delivery_charge}</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Discount : </div>
                            <div>
                              {data?.discount_type === 0 ? "৳" : ""}{" "}
                              {data.total_discount}{" "}
                              {data?.discount_type === 1 ? "%" : ""}{" "}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Total {data.total_amount < 0 ? "(Non-refundable)" : ""}: </div>
                            <div>৳ {Math.abs(data.total_amount)}</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Paid Amount: </div>
                            <div>৳ {data?.total_paid}</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Due Amount : </div>
                            <div style={{ color: "red" }}>
                              ৳ {data.total_due}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="calc-main2">
              <div className=" calc2">
                <div className="t-class">
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Sub Total : </div>
                      <div>
                        ৳{" "}
                        {Jdata.map((curr) => curr.total).reduce(
                          (a, b) => a + b,
                          0
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Delivery Charge : </div>
                      <div>৳ {data.delivery_charge}</div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Discount : </div>
                      <div>
                        {data?.discount_type === 0 ? "৳" : ""}{" "}
                        {data.total_discount}{" "}
                        {data?.discount_type === 1 ? "%" : ""}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Total {data.total_amount < 0 ? "(Non-refundable)" : ""}: </div>
                      <div>৳ {Math.abs(data.total_amount)}</div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Paid Amount: </div>
                      <div>৳ {data?.total_paid}</div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Due Amount : </div>
                      <div style={{ color: "red" }}>৳ {data.total_due}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* table */}
        <div className="mx-2 mx-md-3 ">
          <div className="border-ex"></div>
        </div>

        <div className="my-4 table-cont address mt-4">
          <p style={{ fontSize: "19px" }} className="dt">
            {data.notes}
          </p>
        </div>
      </div>
      <div className=" invoice-button">
        <button
          className="me-2 my-2 button-1"
          onClick={() => handleClick(data)}
        >
          <img src={print} alt="" /> Print
        </button>
        <button
          onClick={sendPdfToEmail}
          className={`my-2 button-2 ${!data.to_email && "d-none"}`}
        >
          <img src={send} alt="" /> Send Invoice
        </button>
      </div>
    </div>
  );
};

export default ViewExchangeInvoice;
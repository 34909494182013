import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import plus from "../../assets/Icon/plus.svg";
import catIcon from "../../assets/Icon/category icon.svg";
import productIcon from "../../assets/Icon/Product icon.svg";
import salesIcon from "../../assets/Icon/sales Icon.svg";
import invoiceIcon from "../../assets/Icon/invoice icon.svg";
import blueTime from "../../assets/Icon/blue time.svg";
import blueArrow from "../../assets/Icon/blue arrow.svg";
import AreaChart from "./AreaChart";
import ViewIcon from "../CustomCommons/ViewIcon";
import EditIcon from "../CustomCommons/EditIcon";
import DeleteIcon from "../CustomCommons/DeleteIcon";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import * as path from "../Routes/RoutePaths";
import { BASE_URL } from "../Const/Url";
import axios from "axios";
import { deliveryStatus, paymentStatus } from "../Const/Status";
import { Cube } from "react-preloaders";
import UseData from "../Const/UseData";
import { HashLoader } from "react-spinners";
import Loader from "../CustomCommons/Loader";
import ReactPaginate from "react-paginate";
import NextIcon from "../CustomCommons/NextIcon";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import CountUp from "react-countup";
import {
  can_change_invoice,
  can_delete_invoice,
  can_view_invoice,
  has_permissions,
} from "../CustomCommons/utils";
import OfsetPaggination from "./OfsetPaggination";
import { Link } from "react-router-dom";
import { showToast } from "../../utils/ToastHelper";
import { logout_func2 } from "../Const/logoutCommon";
import InvoiceList from "../invoice/InvoiceList";
import CashReport from "../Reports/CashReport";
import DiscountReport from "../Reports/DiscountReport";

const Dashboard = () => {
  const [totalData, setTotalData] = useState(0);
  const [query, setQuery] = useState("");
  const history = useHistory();
  const [items_data, setItems_data] = useState([]);
  const [selectVal, setSelectVal] = useState("Lsat Week");
  const [dataDrop, setDataDrop] = useState(false);
  if (!localStorage.getItem("is_logged_in")) {
    history.push("/login/");
  }
  //  ofet code
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 20;

  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const logout_func = () => {
    localStorage.removeItem("is_logged_in");
    localStorage.removeItem("access_token");
    localStorage.removeItem("userData");
    setOnlogoutHide(1);

    history.push("/login");
  };

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        SetisLoading2(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message, "errrrrrr");
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  // useEffect(() => {
  //   apiFunction(
  //     `${BASE_URL}${"api/v1/sales/inventory/all-invoice/"}?limit=${limit}&offset=${offset}&query=${query}`
  //   );
  // }, []);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
    // SetisLoading(false)
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
    // SetisLoading(false)
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);
  // useEffect(() => {
  //   if (pageNumber > 1 || query) {
  //     apiFunction(
  //       `${BASE_URL}${"api/v1/sales/inventory/all-invoice/"}?limit=${limit}&offset=${offset}&query=${query}`
  //     );
  //   }
  // }, [pageNumber, query]);
  //  ofet code
  const updatedPageNumber = parseInt(
    localStorage.getItem("allInvoicePageNbr")
      ? localStorage.getItem("allInvoicePageNbr")
      : 0
  );

  const usersPerPage = 15;

  const [dashboardCountData, setDashboardCountData] = useState({
    product: 0,
    invoice: 0,
    sales: 0,
    category: 0,
  });
  console.log("count", dashboardCountData);
  const [invoiceList, setInvoiceList] = useState([]);

  const [isLoading, SetisLoading] = useState(true);

  // const [data,loading] = UseData(`${BASE_URL}api/v1/sales/inventory/invoice/`)
  const getInvoiceList = () => {
    const url = `${BASE_URL}api/v1/sales/inventory/all-invoice/`;
    axios
      .get(url)
      .then((res) => {
        setInvoiceList(res.data.data.results);
        // console.log(res.data);
        
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        SetisLoading(false);
      });
  };
  // useEffect(() => {
  //   getInvoiceList();
  // }, []);
  const [checkedIds, setCheckedIds] = useState([]);
  const handleCheckboxChange = (id) => {
    // Check if the ID is already in the state
    const isChecked = checkedIds.includes(id);

    if (isChecked) {
      // If checked, remove the ID from the state
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      // If unchecked, add the ID to the state
      setCheckedIds([...checkedIds, id]);
    }
  };
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked) {
      const data = items_data.map((item) => item.id);
      setCheckedIds(data);
    } else {
      setCheckedIds([]);
    }
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/sales/inventory/all-invoice/${id}`;
        axios
          .delete(url)
          .then(() => {
            // showToast("Delele", "Data Deleted");
            getInvoiceList();
            apiFunction(
              `${BASE_URL}${"api/v1/sales/inventory/all-invoice/"}?limit=${limit}&offset=${offset}&query=${query}`
            );
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(err.request.response);
          });

        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  const deleteDataMulti = (ids) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map(id => {
          const url = `${BASE_URL}api/v1/sales/inventory/all-invoice/${id}`;
          return axios.delete(url);
        });
  
        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been deleted!", {
              icon: "success",
            });
            setCheckedIds([])
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {
            // Call getProduct and apiFunction regardless of success or failure
            getInvoiceList();
            // apiFunction(
            //   `${BASE_URL}${"api/v1/sales/inventory/all-invoice/"}?limit=${limit}&offset=${offset}&query=${query}`
            // );
          
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  
  const keys = ["number", "total_amount", "prepared_by", "invoice_date"];
  const getData = invoiceList.filter((p) =>
    keys.some((key) => p[key]?.toString().toLowerCase().includes(query))
  );

  // const getData = invoiceList === undefined ? [] : invoiceList.filter((p) => keys.some((key) => p[key].toLowerCase().includes(query)));

  const select = ["Last Week", "Last Month", "Last Year"];
  const lastSelect = useRef();
  const handleSelect = () => {
    setDataDrop(!dataDrop);
  };
  useEffect(() => {
    const closeUser = (e) => {
      if (e.path[0] !== lastSelect.current) {
        // setDataDrop(1)
      }
    };
    document.body.addEventListener("click", closeUser);

    return () => document.body.removeEventListener("click", closeUser);
  }, []);
  // console.log(invoiceList, "-------list----------");
  const userProfile = JSON.parse(window.localStorage.getItem("userData"));

  const getDashboard = () => {
    const url = `${BASE_URL}api/v1/auth/dashboard/?outlet=${userProfile?.outlet}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res);

        setDashboardCountData(res.data.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    getDashboard();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  const invoice_choices = ["Paid", "Unpaid", "Due"];

  const delivery_choices = ["Processing", "Recieved", "Delivered"];
  const delivery_type_choices = ["Regular", "Urgent"];
  const paymentType = ["C.O.D", "Card", "Bank Transfer", "bKash",'SSLECOMMERZ','Nagad','KOD'];
  const sourceList = ["Website", "Inventory", "Admin"];
  return (
    <div>
      <div className="categorey-parent " style={{ height: "100%" }}>
        <div className="only-margin">
          {/* <Cube/> */}
          <div className="  stat-box-main">
            {/* <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={catIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Total Category</p>
                <h5 className="state-head">
                  <CountUp
                    end={
                      dashboardCountData.category !== undefined
                        ? dashboardCountData.category
                        : 1232
                    }
                    duration={1}
                  />
                </h5>
              </div>
            </div> */}
            
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={productIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Total Product</p>
                <h5 className="state-head">
                  <CountUp
                    end={
                      dashboardCountData.product !== undefined
                        ? dashboardCountData.product
                        : 0
                    }
                    duration={1}
                  />

                  <span className="text-white">৳</span>
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={salesIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Total Sales</p>
                <h5 className="state-head">
                  ৳
                  <CountUp
                    end={
                      dashboardCountData.sales !== undefined
                        ? dashboardCountData.sales
                        : 0
                    }
                    duration={0.2}
                  />
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={invoiceIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Total Invoice</p>
                <h5 className="state-head">
                  <CountUp
                    end={
                      dashboardCountData.invoice !== undefined
                        ? dashboardCountData.invoice
                        : 0
                    }
                    duration={1}
                  />

                  <span className="text-white">৳</span>
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={invoiceIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Total User</p>
                <h5 className="state-head">
                  <CountUp
                    end={
                      dashboardCountData.users
                      !== undefined
                        ? dashboardCountData.users

                        : 0
                    }
                    duration={1}
                  />

                  
                </h5>
              </div>
            </div>
          </div>
        </div>
        
        {/* ==============React Chart ============== */}{" "}
        {/* <AreaChart chartLabel="Sales Report" />{" "} */}
        {/* Resent Sale card start fana */}
        <div className="only-margin">
          {/* <Cube/> */}
          <div className="  stat-box-main">
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={catIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Today Sales</p>
                <h5 className="state-head">
                  ৳
                  <CountUp
                    end={
                      dashboardCountData.todays_sale_amount !== undefined
                        ? dashboardCountData.todays_sale_amount
                        : 0
                    }
                    duration={1}
                  />
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={productIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>This Week Sales</p>
                <h5 className="state-head">
                  ৳
                  <CountUp
                    end={
                      dashboardCountData.weekly_sale_amount !== undefined
                        ? dashboardCountData.weekly_sale_amount
                        : 0
                    }
                    duration={1}
                  />
                  <span className="text-white">৳</span>
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={salesIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>This Month Sales</p>
                <h5 className="state-head">
                  ৳
                  <CountUp
                    end={
                      dashboardCountData.monthly_sale_amount !== undefined
                        ? dashboardCountData.monthly_sale_amount
                        : 0
                    }
                    duration={0.2}
                  />
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={invoiceIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Last Month Sales</p>
                <h5 className="state-head">
                  ৳
                  <CountUp
                    end={
                      dashboardCountData.previous_month_sale_amount !==
                      undefined
                        ? dashboardCountData.previous_month_sale_amount
                        : 0
                    }
                    duration={1}
                  />
                  <span className="text-white">৳</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/* <DiscountReport/> */}
        {/* Resent Sale card */}
        <CashReport limit_data={7} />
        {/* ==============React Chart ============== */}{" "}
        {/* <AreaChart chartLabel="Sales Report" />{" "} */}
        {/* ==============React Chart ============== */}
       

        <InvoiceList limit_data={7}/>
      </div>
    </div>
  );
};

export default Dashboard;

import React from "react";
import CountUp from "react-countup";

const SalesCard = ({ img, value, title }) => {
  return (
    <div className=" state-main  mt-2 bg-white">
      <div className="state-img-box">
        <img src={img} width={26} height={24} alt="" />
      </div>
      <div className="mx-4 mb-2">
        <p>{title}</p>
        <h6 className="state-head">
          <CountUp end={value} duration={1} />
          <span className="text-white">৳</span>
        </h6>
      </div>
    </div>
  );
};

export default SalesCard;

// export const dasshboard = "/";
// // inventory paths
// export const add_category = "/inventory/add-category";
// export const category_list = "/inventory/category-list";
// export const add_product = "/inventory/add-product";
// export const product_list = "/inventory/product-list";
// export const chalan_list = "/inventory/chalan-list";
// export const product_view = "/inventory/product-view";
// export const add_attribute = "/inventory/add-attribute";
// export const bulk_import = "/inventory/bulk-import";
// // Sales paths
// export const create_invoice = "/sales/create-invoice";
// export const invoice_list = "/sales/invoice-list";
// export const exchange_list = "/sales/exchange-list";
// export const view_invoice = "/sales/view-invoice/";
// export const create_refund = "/sales/create-refund-invoice/";
// export const create_exchange = "/sales/create-exchange-invoice/";
// // customars path
// export const customer_list = "/customer/customer-list";
// export const add_customer = "/customer/add-customer";
// export const customer_bulk_import = "/customer/bulk-import";
// // notification
// export const notification = "/notification";
// // user path
// export const add_user = "/user/add-user";
// export const add_role = "/user/add-role";
// export const add_group = "/user/add-group";
// export const user_list = "/user/user-list";
// export const web_user_list = "/user/website_user";
// export const user_view = "/user/user-View";
// // reports
// export const reports = "/report/reports";
// export const sales_report = "/report/daily-sales-report";
// export const cash_report = "/report/daily-cash-report";
// export const discount_report = "/report/discount-report";
// export const area_report = "/report/area-invoice-report";
// // custom
// export const custom_create_invoice = "/custom/create-custom-invoice";
// export const custom_invoice_list = "/custom/custom-invoice-list";
// export const custom_sales_report = "/custom/custom-sales-report";
// export const purchace_order_list = "/custom/purchace-order-list";
// export const add_purchace_order = "/custom/add-purchace-order";
// export const add_requisition_order = "/custom/add-requisition-order";
// export const requisition_order_list = "/custom/requisition-order-list";
// export const requisition_order_report = "/custom/requisition-order-report";
// export const purchace_order_report = "/custom/purchace-order-report";
// // offers
// export const offer = "/promotions/offer";
// export const cupons = "/promotions/coupons";
// export const review = "/promotions/review";
// export const banner = "/promotions/banner";
// export const contactUs = "/promotions/contact-us";
// export const work_with_us = "/promotions/work_with_us";
// // web settings
// export const global_setting = "/web-settings/global-setting";
// export const main_setting = "/web-settings/main-setting";
// export const display_center = "/web-settings/display-center";
// export const testimonials = "/web-settings/testimonials";
// export const teammember = "/web-settings/teammember";
// // social media 
// export const social_media = '/web-settings/social-media'
// export const add_social_media = '/web-settings/add-social-media'
// export const edit_social_media = '/web-settings/edit-social-media'
// // outlets
// export const add_outlet = '/outlet/add_outlet'
// export const outlet_list = '/outlet/outlet_list'
// // return product 
// export const add_return_products = '/inventory/add_return_product'
// export const edit_return_products = '/inventory/edit_return_product/:id'
// export const view_return_products = '/inventory/view_return_product/:id'
// export const return_products = '/inventory/returm_product_list'


export const dasshboard = "";
// inventory paths
export const add_category = "/inventory/add-category";
export const category_list = "/inventory/category-list";
export const add_product = "/inventory/add-product";
export const product_list = "/inventory/product-list";
export const chalan_list = "/inventory/chalan-list";
export const product_view = "/inventory/product-view";
export const add_attribute = "/inventory/add-attribute";
export const bulk_import = "/inventory/bulk-import";
// Sales paths
export const create_invoice = "/sales/create-invoice";
export const create_invoice_lo = "/sales/create-invoice-lo";
export const create_invoice_mo = "/sales/create-invoice-mo";
export const invoice_list = "/sales/invoice-list";
export const exchange_list = "/sales/exchange-list";
export const view_invoice = "/sales/view-invoice";
export const create_refund = "/sales/create-refund-invoice";
export const create_exchange = "/sales/create-exchange-invoice";
// customars path
export const customer_list = "/customer/customer-list";
export const add_customer = "/customer/add-customer";
export const customer_bulk_import = "/customer/bulk-import";
// notification
export const notification = "/notification";
// user path
export const add_user = "/user/add-user";
export const add_role = "/user/add-role";
export const add_group = "/user/add-group";
export const user_list = "/user/user-list";
export const web_user_list = "/user/website_user";
export const user_view = "/user/user-View";
// reports
export const reports = "/report/reports";
export const sales_report = "/report/daily-sales-report";
export const cash_report = "/report/daily-cash-report";
export const discount_report = "/report/discount-report";
export const area_report = "/report/area-invoice-report";
export const sales_report_dash = "/report/product-report";
export const sales_report_inv = "/report/sales-report-invoice";
// custom
export const custom_create_invoice = "/custom/create-custom-invoice";
export const custom_invoice_list = "/custom/custom-invoice-list";
export const custom_sales_report = "/custom/custom-sales-report";
export const purchace_order_list = "/custom/purchace-order-list";
export const add_purchace_order = "/custom/add-purchace-order";
export const add_requisition_order = "/custom/add-requisition-order";
export const requisition_order_list = "/custom/requisition-order-list";
export const requisition_order_report = "/custom/requisition-order-report";
export const purchace_order_report = "/custom/purchace-order-report";
// offers
export const offer = "/promotions/offer";
export const cupons = "/promotions/coupons";
export const review = "/promotions/review";
export const banner = "/promotions/banner";
export const contactUs = "/promotions/contact-us";
export const work_with_us = "/promotions/work_with_us";
// web settings
export const global_setting = "/web-settings/global-setting";
export const main_setting = "/web-settings/main-setting";
export const display_center = "/web-settings/display-center";
export const testimonials = "/web-settings/testimonials";
export const teammember = "/web-settings/teammember";
// social media 
export const social_media = '/web-settings/social-media'
export const add_social_media = '/web-settings/add-social-media'
export const edit_social_media = '/web-settings/edit-social-media'
// outlets
export const add_outlet = '/outlet/add_outlet'
export const outlet_list = '/outlet/outlet_list'
// return product 
export const add_return_products = '/inventory/add_return_product'
export const edit_return_products = '/inventory/edit_return_product/:id'
export const view_return_products = '/inventory/view_return_product/:id'
export const return_products = '/inventory/returm_product_list'
// relocation

export const add_relocation = '/Relocation/add_outlet_to_outlet_product'
export const relocation_send = '/Relocation/sended_product'
export const relocation_received = '/Relocation/received_product'
export const relocation_view = '/Relocation/view_relocation'
import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { showToast } from "../../utils/ToastHelper";
import axios from "axios";
import { BASE_URL } from "./Url";

const VerifyVoucher = ({
  number,
  success,
  setSuccess,
  productIds,
  setVoucherData,
}) => {
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(false);
  const [pointValue, setPointValue] = useState("");
  function getIds(obj) {
    // Array to store the result
    const ids = [];

    // Iterate over each key in the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Push the main id into the ids array
        ids.push(obj[key].id);
      }
    }

    return ids;
  }

  const ids = getIds(productIds);
  const verifyPoint = async () => {
    const url = `${BASE_URL}api/v1/promotions/admin/voucher-verify/`;
    setIsloading(true);
    setError(null);
    setSuccess(false);
    try {
      const res = await axios.post(url, {
        code: pointValue,
        mobile: number,
        product_ids: ids,
      });
      
      if (res.data.status) {
        showToast("success", "Voucher Verified.");
        setSuccess(true);
        setVoucherData(res.data.data);
      }
    } catch (err) {
      let message = "An error occurred";
      if (err.response && err.response.data) {
        const errorData = err.response.data;
        
        // Check if the 'errors' object contains 'non_field_errors'
        if (errorData.errors && errorData.errors.non_field_errors && errorData.errors.non_field_errors.length > 0) {
          // Extract the first error message from 'non_field_errors'
          message = errorData.errors.non_field_errors[0];
        } else if (errorData.errors && errorData.errors.message) {
          // Check if there is a 'message' in 'errors'
          message = errorData.errors.message;
        } else if (errorData.message) {
          // Check if there is a general 'message'
          message = errorData.message;
        }
      }
      setError(message);
      setSuccess(false);
      showToast("error", message);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div className="col-12 col-md-6">
      <p>Verify Voucher </p>
      <div className="d-flex position-relative ">
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="Username"
            type="text"
            name="voucher"
            disabled={success && pointValue.length === 0}

            value={pointValue}
            onChange={(e) => setPointValue(e.target.value)}
            aria-describedby="basic-addon1"
            style={{ backgroundColor: "#FAFAFA" }}
          />
        </InputGroup>

        <div className="ms-2 mb-3 d-flex gap-1">
          {success ? (
            <>
              <button disabled className="btn btn-small btn-success">
                Verified
              </button>
              {/* <button
                onClick={() => {
                  setSuccess(false);
                }}
                className="btn btn-small btn-danger"
              >
                Reset
              </button> */}
            </>
          ) : (
            <>
              {isLoading ? (
                <button disabled className="btn btn-small btn-danger">
                  Verifying...
                </button>
              ) : (
                <button
                  onClick={verifyPoint}
                  disabled={pointValue.length === 0}
                  className="btn btn-small btn-danger"
                >
                  Verify
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyVoucher;

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from "react-router-dom";
// // import "bootstrap/dist/css/bootstrap.min.css";
// const root = ReactDOM.createRoot(document.getElementById('root'));
// require("./services/axios");
// root.render(
//   // <React.StrictMode>
//     <BrowserRouter>
//       <App /> 
//     </BrowserRouter>
//   // </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "react-redux"
import configureStore from './store/configureStore';

import { BrowserRouter } from "react-router-dom";
const configured = configureStore();
window.store = configured.store;

const root = ReactDOM.createRoot(document.getElementById('root'));
require("./services/axios");

root.render(
  // <React.StrictMode>
    <Provider {...configured}>
      <BrowserRouter>
      <App />
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>,
  // document.getElementById('root')
);

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import Select from "react-select";
import { showToast } from "../../../utils/ToastHelper";
import { Link, useHistory } from "react-router-dom";
import * as path from "../../Routes/RoutePaths";

const AddReturnProduct = () => {
  const history = useHistory();
  const userProfile = JSON.parse(window.localStorage.getItem("userData"));
  const [mainState, setMainState] = useState({});
  const [mainInnerState, setMainInnerState] = useState({});
  const [note,setNote] = useState('')
  const [ProductCom, setProductCom] = useState([]);
  const [defaultValue, setdefaultValue] = useState([]);
  const [inputQuery, setInputQuery] = useState("");
  const [ProductsList, setProductsList] = useState([]);
  const [pS, setPs] = useState(0);
  const [isLoading, SetisLoading] = useState(false);
  const getProductList = () => {
    if (inputQuery?.length > 1) {
      let url;
      if (!inputQuery || inputQuery === "" || inputQuery === undefined) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/${userProfile?.outlet}/outlet`;
      } else if (inputQuery) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/${userProfile?.outlet}/outlet/?query=${inputQuery}`;
      }
      axios
        .get(url)
        .then((res) => {
          // console.log("productlistinvoice", res.data);
          const result = res.data.data;
          console.log(result, "------productlistinvoice-----");
          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            price: curr.price,
            discount: curr.discount,
            stock: curr.stock,
            variant: curr.variants,
            thumb: `${curr.thumb_url}`,
          }));

          setProductsList(options);
          // SetisLoading(false);
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };
  useEffect(() => {
    getProductList();
  }, [inputQuery]);
  const handleMultiInputChange = (newValue, actionMeta) => {
    // const newVal = actionMeta?.option?.label?.split("[[")[0];
    const newVal = actionMeta?.option?.label;
    setProductCom(newValue);
    console.log("newValue 123", newValue);
    console.log("newValue 1234", actionMeta?.option?.label);
    if (actionMeta.action === "select-option" && actionMeta?.option?.variant) {
      let variants = actionMeta?.option?.variant.map((curr) => ({
        id: curr.id,
        name: curr.name,
        price: curr.price,
        stock: curr.stock,
        product: curr.product,
        quantity: -1,
        total: 0,
      }));
      const variantsObj = {};
      actionMeta.option?.variant.map(
        (curr) =>
          (variantsObj[curr.name] = {
            id: curr.id,
            name: curr.name,
            price: curr.price,
            stock: curr.stock,
            product: curr.product,
            quantity: -1,
            total: 0,
          })
      );

      setMainState({
        ...mainState,
        [newVal]: {
          id: actionMeta.option.value,
          variant: variants,
          variantObj: variantsObj,
          price: parseInt(actionMeta.option.price),
          stock: parseInt(actionMeta.option.stock),
          thumb: actionMeta.option.thumb,
          discount: parseInt(actionMeta.option.discount),
        },
      });
      setdefaultValue([
        ...defaultValue,
        {
          value: actionMeta.option.value,
          label: newVal,
        },
      ]);
    }
    //remove data from select
    if (actionMeta.action === "remove-value") {
      delete mainState[actionMeta.removedValue.label];
      setMainState({ ...mainState });
      setdefaultValue((prevDefaultValue) =>
        prevDefaultValue.filter(
          (value) =>
            value.label !== actionMeta.removedValue.label &&
            value.value !== actionMeta.removedValue.value
        )
      );
      // setScanCode("");
    }
  };
  const inputProduct = (inputValue) => {
    setInputQuery(inputValue);
    console.log("searched", inputQuery);
  };
  const handleInnerVariant = (currentName, value) => {
    // console.log("currentName", currentName);
    console.log("value", currentName, value);
    setMainInnerState({
      ...mainInnerState,
      [currentName]: value,
    });
  };
  const getDiscountOrMainPrice = (curr, innerCur) => {
    return parseInt(
      mainState[curr] !== undefined &&
        (mainState[curr].discount === 0
          ? mainState[curr].price
          : mainState[curr].discount)
    );
  };
  const getBasePlusVariant = (curr, innerCur) => {
    return getDiscountOrMainPrice(curr, innerCur) + parseInt(innerCur.price);
  };
  const saveProduct = () => {
    // Initialize an array to store the resulting objects
    const payload = [];

    // Iterate over mainState and create objects
    for (const productName in mainState) {
      if (mainState.hasOwnProperty(productName)) {
        const productInfo = mainState[productName];
        const variants = mainInnerState[productName].map((variant) => {
          return {
            stock: mainState[productName].variant.find(({ id }) => id == variant.value).quantity,
            variant: variant.value,
            is_return: true,
          };
        });

        payload.push({
          outletVariant: variants,
          stock: variants.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.stock);
          }, 0),
          product: productInfo.id,
          outlet: userProfile?.outlet,
          is_return:true,
          notes:note // You can set this value as needed
        });
      }
    }
    axios
      .post(`${BASE_URL}api/v1/inventory/admin/outlet-product-return/`, payload)
      .then((res) => {
        if (res.data.status) {
          // clearData();
          showToast("success", "Product Added for return");
          history.push(path.return_products);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        console.log(errorMsg[0].non_field_errors[0].replace(/['{}]/g, ''),'187')
        showToast("error", errorMsg[0]?.non_field_errors[0]?.replace(/['{}]/g, ''));
        for (let key in errorMsg[0]) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        
      });
  };
  const VariantThead = () => {
    return (
      <>
        {Object.keys(mainInnerState).length > 0 && (
          <thead style={{ border: "1.5px solid #E0E0E0" }}>
            <tr className="px-3" style={{ height: "45px" }}>
              <th
                className="minWidthForInvoiceCreateTable ps-4"
                style={{ width: "349px", textAlign: "start" }}
              >
                Variant
              </th>
              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Price
              </th>

              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                In Stock
              </th>
              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Quantity
              </th>
            </tr>
          </thead>
        )}
      </>
    );
  };
  return (
    <div className="categorey-parent">
      <div className="bg-white cat-child ">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 24px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3
            style={{ fontSize: "23px", marginTop: "6px", marginLeft: "12px" }}
          >
            Add return product
          </h3>
        </div>

        <div className="product-main createInvoiceInputContainer">
          <div className="row">
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="Invoice Number" /> */}
              <p>Outlet Name</p>
              <InputGroup className="mb-3">
                <Form.Control
                  disabled
                  aria-label="Username"
                  placeholder={`${userProfile?.outlet_name}`}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="Invoice Number" /> */}
              <p>User Name</p>
              <InputGroup className="mb-3">
                <Form.Control
                  disabled
                  aria-label="Username"
                  placeholder={`${userProfile?.first_name} ${userProfile?.last_name}`}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="Invoice Number" /> */}
              <p>Proper Note</p>
              <Form.Control
                as="textarea"
                placeholder=""
                className="mb-3"
                name="invoice_notes"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                style={{
                  height: "100px",
                  resize: "none",
                  backgroundColor: "#FAFAFA",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="invoice-b my-4 bg-white cat-child"
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <h4 className=" my-2 mb-4 padding-main-head">Product Details</h4>
        <div className="border-p"></div>
        <div className="padding-main">
          <div className="main-select">
            <Select
              // styles={styles}
              isMulti
              placeholder="---Select---"
              isClearable
              // defaultValue={selectedOption}
              onChange={handleMultiInputChange}
              onInputChange={inputProduct}
              options={ProductsList}
              value={defaultValue}
            />
          </div>

          {/* </div> */}
          {/* </div> */}
          <div className="mt-3">
            <div
              className="rounded"
              style={{
                background: "#FAFAFA",
                display: "block",
                overflowX: "hidden",
                width: "100%",
              }}
            >
              <table className="item-tables table_my2">
                <tbody>
                  {/* //Here-------------------------- */}
                  {Object.keys(mainState).map((curr, index) => {
                    // console.log("curr---------", curr);
                    // console.log("curr---------var", mainState[curr]["variant"]);

                    return (
                      <>
                        {mainState[curr]["variant"]?.length > 0 ? (
                          <>
                            <div
                              className="mt-4  px-2"
                              style={{
                                background: " rgba(245, 245, 245, 0.36)",
                                border: "none",
                                height: "119px",
                              }}
                            >
                              <div
                                className="row py-3 my-3"
                                style={{ height: "45px" }}
                              >
                                <div
                                  className="col-2 minWidthForInvoiceCreateTable ps-4"
                                  style={{ textAlign: "start" }}
                                >
                                  <p>Product</p>
                                </div>
                                <div
                                  className="col-4 minWidthForInvoiceCreateTable  py-1"
                                  style={{ textAlign: "center" }}
                                >
                                  Total Stock
                                </div>
                                <div
                                  className="col-5 minWidthForInvoiceCreateTable  py-1"
                                  style={{ textAlign: "center" }}
                                >
                                  Variants
                                </div>
                              </div>
                              <div className="row  " style={{ height: "45px" }}>
                                <div className="col-2 ps-4 py-1 d-flex align-items-center">
                                  <p className="text-capitalize">{curr}</p>
                                  <img
                                    width={50}
                                    style={{
                                      margin: "6px 0px !important",
                                      border: "0.2px solid #bcb0b0",
                                    }}
                                    height={50}
                                    src={!isLoading && mainState[curr]["thumb"]}
                                    alt="failed"
                                    className="ms-3 shadow-sm"
                                  />
                                  {/* {console.log(" mainState[curr][thumb", mainState[curr]["thumb"])} */}
                                </div>
                                <div className="col-4 py-1">
                                  <p className="text-capitalize text-center d-flex align-items-center justify-content-center">
                                    {Number(mainState[curr]["stock"])}
                                    {/* {Number(mainState[curr]["stock"]) - Number(pS)} */}
                                  </p>
                                </div>
                                <div className="col-5 text-center   py-1 pe-2">
                                  <div
                                    className=""
                                    style={{ marginTop: "-10px" }}
                                  >
                                    {/* <div className="" style={{ marginTop: "-10px" }}> */}
                                    <Select
                                      styles={{
                                        height: "45px",
                                        background: "black",
                                      }}
                                      isMulti
                                      placeholder="-Select Variants-"
                                      isClearable
                                      onChange={(value) => {
                                        handleInnerVariant(curr, value);
                                      }}
                                      options={
                                        mainState[curr]["variant"] !==
                                          undefined &&
                                        Object.values(
                                          mainState[curr]["variant"]
                                        ).map((curr) => ({
                                          label: curr.name,
                                          value: curr.id,
                                          price: curr.price,
                                          stock: curr.stock,
                                          quantity: 1,
                                        }))
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <table className="mapTable shadow-sm ms-2">
                              <thead
                                className="mt-4"
                                style={{
                                  background: " rgba(245, 245, 245, 0.36)",
                                }}
                              ></thead>
                              <tbody className="innerTbody w-100">
                                {mainInnerState !== undefined &&
                                  mainInnerState[curr] !== undefined &&
                                  mainInnerState[curr].map(
                                    (innerCur, index) => {
                                      return (
                                        <>
                                          {index === 0 && <VariantThead />}

                                          <tbody>
                                            <tr
                                              className="border-invoice"
                                              style={{ height: "45px" }}
                                            >
                                              <td className="ps-4 py-3">
                                                <p className="text-capitalize">
                                                  {innerCur.label}
                                                </p>
                                                {/* <input className="invoiceCreateBigInputs" value={name} type="text" /> */}
                                              </td>
                                              <td
                                                className="text-center   py-3"
                                                style={{ minWidth: "300px" }}
                                              >
                                                <input
                                                  name="in_price"
                                                  // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                  value={getBasePlusVariant(
                                                    curr,
                                                    innerCur
                                                  )}
                                                  className="invoiceCreateSmallInputs"
                                                  placeholder="00.00"
                                                  type="text"
                                                  //
                                                />
                                              </td>
                                              <td className="text-center   py-3">
                                                <input
                                                  name="in_price"
                                                  // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                  value={Number(innerCur.stock)}
                                                  className="invoiceCreateSmallInputs"
                                                  placeholder="00.00"
                                                  type="text"
                                                />
                                                {/* {console.log(mainState[curr]["variantObj"][innerCur],'------vvv----------')} */}
                                              </td>
                                              <td className="text-center   py-3">
                                                <input
                                                  name="in_quantity"
                                                  onWheel={(e) =>
                                                    e.target.blur()
                                                  }
                                                  onChange={(e) => {
                                                    // HandleNewProductsInputValue(e, innerCur);
                                                    const productName = curr;
                                                    if (e.target.value < 0) {
                                                      setPs(0);
                                                    } else {
                                                      setPs(e.target.value);
                                                    }

                                                    setMainState(
                                                      (prevInputs) => {
                                                        const copiedObjects =
                                                          Object.assign(
                                                            {},
                                                            mainState
                                                          ); // Shallow copy
                                                        // console.log("copiedObjects", copiedObjects);
                                                        // copiedObjects["Blankets"]["variant"][index]['quantity'] = 12; // Assign new value
                                                        const keyOfVariant =
                                                          Object.keys(
                                                            copiedObjects[
                                                              productName
                                                            ]["variant"]
                                                          ).filter(
                                                            (curr) =>
                                                              copiedObjects[
                                                                productName
                                                              ]["variant"][
                                                                curr
                                                              ]["name"] ===
                                                              innerCur.label
                                                          );
                                                        const variantName =
                                                          innerCur.label;
                                                        // console.log("innercur", innerCur);
                                                        if (
                                                          parseInt(
                                                            e.target.value
                                                          ) < 0
                                                        ) {
                                                          copiedObjects[
                                                            productName
                                                          ]["variant"][
                                                            keyOfVariant
                                                          ]["quantity"] = 0;
                                                          copiedObjects[
                                                            productName
                                                          ]["variantObj"][
                                                            variantName
                                                          ]["quantity"] = 0;
                                                        } else if (
                                                          parseInt(
                                                            e.target.value
                                                          ) > innerCur.stock
                                                        ) {
                                                          showToast(
                                                            "error",
                                                            `Max Stock : ${innerCur.stock}`
                                                          );
                                                          copiedObjects[
                                                            productName
                                                          ]["variant"][
                                                            keyOfVariant
                                                          ]["quantity"] = 0;
                                                          copiedObjects[
                                                            productName
                                                          ]["variantObj"][
                                                            variantName
                                                          ]["quantity"] = 0;
                                                          copiedObjects[
                                                            productName
                                                          ]["variant"][
                                                            keyOfVariant
                                                          ]["quantity"] =
                                                            innerCur.stock;
                                                          copiedObjects[
                                                            productName
                                                          ]["variantObj"][
                                                            variantName
                                                          ]["quantity"] =
                                                            innerCur.stock;
                                                        } else {
                                                          copiedObjects[
                                                            productName
                                                          ]["variant"][
                                                            keyOfVariant
                                                          ]["quantity"] =
                                                            e.target.value;
                                                          copiedObjects[
                                                            productName
                                                          ]["variantObj"][
                                                            variantName
                                                          ]["quantity"] =
                                                            e.target.value;
                                                        }

                                                        let checkDiscountPrice =
                                                          parseInt(
                                                            copiedObjects[
                                                              productName
                                                            ]["discount"]
                                                          ) === 0
                                                            ? parseInt(
                                                                copiedObjects[
                                                                  productName
                                                                ]["price"]
                                                              )
                                                            : parseInt(
                                                                copiedObjects[
                                                                  productName
                                                                ]["discount"]
                                                              );
                                                        // console.log("checkDiscountPrice", checkDiscountPrice);
                                                        const baseAndVariant =
                                                          parseInt(
                                                            copiedObjects[
                                                              productName
                                                            ]["variant"][
                                                              keyOfVariant
                                                            ]["price"]
                                                          ) +
                                                          checkDiscountPrice;
                                                        copiedObjects[
                                                          productName
                                                        ]["variant"][
                                                          keyOfVariant
                                                        ]["total"] =
                                                          parseInt(
                                                            copiedObjects[
                                                              productName
                                                            ]["variant"][
                                                              keyOfVariant
                                                            ]["quantity"]
                                                          ) * baseAndVariant;

                                                        const baseAndVariantObj =
                                                          parseInt(
                                                            copiedObjects[
                                                              productName
                                                            ]["variantObj"][
                                                              variantName
                                                            ]["price"]
                                                          ) +
                                                          checkDiscountPrice;
                                                        // console.log("baseAndVariantObj price--", baseAndVariantObj);
                                                        copiedObjects[
                                                          productName
                                                        ]["variantObj"][
                                                          variantName
                                                        ]["total"] =
                                                          parseInt(
                                                            copiedObjects[
                                                              productName
                                                            ]["variantObj"][
                                                              variantName
                                                            ]["quantity"]
                                                          ) * baseAndVariantObj;

                                                        return copiedObjects; // Return copied object
                                                      }
                                                    );
                                                  }}
                                                  className="invoiceCreateSmallInputs"
                                                  placeholder="00.00"
                                                  type="number"
                                                  value={
                                                    (mainState === undefined &&
                                                      mainState[curr] ===
                                                        undefined &&
                                                      mainState[curr][
                                                        "variantObj"
                                                      ] === undefined &&
                                                      mainState[curr][
                                                        "variantObj"
                                                      ][innerCur.label] ===
                                                        undefined &&
                                                      mainState[curr][
                                                        "variantObj"
                                                      ][innerCur.label][
                                                        "quantity"
                                                      ] === undefined) ||
                                                    mainState[curr][
                                                      "variantObj"
                                                    ][innerCur.label][
                                                      "quantity"
                                                    ] === "-1" ||
                                                    mainState[curr][
                                                      "variantObj"
                                                    ][innerCur.label][
                                                      "quantity"
                                                    ] === -1
                                                      ? 0
                                                      : mainState[curr][
                                                          "variantObj"
                                                        ][innerCur.label][
                                                          "quantity"
                                                        ]
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </>
                                      );
                                      // return <h2>{innerCurr.label}</h2>;
                                    }
                                  )}
                              </tbody>

                              {/* <VariantTable /> */}
                            </table>
                          </>
                        ) : (
                          <div>No variant</div>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>

              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="padding-btn-head mb-4 d-flex justify-content-end  bg-white cat-child"
        style={{ border: "0.5px solid #E0E0E0" }}
      >
        <div className="" style={{ marginLeft: "-10px" }}>
          {/* <button onClick={postInvoiceProduct} className="btn rounded border me-2 mt-2"> */}
          <button
            onClick={saveProduct}
            className="btn rounded border me-2 mt-2"
          >
            Return
          </button>
          <button
            className="btn rounded border me-2 mt-2"
            onClick={() => history.push(path.return_products)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddReturnProduct;

import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { Alarm, CaretDown, PencilSimple, XCircle } from "phosphor-react";
import RequiredLabel from "../CustomCommons/RequiredLabel";
import PhoneInput from "react-phone-input-2";
import "./invoice.css";

import Select from "react-select";
import DoIcon from "../CustomCommons/DoIcon";

import { handleInputs } from "../../utils/HandleInputs";
import { showToast } from "../../utils/ToastHelper";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import CreatableSelect from "react-select/creatable";
import { useEffect } from "react";
import SelectDropDown from "../CustomCommons/SelectDropDown";
import swal from "sweetalert";
import { useRef } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import PreviewCreate from "./PreviewCreate";
import * as path from "../Routes/RoutePaths";
import { useHistory } from "react-router-dom";
import Loader from "../CustomCommons/Loader";
import { can_add_invoice, has_permissions } from "../CustomCommons/utils";
import Permission from "../CustomCommons/Permission";
import { logout_func2 } from "../Const/logoutCommon";
import Scanner from "./Scanner";
import Scanner2 from "./Scanner2";

import divisions from "../../assets/json/bd_divisions.json";
import allDistricts from "../../assets/json/bd_districts.json";
import upazillas from "../../assets/json/bd_upazillas.json";
import zips from "../../assets/json/bd_postcodes.json";
import { Controller, useForm } from "react-hook-form";
import SelectOpenWithPaggination from "../Const/SelectOpenWithPaggination";
import { filterMainState } from "../Const/FilterMainState";
import { memshipLabel } from "../Const/Status";
import RedeemPoint from "../Const/RedeemPoint";
import ProToPremium from "./ProToPremium";
import VerifyVoucher from "../Const/VerifyVoucher";

const CreateInvoicelClub = () => {
  const [selectedVariants, setSelectedVariants] = useState({});
  const [invoiceLoader, setInvoiceLoader] = useState(false);
  const userProfile = JSON.parse(window.localStorage.getItem("userData"));
  const [mixPaymentData, setMixPaymentData] = useState([]);
  const [dob, setDob] = useState("");
  const [membershipInfo, setMembershipInfo] = useState({});
  const [voucher, setVoucher] = useState({});
  const [redeem, setRedeem] = useState(0);
  const [success, setSuccess] = useState(false);
  const [success1, setSuccess1] = useState(false);

  const [FoundCustomer, setFoundCustomer] = useState({
    id: "",
    total_purchase: "",
    created_at: "",
    updated_at: "",
    name: "",
    mobile: "",
    email: "",
    status: "",
    address: "",
  });
  const [Found, setFound] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState({
    label: "",
    value: "",
  });
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: "",
    value: "",
  });
  const [selectedTown, setSelectedTown] = useState({ label: "", value: "" });

  // Address Form methods start
  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (Found) {
      if (FoundCustomer.redex_area) {
        // setCustomCharge(FoundCustomer?.redex_area_details.delivery_charge);
        reset({
          // division: { label: sInvoice.to_division, value: sInvoice.to_division},
          division: {
            label: FoundCustomer.redex_division_details.name,
            value: FoundCustomer.redex_division_details.id,
          },
          // district: { label: sInvoice.to_district, value: sInvoice.to_district},
          district: {
            label: FoundCustomer.redex_district_details.name,
            value: FoundCustomer.redex_district_details.id,
          },
          // town: { label: sInvoice.to_city, value: sInvoice.to_city },
          town: {
            label: FoundCustomer.redex_area_details.name,
            value: FoundCustomer.redex_area_details.id,
          },
          // zip: { label: sInvoice.to_zip_code, value: sInvoice.to_zip_code }
          zip: FoundCustomer.to_zip_code,
        });
        setSelectedDivision({
          label: FoundCustomer.redex_division_details.name,
          value: FoundCustomer.redex_division_details.id,
        });
        setSelectedDistrict({
          label: FoundCustomer.redex_district_details.name,
          value: FoundCustomer.redex_district_details.id,
        });
        setSelectedTown({
          label: FoundCustomer.redex_area_details.name,
          value: FoundCustomer.redex_area_details.id,
        });
      } else {
        reset({
          division: { label: FoundCustomer.to_division, value: "" },
          district: { label: FoundCustomer.to_district, value: "" },
          town: { label: FoundCustomer.to_city, value: "" },
          // zip: { label: sInvoice.to_zip_code, value: sInvoice.to_zip_code }
          zip: FoundCustomer.to_zip_code,
        });
        setSelectedDivision({ label: FoundCustomer.to_division, value: "" });
        setSelectedDistrict({ label: FoundCustomer.to_district, value: "" });
        setSelectedTown({ label: FoundCustomer.to_city, value: "" });
      }
    }
  }, [Found, FoundCustomer, reset]);
  const [districtList, setDistrictList] = useState([]);
  const [upazillaList, setUpazillaList] = useState([]);
  const [zipList, setZipList] = useState([]);
  const watchFields = watch([
    // "town",
    "division",
    "district",
    "town",
    "zip",
  ]);
  useEffect(() => {
    if (selectedDivision.value !== "") {
      axios
        .get(
          `${BASE_URL}/api/v1/utility/admin/redex-address/${selectedDivision.value}/`
        )
        .then((response) => {
          // setDistrictList(response.data.data.districts)
          if (response.data.data.districts !== undefined) {
            setDistrictList(response.data.data.districts);
          } else {
            setDistrictList([]);
          }
        });
    }
  }, [selectedDivision]);
  useEffect(() => {
    if (selectedDistrict.value !== "") {
      const foundAreas = districtList.filter(
        ({ id }) => id == selectedDistrict.value
      )[0]?.areas;
      // setUpazillaList(foundAreas)
      if (foundAreas !== undefined) {
        setUpazillaList(foundAreas);
      } else {
        setUpazillaList([]);
      }
    }
  }, [selectedDistrict, districtList]);
  const handleDivisionChange = (division) => {
    if (selectedDivision?.value !== undefined) {
      // const foundDistricts = allDistricts.filter(
      //   ({ division_id }) => division_id == watchFields[1].value
      // );
      // setDistrictList(foundDistricts);
      // setValue("district", { label: "", value: "" })
      setSelectedDistrict({ label: "", value: "" });
      setSelectedTown({ label: "", value: "" });
      setValue("zip", "");
      setDistrictList([]);
      setSelectedDivision(division);
      setValue("division", division);
      const selectedDivisionId = division.value;
      axios
        .get(
          `${BASE_URL}/api/v1/utility/admin/redex-address/${selectedDivisionId}/`
        )
        .then((response) => {
          // setDistrictList(response.data.data.districts)
          if (response.data.data.districts !== undefined) {
            setDistrictList(response.data.data.districts);
          } else {
            setDistrictList([]);
          }
        });
    }
  };
  const handleDistrictChange = (district) => {
    if (selectedDistrict?.value !== undefined) {
      setSelectedTown({ label: "", value: "" });
      setValue("zip", "");
      setUpazillaList([]);

      setSelectedDistrict(district);
      setValue("district", district);
      const selectedDistrictId = district.value;
      const foundAreas = districtList.filter(
        ({ id }) => id == selectedDistrictId
      )[0]?.areas;
      // setUpazillaList(foundAreas)
      if (foundAreas !== undefined) {
        setUpazillaList(foundAreas);
      } else {
        setUpazillaList([]);
      }
    }
    // if (watchFields[2].label !== )
  };
  const handleTownChange = (town) => {
    if (selectedTown?.value !== undefined) {
      setValue("zip", "");
      setSelectedTown(town);
      setValue("town", town);
    }
  };
  // Address form methods end

  const [scanedCode, setScanCode] = useState("");
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [inputQuery, setInputQuery] = useState("");
  const mainEmail = "kaarujbangladesh@gmail.com";
  const mainName = "KAARUJ";
  const mainAddress = userProfile?.outlet_location
    ? userProfile?.outlet_location
    : "Road: 42, Gulshan 2, Dhaka, Bangladesh";

  const mainMobile = "+8801980907892";
  const [isLoading, SetisLoading] = useState(false);
  const [defaultValue, setdefaultValue] = useState([]);
  const [metaSelect, setMetaSelect] = useState({
    action: "select-option",
    name: "",
    option: {},
  });

  const print = useRef();
  const [selectedOption, setSelectedOption] = useState(null);
  const [Thumb, setThumb] = useState(null);
  const delivery_charge = {
    0: 80,
    1: 150,
  };
  const deliveryType = [
    { value: 0, label: "Regular" },
    { value: 1, label: "Urgent" },
  ];
  const invoice_choices = [
    { value: 0, label: "Recieved" },
    // { value: 1, label: "Exchange" },
    // { value: 2, label: "Refunded" },
    { value: 3, label: "Exchange" },
  ];

  const delivery_choices = [
    // { value: 0, label: "Returned" },
    // { value: 1, label: "Order Placed" },
    { value: 2, label: "Delivered" },
    // { value: 3, label: "Pending" },
    { value: 4, label: "Hold" },
    // { value: 5, label: "Dispatched" },
  ];
  const paymentType = [
    { value: 0, label: "Cash" },
    { value: 1, label: "Card" },
    // { value: 2, label: "Bank Transfer" },
    { value: 3, label: "Bkash" },
    // { value: 4, label: "SSLECOMMERZ" },
    { value: 5, label: "Nagad" },
    // { value: 6, label: "Kaaruj Delivery" },
    { value: 7, label: "Rocket" },
    { value: 8, label: "Mixed" },
  ];
  const paymentTypeForMix = [
    { value: 0, label: "Cash", amount: 0 },
    { value: 1, label: "Card", amount: 0 },
    { value: 3, label: "Bkash", amount: 0 },
    { value: 5, label: "Nagad", amount: 0 },
    { value: 7, label: "Rocket", amount: 0 },
  ];
  const handleInputChange = (id, newValue) => {
    // Create a new array with the updated amount for the payment with the specified id.
    const updatedPayments = mixPaymentData?.map((payment) =>
      payment.value === id ? { ...payment, amount: newValue } : payment
    );

    setMixPaymentData(updatedPayments);
  };
  const paymentType1 = [
    { value: 0, label: "Cash On Delivery" },
    { value: 1, label: "Card" },
    { value: 2, label: "Bank Transfer" },
    { value: 3, label: "Bkash" },
    { value: 4, label: "SSLECOMMERZ" },
    { value: 5, label: "Nagad" },
    { value: 5, label: "Kaaruj Delivery" },
  ];
  const [pS, setPs] = useState(0);
  const [subTotal, setSubtotal] = useState(0);

  const [fromCode, setFromCode] = useState("880");
  const [toCode, setToCode] = useState("880");
  const [customCharge, setCustomCharge] = useState(0);
  console.log("crg", customCharge);
  const today = new Date();

  // Format the date as "YYYY-MM-DD"
  const formattedDate = today.toISOString().split("T")[0];

  const [InvoiceAllData, SetInvoiceAllData] = useState({
    invoice_date: formattedDate,
    invoice_due_date: "",
    invoice_bill_from: mainName,
    invoice_bill_to: "",
    invoice_from_mobile: mainMobile.replace("+880", ""),
    invoice_to_mobile: "",
    invoice_from_email: mainEmail,
    invoice_to_email: "",
    invoice_from_address: mainAddress,
    invoice_to_address: "",
    invoice_to_address2: "",
    to_zip_code: "",
    to_country: "Bangladesh",
    to_district: "",
    to_division: "",
    to_city: "",
    invoice_notes: "",
    invoice_delivery_charge: 0,
    recived_amount: 0,
  });

  const [paymentAllData, setPaymentAllData] = useState({
    card_holder_name: "",
    card_number: "",
    bank_name: "",
    banK_account_number: "",
    banK_account_name: "",
    banK_branch_name: "",
    bkash_number: "",
    bkash_trx_number: "",
    nagad_number: "",
    nagad_trx_number: "",
    reference_id: "",
  });
  const clearPaymentALlData = () => {
    setPaymentAllData({
      card_holder_name: "",
      card_number: "",
      bank_name: "",
      banK_account_number: "",
      banK_account_name: "",
      banK_branch_name: "",
      bkash_number: "",
      bkash_trx_number: "",
      nagad_number: "",
      nagad_trx_number: "",
      reference_id: "",
    });
  };
  const [invoice_status, setinvoice_status] = useState(0);
  const [Delivery_status, setDelivey_status] = useState(2);
  const [invoice_delivery_type, setinvoice_delivery_type] = useState(0);
  const [invoice_discount_type, setinvoice_discount_type] = useState(0);
  const [invoice_payment_type, setinvoice_payment_type] = useState(0);
  const [invoice_delivery_charge, setinvoice_delivery_charge] = useState(2);
  const [InvoiceBelowData, SetInvoiceBelowData] = useState({
    invoice_vat: 0,
    invoice_tax: 0,
    invoice_due: 0,
    invoice_paid: 0,
    invoice_total: 0,
  });
  console.log(invoice_status, "ins");
  console.log(Delivery_status, "ins");
  // console.log("------------------------------");
  // console.log("invoice_payment_type", invoice_payment_type);
  // console.log("------------------------------");

  const [optional, setOptional] = useState("");
  const [URL, setURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [Items, SetItems] = useState([]);
  const [Signature, SetSignature] = useState(null);
  const [ProductsList, setProductsList] = useState([]);
  const [CustomerList, setCustomerList] = useState([]);
  const [ProductCom, setProductCom] = useState([]);
  const [mainState, setMainState] = useState({});
  const [mainInnerState, setMainInnerState] = useState({});
  // const [FoundCustomer, setFoundCustomer] = useState({
  //   id: "",
  //   total_purchase: "",
  //   created_at: "",
  //   updated_at: "",
  //   name: "",
  //   mobile: "",
  //   email: "",
  //   status: "",
  //   address: "",
  // });
  const [Jdata, setJData] = useState([]);
  useEffect(() => {
    let temp1 = mainState;
    console.log(temp1, "--------mainState found temp1------");
    var output = [];
    for (let i in temp1) {
      console.log(temp1[i]["variantObj"]);
      for (let j in temp1[i]["variantObj"]) {
        console.log(j);
        console.log(temp1[i]["variantObj"][j]);
        const d = temp1[i]["variantObj"][j];
        d.total !== 0 &&
          output.push({
            name: i,
            basePrice: temp1[i].price,
            thumb: temp1[i].thumb ? temp1[i].thumb : temp1[i].thumb_url,
            discount: isNaN(temp1[i].discount) === true ? 0 : temp1[i].discount,
            price: d["price"],
            quantity: d["quantity"],
            id: d["id"],
            total: d["total"],
            variant: d["name"],
          });
      }
    }
    setJData(output);
  }, [mainState]);
  const fileHandle = (e) => {
    SetSignature(e.target.files[0]);

    setFileName(e.target.files[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    setURL(reader.result);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setURL(reader.result);
      }
    };
  };

  // console.log("FoundCustomer", FoundCustomer);
  const handleOptional = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    setOptional(reader.result);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setOptional(reader.result);
      }
    };
  };
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
    }),
  };

  const convertData = (date) => {
    var current = new Date(date);
    return current.toISOString().substring(0, 10);
  };

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const clearData = () => {
    SetInvoiceAllData({
      invoice_date: "",
      invoice_due_date: "",
      invoice_bill_from: "",
      invoice_bill_to: "",
      invoice_from_mobile: "",
      invoice_to_mobile: "",
      invoice_from_address: "",
      invoice_to_address: "",
      invoice_to_address2: "",
      to_zip_code: "",
      to_city: "",
      to_country: "",
      to_district: "",
      to_division: "",
      invoice_delivery_charge: "",
      invoice_notes: "",
      invoice_notes2: "",
    });
  };
  const validatePhone = (phoneNo) => {
    const bd = /^[0][1][0-9]{9}$/;
    const format = {
      bd: bd,
    };

    if (phoneNo.length <= 0) {
      showToast("error", "Phone Number Can't be empty.");
      return 0;
    } else if (phoneNo.length < 10) {
      showToast("error", "Please,Enter full phone number...");
      return 0;
    } else if (/[a-z]/i.test(phoneNo)) {
      showToast("error", "Invalid Phone Number");
      return 0;
    }
  };
  const history = useHistory();
  const ValidateData = (sendEmail) => {
    const bd = /^[1][0-9]{9}$/;

    if (InvoiceAllData["invoice_date"] === "") {
      showToast("error", "Invoice  Date can't be empty..");
      return 0;
    } else if (!bd.test(InvoiceAllData["invoice_from_mobile"])) {
      showToast("error", "From Mobile : Please Enter Valid phone number");
      return 0;
    } else if (!bd.test(InvoiceAllData["invoice_to_mobile"])) {
      showToast("error", "To Mobile : Please Enter Valid phone number");
      return 0;
    } else if (!Found && InvoiceAllData["invoice_bill_from"] === "") {
      showToast("error", "Bill from  can't be empty..");
      return 0;
    }
    // else if (!Found && InvoiceAllData["invoice_bill_to"] === "") {
    //   showToast("error", "Bill to  can't be empty..");
    //   return 0;
    // }

    if (InvoiceAllData["invoice_from_email"] === "") {
      showToast("error", "From email  can't be empty..");
      return 0;
    } else if (!validateEmail(InvoiceAllData["invoice_from_email"])) {
      showToast("error", "From email  not valid..");
      return 0;
    }

    // else if (
    //   Found &&
    //   !validateEmail(FoundCustomer?.email) === "" &&
    //   InvoiceAllData["invoice_to_email"].length > 0
    // ) {
    //   showToast("error", "To email  not valid...");
    //   return 0;
    // } else if (
    //   !Found &&
    //   !validateEmail(InvoiceAllData["invoice_to_email"]) &&
    //   InvoiceAllData["invoice_to_email"].length > 0
    // ) {
    //   showToast("error", "To email  not valid..");
    //   return 0;
    // }
    else if (InvoiceAllData["invoice_from_address"] === "") {
      showToast("error", "From address  can't be empty..");
      return 0;
    }

    // else if (!Found && InvoiceAllData["invoice_to_address"] === "") {
    //   showToast("error", "To address  can't be empty..");
    //   return 0;
    // }

    if (Object.keys(mainState).length <= 0) {
      showToast("error", "Product Details is empty..");
      return 0;
    }
    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }

    const allvariants = Object.values(mainState).map(
      (current) => current["variantObj"]
    );

    if (
      invoice_payment_type === 8 ||
      (invoice_payment_type === "8" && mixPaymentData?.length === 0)
    ) {
      showToast("error", "Please add mix payment");
      return 0;
    }
    if (InvoiceAllData["invoice_notes"] === null) {
      showToast("error", "Notes is empty..");
      return 0;
    }
    // if (!watchFields[0]?.label && !FoundCustomer.to_division) {
    //   showToast("error", "Division is empty..");
    //   return 0;
    // }

    // if (!FoundCustomer.to_district && !watchFields[1]?.label) {
    //   showToast("error", "District code is empty..");
    //   return 0;
    // }
    // if (!FoundCustomer.to_city && !watchFields[2]?.label) {
    //   showToast("error", "City is empty..");
    //   return 0;
    // }
    // if (!watchFields[3]?.label && !FoundCustomer.to_zip_code) {
    //   showToast("error", "Zip code is empty..");
    //   return 0;
    // }

    postInvoice(sendEmail);
  };

  console.log("invoice_payment_type", invoice_payment_type);
  // };
  const [isSendingInvoiceProudct, setisSendingInvoiceProudct] = useState(false);
  const allvariants = Object.values(mainState).map(
    (current) => current["variantObj"]
  );
  // console.log("allvariants", allvariants);

  function formatInv(inputData) {
    const formattedData = [];
    // Iterate over the data object
    for (const key in inputData) {
      const product = inputData[key];
      const variants = product.variant;

      // Iterate over the variants array for each product
      for (let i = 0; i < variants.length; i++) {
        const variant = variants[i];

        // Check if quantity is greater than 0
        if (variant.quantity > 0) {
          // Create a new formatted object using the variant data
          const formattedObject = {
            quantity: variant.quantity,
            total: variant.total.toString(),
            product_name: key.trim(),
            variant_name: variant.name,
            is_custom: false,
            invoice_date: InvoiceAllData["invoice_date"],
            product: product.id,
            variant: variant.id,
            is_outlet: true,
            outlet: userProfile?.outlet,
            // offer Details
            discount_type: variant.discount_type,
            discount_value: variant.discount_value,
            main_price: variant.main_price2,
            offer_price: variant.offer_price,
            offer_type: variant.offer_type,
          };

          // Add the formatted object to the formattedData array
          formattedData.push(formattedObject);
        }
      }
    }

    return formattedData;
  }

  console.log(formatInv(mainState), "invoice product");

  const SaveAndSendEmail = () => {
    ValidateData(true);
  };
  const handleClick = (category) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=212px, initial-scale=1.0">
          <title>${category.number}</title>
          <style>
            /* Add your CSS styles here */
            table, tr, td {
              border: none;
          }
          body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              width: 212px;
              font-weight: normal;
          }
  
          .container {
              width: 100%;
          }
  
          .header {
              text-align: center;
          }
  
          .address {
              margin: 5px 0;
              text-align: center;
          }
  
          .address p {
              margin: 3px;
              font-size: 10px;
          }
  
          .invoice-details {
              margin: 5px 0;
          }
  
          .invoice-details p {
              margin: 3px 0;
              font-size: 10px;
          }
  
          .invoice-table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 5px;
          }
  
          .invoice-table th,
          .invoice-table td {
              border: 1px solid #3327272b;
              padding: 3px;
              text-align: center;
              font-size: 10px;
          }
  
          .invoice-table th {
              background-color: #f2f2f2;
          }
  
          .total-section {
              margin-top: 5px;
          }
          .invoice-note ul li {
            margin: 8px 0;
            font-size: 10px;
        }
  
          .total-section p {
              margin: 3px 0;
              text-align: right;
              font-size: 10px;
          }
          .header {
              text-align: center;
              margin-top: 5px; 
          }
  
          .address {
              margin: 5px 0 0 0; 
              text-align: center;
          }
  
          @media print {
              .invoice-table {
                  page-break-inside: avoid;
              }
          }
          </style>
        </head>
        <body>
          <div class="">
            <div class="header" style="margin-bottom: 5px; font-weight:bolder;">
              <p>Bill Receipt</p>
            </div>
  
            <div class="address">
              <div class="address" style="margin-bottom: 0px;font-weight:bolder;">
                <p4>${category.bill_from}</p4>
              </div>
              <p>${category.from_address}</p>
              <p>Mobile: ${category.from_mobile}</p>
              <p> Email: ${category.from_email}</p>
              <p>BIN No: 004187577-0201</p>
              <p>VAT: Mushak 6.3</p>
              <p>Date: ${category.invoice_date}</p>
            </div>
  
            <div class="invoice-details">
              <p>Invoice No: ${category.number}</p>
              <p>Name: ${category.bill_to}</p>
              <p>Address: ${
                category.to_address ? category.to_address : "Dhaka"
              }</p>
              <p>Phone: ${category.to_mobile} </p>
              ${category.to_email && `<p>Email: ${category.to_email}</p>`}
            
            </div>
  
            <table class="invoice-table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                ${category.invoice_products
                  .map(
                    (item) => `
                      <tr>
                        <td><p>${item.product_name}(${
                      item.variant_name
                    })</p></td>
                        <td><p>${
                          Number(item.total) / Number(item?.quantity)
                        }</p></td>
                        <td><p>${item.quantity}</p></td>
                        <td><p>${item.total}</p></td>
                      </tr>
                    `
                  )
                  .join("")}
              </tbody>
            </table>
  
            <div class="total-section">
              <p>Total Item: ${category.invoice_products.length}</p>
              <p>Total Amount: ${category.total_amount}</p>
              <p>Redeem Discount: ${
                category?.redeem_amount ? category?.redeem_amount : 0
              }</p>
        
              <p>Discount: ${category?.discount_type === 0 ? "৳" : ""}
              ${category.total_discount}
              ${category?.discount_type === 1 ? "%" : ""} </p>
              <p>Paid Amount: ${category.total_paid}</p>
              <p>Due Amount: ${category.total_due}</p>
            </div>
          </div>

          <div class='invoice-note'>
          <ul>
          <li>Product is non-refundable if there is no major manufacturing fault. Refund process will be completed within 10 business days after receiving faulty products, checked and accepted by the seller.</li>
          <li>Exchange only allowed within 7 days with the same or higher price product.</li>
          <li>Product will be delivered within Bangladesh upon receipt of full payment.</li>
        </ul>
          <ul>
          <li>Desgin and develop by Devsstream Limited.</li>
      
        </ul>
    </div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
    history.push(path.invoice_list);
  };
  const postInvoice = (sendEmail) => {
    const url = `${BASE_URL}api/v1/sales/inventory/invoice/`;
    const data = new FormData();

    const current_user = JSON.parse(localStorage.getItem("userData"));
    data.append(
      "invoice_date",
      InvoiceAllData["invoice_date"] !== "" &&
        convertData(InvoiceAllData["invoice_date"])
    );

    data.append("bill_from", InvoiceAllData["invoice_bill_from"]);
    data.append(
      "bill_to",
      Found ? FoundCustomer.name : InvoiceAllData["invoice_bill_to"]
    );
    data.append("from_email", InvoiceAllData["invoice_from_email"]);
    data.append(
      "to_email",
      Found ? FoundCustomer.email : InvoiceAllData["invoice_to_email"]
    );
    data.append(
      "from_mobile",
      `+${fromCode}${InvoiceAllData["invoice_from_mobile"]}`
    );
    data.append(
      "to_mobile",
      `+${toCode}${InvoiceAllData["invoice_to_mobile"]}`
    );
    data.append("from_address", InvoiceAllData["invoice_from_address"]);
    data.append(
      "to_address",
      Found ? FoundCustomer.address : InvoiceAllData["invoice_to_address"]
    );
    data.append(
      "delivery_charge",
      parseInt(invoice_delivery_charge) === 2
        ? customCharge
        : delivery_charge[InvoiceAllData["invoice_delivery_charge"]]
    );
    data.append("delivery_charge_type", parseInt(invoice_delivery_charge));

    data.append("total_discount", Discount);
    data.append("send_pdf", sendEmail);

    data.append("discount_type", invoice_discount_type);
    data.append(
      "product_list_json",
      JSON.stringify({
        mainstate: mainState,
        maininnerstate: mainInnerState,
      })
    );

    data.append("invoice_view_json", JSON.stringify(Jdata));

    data.append("payment_status", invoice_status.value);
    data.append("delivery_status", Delivery_status.value);
    data.append("delivery_type", invoice_delivery_type);
    data.append("payment_type", invoice_payment_type);
    data.append("payment_type", invoice_payment_type);
    data.append("card_holder_name", paymentAllData["card_holder_name"]);
    data.append("card_number", paymentAllData["card_number"]);
    data.append("card_expiry_date", paymentAllData["card_expiry_date"]);
    data.append("bank_name", paymentAllData["bank_name"]);
    data.append("banK_account_number", paymentAllData["banK_account_number"]);
    data.append("banK_routing_number", paymentAllData["banK_routing_number"]);
    data.append("banK_account_name", paymentAllData["banK_account_name"]);
    data.append("banK_branch_name", paymentAllData["banK_branch_name"]);
    data.append("bkash_number", paymentAllData["bkash_number"]);
    data.append("bkash_trx_number", paymentAllData["bkash_trx_number"]);
    // data.append("payment_status", 0);
    data.append("signature", Signature);
    data.append("notes", InvoiceAllData["invoice_notes"]);
    data.append("created_by", current_user.id);
    data.append("total_tax", 0);
    data.append("total_due", parseInt(getDueAmount()));
    data.append("total_paid", paid);
    data.append("total_amount", parseInt(getGrandTotal()));
    data.append("total_vat", 0);
    data.append("invoice_products", JSON.stringify(formatInv(mainState)));

    SetisLoading(true);
    const data2 = {
      invoice_date:
        InvoiceAllData["invoice_date"] !== "" &&
        convertData(InvoiceAllData["invoice_date"]),
      bill_from: InvoiceAllData["invoice_bill_from"],
      bill_to: Found
        ? FoundCustomer.name
        : InvoiceAllData["invoice_bill_to"]
        ? InvoiceAllData["invoice_bill_to"]
        : "",
      from_email: InvoiceAllData["invoice_from_email"],
      to_email: Found
        ? FoundCustomer.email
        : InvoiceAllData["invoice_to_email"],
      from_mobile: `+${fromCode}${InvoiceAllData["invoice_from_mobile"]}`,
      to_mobile: `+${toCode}${InvoiceAllData["invoice_to_mobile"]}`,
      from_address: InvoiceAllData["invoice_from_address"],
      to_address: Found
        ? FoundCustomer.to_address
        : InvoiceAllData["invoice_to_address"]
        ? InvoiceAllData["invoice_to_address"]
        : "",
      delivery_charge:
        parseInt(invoice_delivery_charge) === 2
          ? customCharge
          : delivery_charge[InvoiceAllData["invoice_delivery_charge"]],
      delivery_charge_type: parseInt(invoice_delivery_charge),
      total_discount: Discount,
      send_pdf: sendEmail,
      discount_type: invoice_discount_type,
      product_list_json: JSON.stringify({
        mainstate: mainState,
        maininnerstate: mainInnerState,
      }),
      invoice_view_json: JSON.stringify(Jdata),
      payment_status: invoice_status,
      delivery_status: Delivery_status,
      delivery_type: invoice_delivery_type,
      payment_type: invoice_payment_type,
      card_holder_name: paymentAllData["card_holder_name"],
      card_number: paymentAllData["card_number"],
      card_expiry_date: paymentAllData["card_expiry_date"],
      bank_name: paymentAllData["bank_name"],
      banK_account_number: paymentAllData["banK_account_number"],
      banK_routing_number: paymentAllData["banK_routing_number"],
      banK_account_name: paymentAllData["banK_account_name"],
      banK_branch_name: paymentAllData["banK_branch_name"],
      bkash_number: paymentAllData["bkash_number"],
      bkash_trx_number: paymentAllData["bkash_trx_number"],
      nagad_number: paymentAllData["nagad_number"],
      nagad_trx_number: paymentAllData["nagad_trx_number"],
      reference_id: paymentAllData["reference_id"],
      signature: Signature,
      notes: InvoiceAllData["invoice_notes"],
      notes2: InvoiceAllData["invoice_notes2"],
      created_by: current_user.id,
      total_tax: 0,
      total_due: parseInt(getDueAmount()),
      total_paid: getGrandTotal(),
      total_amount: parseInt(getGrandTotal()),
      total_cash_recieved: InvoiceAllData["recived_amount"],
      change_amount:
        InvoiceAllData["recived_amount"] > getGrandTotal()
          ? Math.abs(
              isNaN(getGrandTotal())
                ? 0
                : getGrandTotal() - InvoiceAllData["recived_amount"]
            )
          : 0,
      sub_total: isNaN(getSubTotal()) ? 0 : getSubTotal(),
      total_vat: 0,
      invoice_products: formatInv(mainState),
      to_address2: Found
        ? FoundCustomer.to_address2
        : InvoiceAllData["invoice_to_address2"]
        ? InvoiceAllData["invoice_to_address2"]
        : "",
      to_country: Found
        ? FoundCustomer.to_country
        : InvoiceAllData["to_country"],
      to_district: Found
        ? FoundCustomer.to_district
        : watchFields[1]?.label
        ? watchFields[1]?.label
        : "",
      redex_division: watchFields[0]?.value,
      redex_district: watchFields[1]?.value,
      redex_area: watchFields[2]?.value,
      to_division: Found
        ? FoundCustomer.to_division
        : watchFields[0]?.label
        ? watchFields[0]?.label
        : "",
      to_zip_code: Found ? FoundCustomer.to_zip_code : watchFields[3],
      to_city: Found ? FoundCustomer.to_city : watchFields[2]?.label,
      is_outlet: true,
      outlet: userProfile?.outlet,
      mixed_payment_details: mixPaymentData,
      source: 2,
      // redeem_details:
      redeem_amount: redeem,
      redeem_point: redeem,
      voucher: voucher?.data?.voucher_details
        ? voucher?.data?.voucher_details.id
        : null,
    };
    setInvoiceLoader(true);
    axios
      .post(url, data2)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data.data);
          // postInvoiceProduct(res.data.data.id);
          // clearData();
          SetisLoading(false);
          setInvoiceLoader(false);
          showToast("success", "Invoice Created.");
          // history.push(path.invoice_list);
          handleClick(res.data.data);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        // console.log(errorMsg);
        // for (let value of Object.values(errorMsg)) {
        //   showToast("error", value[0]);
        // }

        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
        SetisLoading(false);
        setInvoiceLoader(false);
      });
  };
  const getProductList2 = () => {
    console.log(scanedCode, "code");
    let url;
    if (scanedCode) {
      url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/${userProfile?.outlet}/outlet/?query=${scanedCode}`;
    }

    axios
      .get(url)
      .then((res) => {
        // console.log("productlistinvoice", res.data);
        const result = res.data.data;

        const options = result.map((curr) => ({
          label: curr.name + " " + "[[" + curr?.sku + "]]",
          value: curr.id,
          price: curr.price,
          discount: curr.discount,
          stock: curr.stock,
          variant: curr.variants,
          thumb: `${curr.thumb}`,
        }));

        setProductsList(options);
        // setLoading(false);
        setScanCode("");
        if (options.length > 0) {
          let variants = options[0]?.variant.map((curr) => ({
            id: curr.id,
            name: curr.name,
            price: curr.price,
            stock: curr.stock,
            product: curr.product,
            quantity: -1,
            total: 0,
          }));
          const variantsObj = {};
          options[0]?.variant.map(
            (curr) =>
              (variantsObj[curr.name] = {
                id: curr.id,
                name: curr.name,
                price: curr.price,
                stock: curr.stock,
                product: curr.product,
                quantity: -1,
                total: 0,
              })
          );

          setMetaSelect({
            action: "select-option",
            name: undefined,

            option: {
              id: options[0]?.value,
              variant: variants,
              label: options[0]?.label,
              variantObj: variantsObj,
              price: parseInt(options[0]?.price),
              stock: parseInt(options[0]?.stock),
              thumb: options[0]?.thumb,
              discount: parseInt(options[0]?.discount),
            },
          });
          console.log("metaSlect2", metaSelect);
          setSelectedOption({});
          // handleMultiInputChange(options[0], metaSelect);
        }
      })
      .catch((err) => {
        // const message = JSON.parse(err.request.response).message;
        // console.log(err.request.response);
      });
  };
  useEffect(() => {
    if (metaSelect?.option?.variant) {
      const newValue = {
        value: ProductsList[0].value,
        label: ProductsList[0].label,
      };
      // Check if the new value already exists in `defaultValue`
      const isValueExists = defaultValue.some(
        (item) => item.value === newValue.value
      );
      if (!isValueExists) {
        handleMultiInputChange(ProductsList[0], metaSelect);
        setdefaultValue([...defaultValue, newValue]);
      }
    }
  }, [metaSelect]);

  const getProductList = () => {
    if (inputQuery?.length > 1) {
      let url;
      if (!inputQuery || inputQuery === "" || inputQuery === undefined) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/${userProfile?.outlet}/outlet`;
      } else if (inputQuery) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/${userProfile?.outlet}/outlet/?query=${inputQuery}`;
      }

      axios
        .get(url)
        .then((res) => {
          // console.log("productlistinvoice", res.data);
          const result = res.data.data;
          console.log(result, "------productlistinvoice-----");
          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            price: curr.price,
            discount: curr.discount,
            stock: curr.stock,
            variant: curr.variants,
            thumb: `${curr.thumb_url}`,
            compared_offer_details: curr.compared_offer_details,
          }));

          setProductsList(options);
          // SetisLoading(false);
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };
  // console.log("ProductsList", ProductsList);

  const getCustomerList = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/customer_list_for_invoice/`;

    axios
      .get(url)
      .then((res) => {
        // console.log('res',res.data.data)
        const result = res.data.data;
        setCustomerList(result);
        // SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const inputProduct = (inputValue) => {
    setInputQuery(inputValue);
    console.log("searched", inputQuery);
  };
  // const handleMultiInputChange = (newValue, actionMeta) => {
  //   const newVal = actionMeta?.option?.label?.split("[[")[0];
  //   setProductCom(newValue);

  //   if (actionMeta.action === "select-option") {
  //     let variants = actionMeta.option.variant.map((curr) => ({
  //       id: curr.id,
  //       name: curr.name,
  //       price: curr.price,
  //       stock: curr.stock,
  //       product: curr.product,
  //       quantity: -1,
  //       total: 0,
  //     }));
  //     const variantsObj = {};
  //     actionMeta.option.variant.map(
  //       (curr) =>
  //         (variantsObj[curr.name] = {
  //           id: curr.id,
  //           name: curr.name,
  //           price: curr.price,
  //           stock: curr.stock,
  //           product: curr.product,
  //           quantity: -1,
  //           total: 0,
  //         })
  //     );

  //     setMainState({
  //       ...mainState,
  //       [newVal]: {
  //         id: actionMeta.option.value,
  //         variant: variants,
  //         variantObj: variantsObj,
  //         price: parseInt(actionMeta.option.price),
  //         stock: parseInt(actionMeta.option.stock),
  //         thumb: actionMeta.option.thumb,
  //         discount: parseInt(actionMeta.option.discount),
  //       },
  //     });
  //   }
  //   if (actionMeta.action === "remove-value") {
  //     delete mainState[actionMeta.removedValue.label?.split("[[")[0]];
  //     setMainState({ ...mainState });
  //   }
  // };
  console.log(mixPaymentData, "mxptda");

  const handleMultiInputChange = (newValue, actionMeta) => {
    // const newVal = actionMeta?.option?.label?.split("[[")[0];
    const newVal = actionMeta?.option?.label;
    setProductCom(newValue);

    if (actionMeta.action === "select-option" && actionMeta?.option?.variant) {
      let variants = actionMeta?.option?.variant.map((curr) => ({
        id: curr.id,
        name: curr.name,
        price: curr.price,
        main_price: curr.main_price,
        stock: curr.stock,
        product: curr.product,
        quantity: -1,
        total: 0,
        // offer Details
        discount_type:
          actionMeta?.option?.compared_offer_details?.discount_type,
        discount_value:
          actionMeta?.option?.compared_offer_details?.discount_value,
        main_price2: actionMeta?.option?.compared_offer_details?.main_price,
        offer_price: actionMeta?.option?.compared_offer_details?.offer_price,
        offer_type: actionMeta?.option?.compared_offer_details?.offer_type,
      }));
      const variantsObj = {};
      actionMeta.option?.variant.map(
        (curr) =>
          (variantsObj[curr.name] = {
            id: curr.id,
            name: curr.name,
            price: curr.price,
            main_price: curr.main_price,
            stock: curr.stock,
            product: curr.product,
            quantity: -1,
            total: 0,
            // offer Details
            discount_type:
              actionMeta?.option?.compared_offer_details?.discount_type,
            discount_value:
              actionMeta?.option?.compared_offer_details?.discount_value,
            main_price2: actionMeta?.option?.compared_offer_details?.main_price,
            offer_price:
              actionMeta?.option?.compared_offer_details?.offer_price,
            offer_type: actionMeta?.option?.compared_offer_details?.offer_type,
          })
      );

      // setMainState({
      //   ...mainState,
      //   [newVal]: {
      //     id: actionMeta.option.value,
      //     variant: variants,
      //     variantObj: variantsObj,
      //     price: parseInt(actionMeta.option.price),
      //     stock: parseInt(actionMeta.option.stock),
      //     thumb: actionMeta.option.thumb,
      //     discount: parseInt(actionMeta.option.discount),
      //   },
      // });
      setMainState((prevMainState) => ({
        [newVal]: {
          id: actionMeta.option.value,
          variant: variants,
          variantObj: variantsObj,
          price: parseInt(actionMeta.option.price),
          product_main_price: parseInt(actionMeta.option.product_main_price),
          stock: parseInt(actionMeta.option.stock),
          thumb: actionMeta.option.thumb,
          discount: parseInt(actionMeta.option.discount),
          compared_offer_details: actionMeta.option.compared_offer_details,
        },
        ...prevMainState,
      }));

      setdefaultValue([
        ...defaultValue,
        {
          value: actionMeta.option.value,
          label: newVal,
        },
      ]);
    }
    //remove data from select
    if (actionMeta.action === "remove-value") {
      delete mainState[actionMeta.removedValue.label];
      setMainState({ ...mainState });
      setdefaultValue((prevDefaultValue) =>
        prevDefaultValue.filter(
          (value) =>
            value.label !== actionMeta.removedValue.label &&
            value.value !== actionMeta.removedValue.value
        )
      );
      setScanCode("");
    }
  };
  console.log(mainState, "mainState123");

  const handleInnerVariant = (currentName, value) => {
    // console.log("currentName", currentName);
    // console.log("value", value);
    setMainInnerState({
      ...mainInnerState,
      [currentName]: value,
    });
  };

  // console.log("mainInnerState", mainInnerState);

  useEffect(() => {
    getProductList2();
    // setTimeout(() => {
  }, [scanedCode]);
  // useEffect(() => {
  //   getProductList();
  // }, [inputQuery]);

  useEffect(() => {
    getCustomerList();
  }, []);
  console.log("mainState 1457", mainState);

  const filterdState = filterMainState(mainState);
  console.log(filterdState, "1460");

  useEffect(() => {
    Object.keys(ProductsList).map((curr, index) => {
      setMainState({
        ...mainState,
        [curr]: { ...mainState[curr], in_price: ProductsList[index]["price"] },
      });
    });
  }, []);

  console.log("CustomerList", CustomerList);
  const toPhoneHandle = (e) => {
    let value = `+${toCode}${e.target.value}`;
    let number = e.target.value;
    handleInputs(e, SetInvoiceAllData);

    // for (let item in CustomerList) {
    //   console.log("item", item);
    //   console.log("item", CustomerList[item]);
    //   if (CustomerList[item]["mobile"] === value) {
    //     setFound(true);
    //     setFoundCustomer(CustomerList[item]);
    //     showToast("success", "Found Customer");
    //     break;
    //   } else if (CustomerList[item]["mobile"] !== value) {
    //     setFound(false);
    //   }
    // }
    if (number.length === 10) {
      const url = `${BASE_URL}/api/v1/inventory/inventory/customer/?limit=20&offset=0&filter=&search=+880${number}
      `;
      axios.get(url).then((res) => {
        if (res.data.data.results.length !== 0) {
          setFound(true);
          setFoundCustomer(res.data.data.results[0]);
          setMembershipInfo(res.data.data.results[0]?.membership_info);
          showToast("success", "Found Customer");
        } else {
          setFound(false);
        }
      });
    }
  };

  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const VariantThead = () => {
    return (
      <>
        {Object.keys(mainInnerState).length > 0 && (
          <thead style={{ border: "1.5px solid #E0E0E0" }}>
            <tr className="px-3" style={{ height: "45px" }}>
              <th
                className="minWidthForInvoiceCreateTable ps-4"
                style={{ width: "349px", textAlign: "start" }}
              >
                Variant
              </th>
              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Price
              </th>

              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                In Stock
              </th>
              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Quantity
              </th>

              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Total
              </th>
            </tr>
          </thead>
        )}
      </>
    );
  };
  const [paid, setpaid] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const getAllVariantName = () => {
    var res =
      mainInnerState !== undefined &&
      [].concat(
        ...Object.values(mainInnerState).map((a) => a.map((b) => b.label))
      );
    return res;
  };
  // console.log("getAllVariantName", getAllVariantName());

  const getSubTotal = () => {
    const allarray = Object.values(mainState).map(
      (curr) =>
        curr.variant !== undefined &&
        curr.variant
          .map((variants) =>
            getAllVariantName().includes(variants.name)
              ? parseInt(variants.total)
              : 0
          )
          .reduce((a, b) => a + b, 0)
    );

    var sum = 0;
    for (let i in allarray) {
      sum += parseInt(allarray[i]);
    }
    return sum;
  };
  const getDiscountOrMainPrice = (curr, innerCur) => {
    return parseInt(
      mainState[curr] !== undefined &&
        (mainState[curr].discount === 0
          ? mainState[curr].price
          : mainState[curr].discount)
    );
  };
  const getBasePlusVariant = (curr, innerCur) => {
    console.log(
      {
        curr: curr,
        innerCur: innerCur,
        vouch: voucher,
      },
      "1547"
    );

    return getDiscountOrMainPrice(curr, innerCur) + parseInt(innerCur.price);
  };
  const getFlatDiscount = () => {
    return Discount;
  };

  const getDeliveryCharge = () => {
    if (invoice_delivery_charge === 2) {
      return customCharge;
    } else {
      return delivery_charge[invoice_delivery_charge];
    }
  };
  const getPercentageDiscount = () => {
    let total = getSubTotal();
    return parseInt((Discount / 100) * total);
  };

  const getGrandTotal = () => {
    const discountCalc =
      invoice_discount_type === 0 || invoice_discount_type === "0"
        ? getFlatDiscount()
        : getPercentageDiscount();
    const charge =
      parseInt(invoice_delivery_charge) !== 2
        ? parseInt(getDeliveryCharge())
        : parseInt(customCharge);
    const grand = getSubTotal() + charge - discountCalc;
    return grand < 0 ? 0 : parseInt(grand - redeem);
  };

  const getDueAmount = () => {
    const due = getGrandTotal() - getGrandTotal();
    return due < 0 ? 0 : due;
  };

  const handlePrint = useReactToPrint({
    content: () => print.current,
    documentTitle: "emp-data",
    bodyClass: "dis",
  });
  const handlePrint2 = () => {
    if (
      InvoiceAllData["invoice_from_email"] !== "" ||
      InvoiceAllData["invoice_to_email"] ||
      InvoiceAllData["invoice_bill_to"] ||
      InvoiceAllData["invoice_bill_form"]
    ) {
      handlePrint();
    } else {
      swal("Empty Data,Please fill the form");
    }
  };

  // console.log(Found, "--------found------");
  // console.log(InvoiceAllData, "--------InvoiceAllData found------");
  console.log(FoundCustomer, "fc");
  useEffect(() => {
    const updatedProducts = { ...mainState };
    // Iterate over each product
    Object.keys(updatedProducts).forEach((productKey) => {
      const product = updatedProducts[productKey];

      // Check if voucher is applied and update the price for each product
      if (!isObjectEmpty(voucher)) {
        // If voucher is not empty, apply main price
        product.price = product.product_main_price;

        // Update the total for each variant in variantObj and variant array
        Object.keys(product.variantObj).forEach((variantKey) => {
          const variant = product.variantObj[variantKey];

          // Calculate total using product_main_price and quantity if quantity > 0
          if (variant.quantity > 0) {
            variant.total = variant.quantity * product.product_main_price;
          } else {
            variant.total = 0; // Set total to 0 if quantity is not greater than 0
          }
        });

        product.variant.forEach((variant) => {
          // Calculate total using product_main_price and quantity if quantity > 0
          if (variant.quantity > 0) {
            variant.total = variant.quantity * product.product_main_price;
          } else {
            variant.total = 0; // Set total to 0 if quantity is not greater than 0
          }
        });
      }
    });

    // Update the state with the new prices and totals
    setMainState(updatedProducts);
  }, [voucher]);

  // 1730389898
  // 	RTC007

  const calculateDiscountedTotal = (
    productsData = {},
    idsToRemove = [],
    percentage = 0,
    maxDiscount = 0
  ) => {
    // Initialize sum of totals
    let totalSum = 0;

    // Iterate over each product
    // Iterate over each product
    Object.values(productsData).forEach((product) => {
      // Check if the product is not in the idsToRemove array
      if (!idsToRemove?.includes(product?.id)) {
        // Filter and sum totals from the variant array
        totalSum += product.variant.reduce((sum, variant) => {
          // Convert quantity and total to numbers
          const quantity = Number(variant.quantity);
          const total = Number(variant.total);

          // Only include variants with quantity not equal to -1
          if (quantity !== -1) {
            return sum + total;
          }
          return sum;
        }, 0);
      }
    });

    // Calculate the discount value
    const discountValue = (totalSum * percentage) / 100;

    // Return the minimum of the calculated discount or the maxDiscount
    return Math.min(discountValue, maxDiscount);
  };
  const findMatchingNames = (data, ids) => {
    const matchingNames = [];
    // Iterate over the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const product = data[key];
        if (ids.includes(product.id)) {
          // Extract the name from the key
          const nameMatch = key.match(/\[\[(.*?)\]\]/);
          if (nameMatch && nameMatch[1]) {
            matchingNames.push(nameMatch[1]);
          }
        }
      }
    }
    return matchingNames.join(", ");
  };
  useEffect(() => {
    if (!isObjectEmpty(voucher)) {
      const discount = calculateDiscountedTotal(
        mainState,
        voucher.data?.new_arrival_products,
        Number(voucher.data?.voucher_details?.discount_value),
        parseInt(voucher.data?.voucher_details?.max_discount)
      );
      // const skus = findMatchingNames(
      //   mainState,
      //   voucher.data?.new_arrival_products
      // );
      // if (skus !== "") {
      //   showToast(
      //     "success",
      //     `Voucher is valid, but it can't be used on these New Arrival items : (${skus})  `
      //   );
      // }

      showToast("success", `Voucher discount is ${Math.floor(discount)}TK`);
      setDiscount(Math.floor(discount));
    }
  }, [voucher]);
  if (!has_permissions(can_add_invoice)) {
    return <Permission />;
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="categorey-parent">
        <div className="bg-white cat-child ">
          <div
            className="d-flex align-items-center"
            style={{ padding: "20px 24px ", border: "0.5px solid #E0E0E0" }}
          >
            <h3
              style={{ fontSize: "23px", marginTop: "6px", marginLeft: "12px" }}
            >
              Customer Data
            </h3>
          </div>
          <div className="product-main">
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <RequiredLabel text="To Mobile" />
                <div className="d-flex phoneContainer ">
                  <PhoneInput
                    inputClass="phoneinput"
                    disableDropdown
                    onChange={(e) => setToCode(e)}
                    enableSearch={true}
                    country={"bd"}
                  />
                  <div className="w-100">
                    <Form.Control
                      aria-label="Input number"
                      placeholder="Input number"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      className="phone_insert_input"
                      name="invoice_to_mobile"
                      value={InvoiceAllData["invoice_to_mobile"]}
                      // onKeyUp={(e) => toPhoneHandle(e)}
                      onChange={(e) => toPhoneHandle(e)}
                    />
                  </div>
                </div>
                {InvoiceAllData["invoice_to_mobile"]?.length < 10 ? (
                  <>
                    <span style={{ fontSize: "12px", color: "red" }}>
                      Type 10 digit mobile number{" "}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12 col-md-6 mb-3 ">
                {Found && membershipInfo && membershipInfo?.card_number && (
                  <>
                    <div className="d-flex gap-3">
                      <p style={{ fontWeight: "bold" }}>
                        Name :{" "}
                        <span className="text-danger">
                          {FoundCustomer?.name}
                        </span>
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        Card Number:{" "}
                        <span className="text-danger">
                          {membershipInfo?.card_number}
                        </span>{" "}
                      </p>
                    </div>
                    <div className="d-flex gap-3">
                      <p style={{ fontWeight: "bold" }}>
                        Available Point :
                        <span className="text-danger">
                          {membershipInfo?.point}
                        </span>
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        Membership Type :{" "}
                        <span className="text-danger">
                          {memshipLabel[membershipInfo?.membership_type]}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p style={{ fontWeight: "bold" }}>
                        Discount :{" "}
                        <span className="text-danger">
                          {membershipInfo?.discount} %
                        </span>
                      </p>
                    </div>
                  </>
                )}
              </div>
              {Found && parseInt(membershipInfo?.point) >= 100 && (
                <RedeemPoint
                  state={redeem}
                  setState={setRedeem}
                  point={membershipInfo?.point}
                  number={membershipInfo?.mobile}
                  success={success}
                  setSuccess={setSuccess}
                />
              )}
              {!isObjectEmpty(mainState) && (
                <>
                  <VerifyVoucher
                    number={
                      membershipInfo?.mobile
                        ? membershipInfo?.mobile
                        : `+880${InvoiceAllData["invoice_to_mobile"]}`
                    }
                    success={success1}
                    setSuccess={setSuccess1}
                    productIds={mainState}
                    setVoucherData={setVoucher}
                  />

                  {voucher?.data?.voucher_details && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      Your voucher is all set! Enjoy a{" "}
                      {voucher?.data?.voucher_details?.discount_value}%
                      discount, with savings up to{" "}
                      {voucher?.data?.voucher_details?.max_discount}Taka. <br />
                      Note : Voucher is not applicable to new arrival products{" "}
                      {voucher?.data?.new_arrival_sku_text &&
                        voucher?.data?.new_arrival_sku_text}{" "}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {InvoiceAllData["invoice_to_mobile"]?.length < 10 ? (
          <></>
        ) : (
          <div
            className="invoice-b my-4 bg-white cat-child"
            style={{ borderTop: "0.5px solid #E0E0E0" }}
          >
            <h4 className=" my-2 mb-4 padding-main-head">Product Details</h4>
            <div className="border-p"></div>
            <div className="padding-main">
              <div className="main-select">
                {/* <Select
                // styles={styles}
                isMulti
                placeholder="---Select---"
                isClearable
                // defaultValue={selectedOption}
                onChange={handleMultiInputChange}
                onInputChange={inputProduct}
                options={ProductsList}
                value={defaultValue}
              /> */}
                <SelectOpenWithPaggination
                  handleMultiInputChange={handleMultiInputChange}
                  inputProduct={inputProduct}
                  ProductsList={ProductsList}
                  defaultValue={defaultValue}
                  setProductsList={setProductsList}
                  inputQuery={inputQuery}
                  mobile={membershipInfo ? membershipInfo?.mobile : ""}
                />
              </div>

              {/* </div> */}
              {/* </div> */}
              <div className="mt-3">
                <div
                  className="rounded"
                  style={{
                    background: "#FAFAFA",
                    display: "block",
                    overflowX: "hidden",
                    width: "100%",
                  }}
                >
                  <table className="item-tables table_my2">
                    <tbody>
                      {/* //Here-------------------------- */}
                      {Object.keys(mainState).map((curr, index) => {
                        // console.log("curr---------", curr);
                        // console.log("curr---------var", mainState[curr]["variant"]);
                        const variants = mainState[curr]["variant"];

                        return (
                          <>
                            {mainState[curr]["variant"]?.length > 0 ? (
                              <>
                                <div
                                  className="mt-4  px-2"
                                  style={{
                                    background: " rgba(245, 245, 245, 0.36)",
                                    border: "none",
                                    height: "119px",
                                  }}
                                >
                                  <div
                                    className="row py-3 my-3"
                                    style={{ height: "45px" }}
                                  >
                                    <div
                                      className="col-2 minWidthForInvoiceCreateTable ps-4"
                                      style={{ textAlign: "start" }}
                                    >
                                      <p>Product</p>
                                    </div>
                                    <div
                                      className="col-4 minWidthForInvoiceCreateTable  py-1"
                                      style={{ textAlign: "center" }}
                                    >
                                      Total Stock
                                    </div>
                                    <div
                                      className="col-5 minWidthForInvoiceCreateTable  py-1"
                                      style={{ textAlign: "center" }}
                                    >
                                      Variants
                                    </div>
                                  </div>
                                  <div
                                    className="row  "
                                    style={{ height: "45px" }}
                                  >
                                    <div className="col-2 ps-4 py-1 d-flex align-items-center">
                                      <p className="text-capitalize">{curr}</p>
                                      <img
                                        width={50}
                                        style={{
                                          margin: "6px 0px !important",
                                          border: "0.2px solid #bcb0b0",
                                        }}
                                        height={50}
                                        src={
                                          !isLoading && mainState[curr]["thumb"]
                                        }
                                        alt="failed"
                                        className="ms-3 shadow-sm"
                                      />
                                      {/* {console.log(" mainState[curr][thumb", mainState[curr]["thumb"])} */}
                                    </div>
                                    <div className="col-4 py-1">
                                      <p className="text-capitalize text-center d-flex align-items-center justify-content-center">
                                        {Number(mainState[curr]["stock"])}
                                        {/* {Number(mainState[curr]["stock"]) - Number(pS)} */}
                                      </p>
                                    </div>
                                    <div className="col-5 text-center   py-1 pe-2">
                                      <div
                                        className=""
                                        style={{ marginTop: "-10px" }}
                                      >
                                        {/* <div className="" style={{ marginTop: "-10px" }}> */}
                                        <Select
                                          styles={{
                                            height: "45px",
                                            background: "black",
                                          }}
                                          isMulti
                                          placeholder="-Select Variants-"
                                          isClearable
                                          // onChange={(value) => {
                                          //   handleInnerVariant(curr, value);
                                          // }}
                                          value={selectedVariants[curr] || []}
                                          onChange={(value) => {
                                            handleInnerVariant(curr, value);
                                            setSelectedVariants({
                                              ...selectedVariants,
                                              [curr]: value,
                                            });
                                          }}
                                          options={
                                            mainState[curr]["variant"] !==
                                              undefined &&
                                            Object.values(
                                              mainState[curr]["variant"]
                                            ).map((curr) => ({
                                              label: curr.name,
                                              value: curr.id,
                                              price: curr.price,
                                              stock: curr.stock,
                                              quantity: 1,
                                            }))
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <table className="mapTable shadow-sm ms-2">
                                  <thead
                                    className="mt-4"
                                    style={{
                                      background: " rgba(245, 245, 245, 0.36)",
                                    }}
                                  ></thead>
                                  <tbody className="innerTbody w-100">
                                    {mainInnerState !== undefined &&
                                      mainInnerState[curr] !== undefined &&
                                      mainInnerState[curr].map(
                                        (innerCur, index) => {
                                          return (
                                            <>
                                              {index === 0 && <VariantThead />}

                                              <tbody>
                                                <tr
                                                  className="border-invoice"
                                                  style={{ height: "45px" }}
                                                >
                                                  <td className="ps-4 py-3">
                                                    <p className="text-capitalize">
                                                      {innerCur.label}
                                                    </p>
                                                    {/* <input className="invoiceCreateBigInputs" value={name} type="text" /> */}
                                                  </td>
                                                  <td
                                                    className="text-center   py-3"
                                                    style={{
                                                      minWidth: "300px",
                                                    }}
                                                  >
                                                    <input
                                                      name="in_price"
                                                      // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                      value={getBasePlusVariant(
                                                        curr,
                                                        innerCur
                                                      )}
                                                      className="invoiceCreateSmallInputs"
                                                      placeholder="00.00"
                                                      type="text"
                                                      //
                                                    />
                                                  </td>
                                                  <td className="text-center   py-3">
                                                    <input
                                                      name="in_price"
                                                      // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                      value={Number(
                                                        innerCur.stock
                                                      )}
                                                      className="invoiceCreateSmallInputs"
                                                      placeholder="00.00"
                                                      type="text"
                                                    />
                                                    {/* {console.log(mainState[curr]["variantObj"][innerCur],'------vvv----------')} */}
                                                  </td>
                                                  <td className="text-center   py-3">
                                                    <input
                                                      name="in_quantity"
                                                      onWheel={(e) =>
                                                        e.target.blur()
                                                      }
                                                      onChange={(e) => {
                                                        // HandleNewProductsInputValue(e, innerCur);
                                                        const productName =
                                                          curr;
                                                        if (
                                                          e.target.value < 0
                                                        ) {
                                                          setPs(0);
                                                        } else {
                                                          setPs(e.target.value);
                                                        }

                                                        setMainState(
                                                          (prevInputs) => {
                                                            const copiedObjects =
                                                              Object.assign(
                                                                {},
                                                                mainState
                                                              ); // Shallow copy
                                                            // console.log("copiedObjects", copiedObjects);
                                                            // copiedObjects["Blankets"]["variant"][index]['quantity'] = 12; // Assign new value
                                                            const keyOfVariant =
                                                              Object.keys(
                                                                copiedObjects[
                                                                  productName
                                                                ]["variant"]
                                                              ).filter(
                                                                (curr) =>
                                                                  copiedObjects[
                                                                    productName
                                                                  ]["variant"][
                                                                    curr
                                                                  ]["name"] ===
                                                                  innerCur.label
                                                              );
                                                            const variantName =
                                                              innerCur.label;
                                                            // console.log("innercur", innerCur);
                                                            if (
                                                              parseInt(
                                                                e.target.value
                                                              ) < 0
                                                            ) {
                                                              copiedObjects[
                                                                productName
                                                              ]["variant"][
                                                                keyOfVariant
                                                              ]["quantity"] = 0;
                                                              copiedObjects[
                                                                productName
                                                              ]["variantObj"][
                                                                variantName
                                                              ]["quantity"] = 0;
                                                            } else if (
                                                              parseInt(
                                                                e.target.value
                                                              ) > innerCur.stock
                                                            ) {
                                                              showToast(
                                                                "error",
                                                                `Max Stock : ${innerCur.stock}`
                                                              );
                                                              copiedObjects[
                                                                productName
                                                              ]["variant"][
                                                                keyOfVariant
                                                              ]["quantity"] = 0;
                                                              copiedObjects[
                                                                productName
                                                              ]["variantObj"][
                                                                variantName
                                                              ]["quantity"] = 0;
                                                              copiedObjects[
                                                                productName
                                                              ]["variant"][
                                                                keyOfVariant
                                                              ]["quantity"] =
                                                                innerCur.stock;
                                                              copiedObjects[
                                                                productName
                                                              ]["variantObj"][
                                                                variantName
                                                              ]["quantity"] =
                                                                innerCur.stock;
                                                            } else {
                                                              copiedObjects[
                                                                productName
                                                              ]["variant"][
                                                                keyOfVariant
                                                              ]["quantity"] =
                                                                e.target.value;
                                                              copiedObjects[
                                                                productName
                                                              ]["variantObj"][
                                                                variantName
                                                              ]["quantity"] =
                                                                e.target.value;
                                                            }

                                                            let checkDiscountPrice =
                                                              parseInt(
                                                                copiedObjects[
                                                                  productName
                                                                ]["discount"]
                                                              ) === 0
                                                                ? parseInt(
                                                                    copiedObjects[
                                                                      productName
                                                                    ]["price"]
                                                                  )
                                                                : parseInt(
                                                                    copiedObjects[
                                                                      productName
                                                                    ][
                                                                      "discount"
                                                                    ]
                                                                  );
                                                            // console.log("checkDiscountPrice", checkDiscountPrice);
                                                            const baseAndVariant =
                                                              parseInt(
                                                                copiedObjects[
                                                                  productName
                                                                ]["variant"][
                                                                  keyOfVariant
                                                                ]["price"]
                                                              ) +
                                                              checkDiscountPrice;
                                                            copiedObjects[
                                                              productName
                                                            ]["variant"][
                                                              keyOfVariant
                                                            ]["total"] =
                                                              parseInt(
                                                                copiedObjects[
                                                                  productName
                                                                ]["variant"][
                                                                  keyOfVariant
                                                                ]["quantity"]
                                                              ) *
                                                              baseAndVariant;

                                                            const baseAndVariantObj =
                                                              parseInt(
                                                                copiedObjects[
                                                                  productName
                                                                ]["variantObj"][
                                                                  variantName
                                                                ]["price"]
                                                              ) +
                                                              checkDiscountPrice;
                                                            // console.log("baseAndVariantObj price--", baseAndVariantObj);
                                                            copiedObjects[
                                                              productName
                                                            ]["variantObj"][
                                                              variantName
                                                            ]["total"] =
                                                              parseInt(
                                                                copiedObjects[
                                                                  productName
                                                                ]["variantObj"][
                                                                  variantName
                                                                ]["quantity"]
                                                              ) *
                                                              baseAndVariantObj;

                                                            return copiedObjects; // Return copied object
                                                          }
                                                        );
                                                      }}
                                                      className="invoiceCreateSmallInputs"
                                                      placeholder="00.00"
                                                      type="number"
                                                      value={
                                                        (mainState ===
                                                          undefined &&
                                                          mainState[curr] ===
                                                            undefined &&
                                                          mainState[curr][
                                                            "variantObj"
                                                          ] === undefined &&
                                                          mainState[curr][
                                                            "variantObj"
                                                          ][innerCur.label] ===
                                                            undefined &&
                                                          mainState[curr][
                                                            "variantObj"
                                                          ][innerCur.label][
                                                            "quantity"
                                                          ] === undefined) ||
                                                        mainState[curr][
                                                          "variantObj"
                                                        ][innerCur.label][
                                                          "quantity"
                                                        ] === "-1" ||
                                                        mainState[curr][
                                                          "variantObj"
                                                        ][innerCur.label][
                                                          "quantity"
                                                        ] === -1
                                                          ? 0
                                                          : mainState[curr][
                                                              "variantObj"
                                                            ][innerCur.label][
                                                              "quantity"
                                                            ]
                                                      }
                                                    />
                                                  </td>
                                                  <td className="text-center py-3 ">
                                                    <input
                                                      // name="in_total"
                                                      value={
                                                        (mainState ===
                                                          undefined &&
                                                          mainState[curr] ===
                                                            undefined) ||
                                                        isNaN(
                                                          mainState[curr][
                                                            "variantObj"
                                                          ][innerCur.label][
                                                            "total"
                                                          ]
                                                        )
                                                          ? 0
                                                          : mainState[curr][
                                                              "variantObj"
                                                            ][innerCur.label][
                                                              "total"
                                                            ]
                                                      }
                                                      className="invoiceCreateSmallInputs"
                                                      placeholder="00.00"
                                                      type="text"
                                                    />
                                                  </td>{" "}
                                                </tr>
                                              </tbody>
                                            </>
                                          );
                                          // return <h2>{innerCurr.label}</h2>;
                                        }
                                      )}
                                  </tbody>

                                  {/* <VariantTable /> */}
                                </table>
                              </>
                            ) : (
                              <div>No variant</div>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="d-flex flex-column flex-md-row mt-4 justify-content-between mx-4">
                    <div>
                      <h6>Total Item : {Object?.keys(mainState)?.length}</h6>
                    </div>
                    {/* sub_total, delivery_charge, discount, total, paid_amount, Due_amount */}
                    <div>
                      <div
                        className="d-flex justify-content-between "
                        style={{
                          color: "#212121",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        <p>Sub Total :</p>
                        <p className="ms-3 ">
                          <input
                            className="ms-3 invoiceInpCss"
                            type="text"
                            value={isNaN(getSubTotal()) ? 0 : getSubTotal()}
                          />
                        </p>
                      </div>

                      <div className="d-flex justify-content-between cl d-none">
                        <p>Delivery Charge : </p>
                        <p className="ms-3 ">
                          <input
                            className="ms-3 invoiceInpCss"
                            type="number"
                            onChange={(e) => setCustomCharge(e.target.value)}
                            // value={invoice_payment_type === 0 || invoice_payment_type === "0" ? delivery_charge[invoice_delivery_charge] : 0}
                            value={getDeliveryCharge()}
                            style={{ border: "1px solid gray" }}
                          />
                        </p>
                      </div>
                      <div className="d-flex justify-content-between pl pt-2">
                        <p>
                          Discount{" "}
                          {invoice_discount_type === "0" ||
                          invoice_discount_type === 0
                            ? ""
                            : "%"}{" "}
                          :
                        </p>
                        <p className="ms-3 ">
                          <input
                            className="ms-3 invoiceInpCss paid_input_css"
                            name="invoice_paid"
                            type="text"
                            value={Discount}
                            onChange={(e) => setDiscount(e.target.value)}
                          />
                        </p>
                      </div>
                      {success && (
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            color: "#212121",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          <p>Redeem Discount :</p>
                          <p className="ms-3 ">
                            <input
                              className="ms-3 invoiceInpCss"
                              type="text"
                              value={redeem}
                            />
                          </p>
                        </div>
                      )}

                      <div
                        className="d-flex justify-content-between"
                        style={{
                          color: "#212121",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        <p>Total amount :</p>
                        <p className="ms-3 ">
                          <input
                            className="ms-3 invoiceInpCss"
                            type="text"
                            value={isNaN(getGrandTotal()) ? 0 : getGrandTotal()}
                          />
                        </p>
                      </div>

                      <div className="d-flex justify-content-between pl pt-2">
                        <p>Paid amount :</p>
                        <p className="ms-3 ">
                          <input
                            className="ms-3 invoiceInpCss paid_input_css"
                            name="invoice_paid"
                            type="text"
                            // value={paid}
                            value={isNaN(getGrandTotal()) ? 0 : getGrandTotal()}
                            disabled
                            // onChange={(e) => setpaid(e.target.value)}
                          />
                        </p>
                      </div>

                      <div className="d-flex justify-content-between cl">
                        <p>Due amount :</p>
                        <p className="ms-3 ">
                          <input
                            className="ms-3 invoiceInpCss"
                            type="text"
                            name="invoice_due"
                            value={isNaN(getDueAmount()) ? 0 : getDueAmount()}
                          />
                        </p>{" "}
                      </div>
                      <div className="d-flex justify-content-between cl">
                        <p>Recieved amount :</p>
                        <p className="ms-3 ">
                          <input
                            className="ms-3 invoiceInpCss paid_input_css"
                            type="number"
                            name="recived_amount"
                            value={InvoiceAllData["recived_amount"]}
                            onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                          />
                        </p>{" "}
                      </div>
                      <div className="d-flex justify-content-between cl">
                        <p>Change Amount :</p>
                        <p className="ms-3 ">
                          <input
                            className="ms-3 invoiceInpCss paid_input_css"
                            type="number"
                            name="invoice_examount"
                            value={
                              InvoiceAllData["recived_amount"] > getGrandTotal()
                                ? Math.abs(
                                    isNaN(getGrandTotal())
                                      ? 0
                                      : getGrandTotal() -
                                          InvoiceAllData["recived_amount"]
                                  )
                                : 0
                            }
                          />
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="bg-white cat-child ">
          <div
            className="d-flex align-items-center"
            style={{ padding: "20px 24px ", border: "0.5px solid #E0E0E0" }}
          >
            <h3
              style={{ fontSize: "23px", marginTop: "6px", marginLeft: "12px" }}
            >
              Invoice Details
            </h3>
          </div>
          {/* -----------product form---------- */}
          <div className="product-main createInvoiceInputContainer">
            <div className="row">
              <div className="col-12 col-md-6 d-none">
                {/* <RequiredLabel text="Invoice Number" /> */}
                <p>Invoice Number</p>

                <InputGroup className="mb-3">
                  <Form.Control
                    disabled
                    aria-label="Username"
                    placeholder="This will be generated automatically."
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-md-6 d-none">
                <p>Barcode</p>
                <InputGroup className="mb-3">
                  <Form.Control
                    disabled
                    placeholder="This will be generated automatically"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>

              <div className="col-12 col-md-6">
                <RequiredLabel text="Invoice Date" />
                <div className="position-relative">
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="date"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      placeholder="Select a date"
                      name="invoice_date"
                      value={InvoiceAllData["invoice_date"]}
                      onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                    />
                  </InputGroup>
                  {/* <div className="calenderContainer">
                    <CalendarBlank size={20} />
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-md-6 ">
                <RequiredLabel text={"Delivery Type"} />
                <div className="" style={{ height: "47px" }}>
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) => setinvoice_delivery_type(e.target.value)}
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                  >
                    <option value={0}>Regular</option>
                    <option value={1}>Urgent</option>
                  </Form.Select>
                </div>
              </div>

              {/* <div className="col-12 col-md-6 ">
                <RequiredLabel text="Due Date" />

                <div className="position-relative ">
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="date"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      placeholder="Select a date"
                      name="invoice_due_date"
                      value={InvoiceAllData["invoice_due_date"]}
                      onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                    />
                  </InputGroup>
             
                </div>
              </div> */}

              <div className="col-12 col-md-6">
                <RequiredLabel text="From Mobile" />
                {/* <p></p> */}

                <div className="d-flex phoneContainer ">
                  <PhoneInput
                    inputClass="phoneinput"
                    disableDropdown
                    onChange={(e) => setFromCode(e)}
                    country={"bd"}
                  />
                  {/* <PhoneInput inputClass="phoneinput" disableDropdown onChange={(e) => setFromCode(e)} enableSearch={true} country={"bd"} /> */}
                  <div className="w-100">
                    <Form.Control
                      aria-label="Input number"
                      placeholder="Input number"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      className="phone_insert_input"
                      name="invoice_from_mobile"
                      value={InvoiceAllData["invoice_from_mobile"]}
                      onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <p>Date Of Birth</p>
                <div className="position-relative">
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="date"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      placeholder="Select a date"
                      name="dob"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </InputGroup>
                  {/* <div className="calenderContainer">
                    <CalendarBlank size={20} />
                  </div> */}
                </div>
              </div>

              <div className="col-12 col-md-6 mt-3">
                <RequiredLabel text="Bill From" />

                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    name="invoice_bill_from"
                    value={InvoiceAllData["invoice_bill_from"]}
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  />
                </InputGroup>
              </div>

              <div className="col-12 col-md-6 mt-3">
                {/* <RequiredLabel text="Bill To" /> */}
                <p>Bill To</p>

                <InputGroup className="mb-3">
                  <Form.Control
                    name="invoice_bill_to"
                    value={
                      Found
                        ? FoundCustomer.name
                        : InvoiceAllData["invoice_bill_to"]
                    }
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>

              {/* new  */}
              <div className="col-12 col-md-6">
                <RequiredLabel text={"Discount Type"} />
                <div className="mb-3" style={{ height: "47px" }}>
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) => setinvoice_discount_type(e.target.value)}
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                    value={invoice_discount_type}
                  >
                    <option value={0}>Flat </option>
                    <option value={1}>Percentage</option>
                  </Form.Select>
                </div>
              </div>

              <div className="col-12 col-md-6 ">
                <RequiredLabel text={"Delivery Charge"} />
                <div className="mb-3">
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) => {
                      if (Number(e.target.value) === 0) {
                        setCustomCharge(80);
                      } else if (Number(e.target.value) === 1) {
                        setCustomCharge(150);
                      }
                      setinvoice_delivery_charge(e.target.value);
                    }}
                    disabled
                    value={invoice_delivery_charge}
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                  >
                    <option value={0}>Inside Dhaka : 80/-</option>
                    <option value={1}>Outside Dhaka : 150/-</option>
                    <option value={2}>Custom</option>
                  </Form.Select>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <RequiredLabel text="From Email" />

                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Username"
                    type="email"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    name="invoice_from_email"
                    value={InvoiceAllData["invoice_from_email"]}
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  />
                </InputGroup>
              </div>

              {Found ? (
                <div className="col-12 col-md-6 ">
                  {/* <RequiredLabel text="To Email" /> */}
                  <p>To Email</p>

                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      name="invoice_to_email"
                      type="email"
                      value={FoundCustomer.email}
                      onChange={(e) =>
                        setFoundCustomer({
                          ...FoundCustomer,
                          email: e.target.value,
                        })
                      }
                    />
                  </InputGroup>
                </div>
              ) : (
                <div className="col-12 col-md-6 ">
                  {/* <RequiredLabel text="To Email" /> */}
                  <p>To Email</p>

                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      name="invoice_to_email"
                      type="email"
                      value={InvoiceAllData["invoice_to_email"]}
                      onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                    />
                  </InputGroup>
                </div>
              )}

              <div className="col-12 col-md-6">
                <RequiredLabel text="From Address" />

                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    name="invoice_from_address"
                    value={InvoiceAllData["invoice_from_address"]}
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  />
                </InputGroup>
              </div>

              <div className="col-12 col-md-6"></div>
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="To Address 1" /> */}
                <p>To Address 1</p>

                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    name="invoice_to_address"
                    value={
                      Found
                        ? FoundCustomer.to_address
                        : InvoiceAllData["invoice_to_address"]
                    }
                    onChange={(e) =>
                      Found
                        ? setFoundCustomer({
                            ...FoundCustomer,
                            to_address: e.target.value,
                          })
                        : handleInputs(e, SetInvoiceAllData)
                    }
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-md-6">
                <p>To Address 2</p>

                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    name="invoice_to_address2"
                    value={
                      Found
                        ? FoundCustomer.to_address2
                        : InvoiceAllData["invoice_to_address2"]
                    }
                    onChange={(e) =>
                      Found
                        ? setFoundCustomer({
                            ...FoundCustomer,
                            to_address: e.target.value,
                          })
                        : handleInputs(e, SetInvoiceAllData)
                    }
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>

              {/* country */}
              <div className="col-12 col-md-6">
                <RequiredLabel text="To Country" />

                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    name="to_country"
                    value={
                      Found
                        ? FoundCustomer.to_country
                        : InvoiceAllData["to_country"]
                    }
                    onChange={(e) =>
                      Found
                        ? setFoundCustomer({
                            ...FoundCustomer,
                            to_country: e.target.value,
                          })
                        : handleInputs(e, SetInvoiceAllData)
                    }
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>
              {/* divi */}
              {/* new address inputs start */}
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="To Division" /> */}
                <p>To Division</p>
                <Controller
                  name="division"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isSearchable
                      {...field}
                      value={selectedDivision}
                      onChange={handleDivisionChange}
                      styles={customStyles}
                      options={divisions.map(({ name, id }) => {
                        return { label: name, value: id };
                      })}
                    />
                  )}
                />
                {/* {errors.division ? (
                  <span className="input-error">
                    Please provide your division name
                  </span>
                ) : null} */}
                {/* <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    name="to_division"
                    value={
                      Found
                        ? FoundCustomer.to_division
                        : InvoiceAllData["to_division"]
                    }
                    onChange={(e) =>
                      Found
                        ? setFoundCustomer({
                            ...FoundCustomer,
                            to_division: e.target.value,
                          })
                        : handleInputs(e, SetInvoiceAllData)
                    }
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup> */}
                {/* <div className="mb-3">
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) =>
                      Found
                        ? setFoundCustomer({
                          ...FoundCustomer,
                          to_division: e.target.value,
                        })
                        : handleInputs(e, SetInvoiceAllData)
                    }
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                  >
                    {divisions.map((curr) => {
                      return <option value={curr.name}>{curr.name}</option>;
                    })}
                  </Form.Select>
                </div> */}
              </div>
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="To District" /> */}
                <p>To District</p>
                <Controller
                  name="district"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isSearchable
                      {...field}
                      value={selectedDistrict}
                      onChange={handleDistrictChange}
                      styles={customStyles}
                      // defaultValue={ label: "", value: "" }
                      options={districtList?.map(({ name, id }) => {
                        return { label: name, value: id };
                      })}
                    />
                  )}
                />
                {/* {errors.district ? (
                  <span className="input-error">
                    Please provide your district name
                  </span>
                ) : null} */}
              </div>
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="" /> */}
                <p>To City</p>
                <Controller
                  name="town"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isSearchable
                      {...field}
                      value={selectedTown}
                      onChange={handleTownChange}
                      styles={customStyles}
                      // defaultValue={ label: "", value: "" }
                      options={upazillaList?.map(({ name, id }) => {
                        return { label: name, value: id };
                      })}
                    />
                  )}
                />
                {/* {errors.town ? (
                  <span className="input-error">
                    Please provide your city or town name
                  </span>
                ) : null} */}
              </div>
              <div className="col-12 col-md-6 mt-2">
                {/* <RequiredLabel text="To Division" /> */}
                <p>Post Code</p>
                {/* <Controller
                  name="zip"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isSearchable
                      {...field}
                      styles={customStyles}
                      options={zipList.map(({ name, postCode }) => {
                        return {
                          label: `${name}-${postCode}`,
                          value: postCode,
                        };
                      })}
                    />
                  )}
                /> */}
                {/* {errors.zip ? (
                  <span className="input-error">
                    Please provide your zip code.
                  </span>
                ) : null} */}
                <input
                  style={{ width: "100%" }}
                  type="text"
                  {...register("zip")}
                />
              </div>
              {/* new address input end */}
              <div className="col-12 col-md-6">
                <RequiredLabel text={"Payment Type"} />
                <div className="mb-3">
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) => {
                      clearPaymentALlData();
                      setinvoice_payment_type(e.target.value);
                    }}
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                  >
                    {paymentType.map((curr) => {
                      return <option value={curr.value}>{curr.label}</option>;
                    })}
                  </Form.Select>
                </div>
              </div>
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="To District" /> */}
                <p>Reference ID</p>
                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    name="reference_id"
                    value={paymentAllData["reference_id"]}
                    onChange={(e) => handleInputs(e, setPaymentAllData)}
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        {invoice_payment_type === 8 ||
          (invoice_payment_type === "8" && (
            <div className="invoice-b my-4 ms-1 bg-white cat-child row">
              <div className="col-12 my-3 ">
                <p>Multiple Payment </p>
                <div className="position-relative">
                  <Select
                    // styles={styles}
                    isMulti
                    placeholder="---Select---"
                    isClearable
                    // defaultValue={selectedOption}
                    onChange={(newValue) => setMixPaymentData(newValue)}
                    options={paymentTypeForMix}
                    value={mixPaymentData}
                  />
                </div>
              </div>
              {mixPaymentData?.map((data) => (
                <>
                  <div className="col-12 col-md-6">
                    {/* <RequiredLabel text="To District" /> */}
                    <p>{data?.label}</p>
                    <InputGroup className="mb-3 addressInput">
                      <Form.Control
                        aria-label="Username"
                        type="number"
                        min={0}
                        name={data?.label}
                        defaultValue={data?.amount}
                        onChange={(e) =>
                          handleInputChange(data.value, e.target.value)
                        }
                        aria-describedby="basic-addon1"
                        style={{ backgroundColor: "#FAFAFA", height: "40px" }}
                      />
                    </InputGroup>
                  </div>
                </>
              ))}
            </div>
          ))}

        <div
          className={`invoice-b my-4 bg-white cat-child ${
            invoice_payment_type === 0 ||
            invoice_payment_type === "0" ||
            invoice_payment_type === 6 ||
            invoice_payment_type === "6" ||
            invoice_payment_type === "7" ||
            invoice_payment_type === "8" ||
            invoice_payment_type === 7 ||
            invoice_payment_type === 8
              ? "d-none"
              : ""
          }`}
          style={{ borderTop: "0.5px solid #E0E0E0" }}
        >
          <h4 className=" my-2 mb-4 padding-main-head">Payment Details</h4>
          <div className="border-p"></div>
          {/* ==========for bKash========== */}
          {invoice_payment_type === 3 ||
            (invoice_payment_type === "3" && (
              <div className="padding-main row">
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="bKash Number" /> */}
                  <p>Bkash Number</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="bkash_number"
                      value={paymentAllData["bkash_number"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="TRX Number" /> */}
                  <p>TNX Number</p>
                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="bkash_trx_number"
                      value={paymentAllData["bkash_trx_number"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
              </div>
            ))}
          {invoice_payment_type === 5 ||
            (invoice_payment_type === "5" && (
              <div className="padding-main row">
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="bKash Number" /> */}
                  <p>Nagad Number</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="nagad_number"
                      value={paymentAllData["nagad_number"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="TRX Number" /> */}
                  <p>TNX Number</p>
                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="nagad_trx_number"
                      value={paymentAllData["nagad_trx_number"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
              </div>
            ))}
          {/* ==========for bKash========== */}
          {/* ===========For Bank Transfer */}
          {invoice_payment_type === 2 ||
            (invoice_payment_type === "2" && (
              <div className="padding-main row">
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Bank Name" /> */}
                  <p>Bank Name</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="bank_name"
                      value={paymentAllData["bank_name"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Branch Name" /> */}
                  {/* <RequiredLabel text="Card Holder Name" /> */}
                  <p>Branch Name</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="banK_branch_name"
                      value={paymentAllData["banK_branch_name"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Acount Number" /> */}
                  <p>Account Number</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="banK_account_number"
                      value={paymentAllData["banK_account_number"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Account Name" /> */}
                  <p>Account Name</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="banK_account_name"
                      value={paymentAllData["banK_account_name"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
              </div>
            ))}

          {/* ===========For Bank Transfer=============== */}
          {/* ===========For Card Payment=============== */}
          {invoice_payment_type === 1 ||
            (invoice_payment_type === "1" && (
              <div className="padding-main row">
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Card Number" /> */}
                  <p>Card Number</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="card_number"
                      value={paymentAllData["card_number"]}
                      // placeholder="4111111111111111"
                      // placeholder="4123412341234123"
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Card Holder Name" /> */}
                  <p>Card Holder Name</p>
                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="card_holder_name"
                      value={paymentAllData["card_holder_name"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                {/* <div className="col-12 col-md-6">
                  <RequiredLabel text="Expiry Date" />

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      placeholder="MM-YY"
                      name="card_expiry_date"
                      value={paymentAllData["card_expiry_date"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                  {console.log(paymentAllData, "============payment all data=============")}
                </div> */}
              </div>
            ))}

          {/* ===========For Card Payment=============== */}
        </div>

        {/* ===============scaner============== */}
        <Scanner2 setScanCode={setScanCode} />
        {/* ===============scaner============== */}
        {/* ====================attribute=================== */}

        {/* ====================attribute=================== */}
        <div
          className="product-main my-4 bg-white cat-child"
          style={{ borderTop: "0.5px solid #E0E0E0" }}
        >
          <div>
            <p>Payment Status</p>

            {/* <SelectDropDown
              options={invoice_choices}
              setSelectedOptionCategory={setinvoice_status}
            /> */}
            <Select
              placeholder="---Select---"
              isClearable
              defaultValue={invoice_choices[0]}
              onChange={(actionMeta) => setinvoice_status(actionMeta.value)}
              options={invoice_choices}
            />
            <p className="mt-4">Delivery Status</p>

            {/* <SelectDropDown
              options={delivery_choices}
              setSelectedOptionCategory={setDelivey_status}
            /> */}
            <Select
              placeholder="---Select---"
              isClearable
              defaultValue={delivery_choices[0]}
              onChange={(actionMeta) => setDelivey_status(actionMeta.value)}
              options={delivery_choices}
            />

            {/* notes */}
            <div className="mt-3 d-flex align-content-center">
              <PencilSimple size={12} weight="light" className="mt-0 pe-1" />{" "}
              <p style={{ color: "#333333" }} className="mb-2">
                Notes
              </p>
            </div>
            <Form.Control
              as="textarea"
              placeholder=""
              className="mb-3"
              name="invoice_notes"
              value={InvoiceAllData["invoice_notes"]}
              onChange={(e) => handleInputs(e, SetInvoiceAllData)}
              style={{
                height: "100px",
                resize: "none",
                backgroundColor: "#FAFAFA",
              }}
            />
            {/* notes */}
            {/* <div className="mt-3 d-flex align-content-center">
              <PencilSimple size={12} weight="light" className="mt-0 pe-1" />{" "}
              <p style={{ color: "#333333" }} className="mb-2">
                Outlet Notes
              </p>
            </div>
            <Form.Control
              as="textarea"
              placeholder=""
              className="mb-3"
              name="invoice_notes2"
              value={InvoiceAllData["invoice_notes2"]}
              onChange={(e) => handleInputs(e, SetInvoiceAllData)}
              style={{
                height: "100px",
                resize: "none",
                backgroundColor: "#FAFAFA",
              }}
            /> */}
          </div>

          <div className="row d-flex justify-content-between"></div>
        </div>

        <div
          className="padding-btn-head mb-4 d-flex justify-content-end  bg-white cat-child"
          style={{ border: "0.5px solid #E0E0E0" }}
        >
          <div className="" style={{ marginLeft: "-10px" }}>
            <button
              className={`btn rounded border align-items-center me-2 mt-2 d-none }`}
              disabled={!InvoiceAllData}
              onClick={handlePrint2}
            >
              <DoIcon className="doc" /> Preview PDF
            </button>

            {/* <button
              onClick={() => {
                ValidateData(false);
              }}
              className="btn rounded border me-2 mt-2"
              onDoubleClick={() => showToast("success", "Invoice Creating")}
            >
              Save Invoice
            </button> */}
            <ProToPremium
              saveInvoice={() => ValidateData(false)}
              success={Found}
              membershipInfo={membershipInfo}
              total={() => getGrandTotal()}
            />
            <button
              className="btn rounded border me-2 mt-2"
              onClick={() => history.push(path.invoice_list)}
            >
              Cancel
            </button>
            {InvoiceAllData["invoice_to_email"] !== "" &&
              !Found &&
              validateEmail(InvoiceAllData["invoice_to_email"]) && (
                <button
                  onClick={SaveAndSendEmail}
                  className="btn rounded border text-white mt-2"
                  style={{ background: "black" }}
                  onDoubleClick={() => showToast("success", "Invoice Creating")}
                >
                  Save & Send PDF
                </button>
              )}

            {Found &&
              FoundCustomer?.email !== "" &&
              validateEmail(FoundCustomer?.email) && (
                <button
                  onClick={SaveAndSendEmail}
                  className="btn rounded border text-white mt-2"
                  style={{ background: "black" }}
                  onDoubleClick={() => showToast("success", "Invoice Creating")}
                >
                  Save & Send PDF
                </button>
              )}
            <div className="d-none">
              <PreviewCreate
                print={print}
                status={invoice_payment_type}
                Found={Found}
                InvoiceAllData={InvoiceAllData}
                FoundCustomer={FoundCustomer}
                mainState={mainState}
                invStatus={invoice_status}
                Jdata={Jdata}
              ></PreviewCreate>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInvoicelClub;

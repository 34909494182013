import React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";


import axios from "axios";
import { useEffect } from "react";
import { BASE_URL } from "../../Const/Url";
import { logout_func2 } from "../../Const/logoutCommon";
import Loader from "../../CustomCommons/Loader";


const ViewReturnProduct = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [outletProduct, setOutletProduct] = useState({});
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const getOutletName = () => {
    const url = `${BASE_URL}api/v1/inventory/admin/outlet-product-return/${id}/`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data;
        setOutletProduct(result);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
        // console.log(err.request.response);
      });
  };
  useEffect(() => {
    getOutletName();
  }, []);
  if (loading) {
    return <Loader />;
  }
  console.log(outletProduct);

  return (
    <div>
      <div className="categorey-parent " style={{ height: "100%" }}>
        <div className="only-margin">
          <div className="d-flex gap-3 justify-content-center">
            <h4>Outlet Name : {outletProduct?.outlet_name}</h4>
            <h4>Outlet User : {outletProduct?.user_name}</h4>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-evenly my-3">
          {outletProduct?.outletVariant.map((product) => (
            <>
              <div>
                <h5>Product Name</h5>
                <p>{outletProduct?.product_details?.name}</p>
              </div>
              <div>
                <h5>Variant  Name</h5>
                <p>{product?.variant_details.name}</p>
              </div>
              <div>
                <h5>Return Stock</h5>
                <p>{product?.stock}</p>
              </div>
              <div>
                <h5>Request Time</h5>
                <p>{outletProduct?.created_at?.split('T')[0]}</p>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewReturnProduct;

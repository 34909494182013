import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import swal from "sweetalert";
import plus from "../../assets/Icon/plus.svg";
import { BASE_URL } from "../Const/Url";
import DeleteIcon from "../CustomCommons/DeleteIcon";
import EditIcon from "../CustomCommons/EditIcon";
import Loader from "../CustomCommons/Loader";
import NextIcon from "../CustomCommons/NextIcon";
import Permission from "../CustomCommons/Permission";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import kaarujLogo from "../../assets/Icon/KAARUJ 1.png";
import {
  render,
  Br,
  Cut,
  Line,
  Printer,
  Text,
  Row,
} from "react-thermal-printer";
import {
  can_add_invoice,
  can_change_invoice,
  can_delete_invoice,
  can_view_invoice,
  has_permissions,
} from "../CustomCommons/utils";
import ViewIcon from "../CustomCommons/ViewIcon";
import * as path from "../Routes/RoutePaths";
import { showToast } from "../../utils/ToastHelper";
import { logout_func2 } from "../Const/logoutCommon";
import Download from "../CustomCommons/Download";
import JspmCom from "./JspmCom";

const ExchangeList = () => {
  const userProfile = JSON.parse(window.localStorage.getItem("userData"));
  const [isLoading, SetisLoading] = useState(true);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const cRef = useRef();
  const [query, setQuery] = useState("");

  // ofset code
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 20;
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  const download = (slug, product_name) => {
    // window.open(url);
    const url = `${BASE_URL}api/v1/sales/inventory/invoice/${slug}/outlet_invoice_print/`;

    axios
      .get(url)
      .then((res) => {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:image/png;base64," + res.data.data.data; //Image Base64 Goes here
        console.log(a.href);
        a.download = product_name + ".png"; //File name Here
        a.click(); //Downloaded file
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    apiFunction(
      `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_outlet=true&outlet=${
        userProfile?.outlet
      }`
    );
  }, []);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
    // SetisLoading(false)
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
    // SetisLoading(false)
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);

  useEffect(() => {
    if (pageNumber > 1 || query) {
      apiFunction(
        `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_outlet=true&&outlet=${
          userProfile?.outlet
        }`
      );
    }
  }, [pageNumber, query]);

  // ofset code

  const history = useHistory();
  const updatedPageNumber = parseInt(
    localStorage.getItem("invoicePageNbr")
      ? localStorage.getItem("invoicePageNbr")
      : 0
  );
  // const [pageNumber, setPageNumber] = useState(updatedPageNumber);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  const [invoiceList, setInvoiceList] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  //print reset
  const [data, setData] = useState({});
  const [Jdata, setJData] = useState([]);
  const handleCheckboxChange = (id) => {
    // Check if the ID is already in the state
    const isChecked = checkedIds.includes(id);

    if (isChecked) {
      // If checked, remove the ID from the state
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      // If unchecked, add the ID to the state
      setCheckedIds([...checkedIds, id]);
    }
  };
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked) {
      const data = items_data.map((item) => item.slug);
      setCheckedIds(data);
    } else {
      setCheckedIds([]);
    }
  };
  const invoice_choices = ["Paid", "Unpaid", "Refunded", "Exchange"];

  const delivery_choices = [
    "Returned",
    "Order Placed",
    "Delivered",
    "Pending",
    "Hold",
    "Dispached",
  ];
  const delivery_type_choices = ["Regular", "Urgent"];
  const paymentType = [
    "Cash",
    "Card",
    "Bank Transfer",
    "bKash",
    "SSLECOMMERZ",
    "Nagad",
    "KOD",
    "Rocket",
    "Mixed",
  ];
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${month}-${day}-${year}`;
  var options = {
    day: "numeric",
    year: "numeric",
    month: "numeric",
  };
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Daily Sales Report",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });
  const handlePrints = () => {
    HandlePrint();
  };

  const getInvoiceList = () => {
    let url;
    if (date1 && date2) {
      url = `${BASE_URL}api/v1/sales/inventory/invoice/filter/?start=${date1}&end=${date2}&is_outlet=true&outlet=${userProfile?.outlet}`;
    } else {
      url = `${BASE_URL}api/v1/sales/inventory/invoice/?is_outlet=true&outlet=${userProfile?.outlet}`;
    }
    axios
      .get(url)
      .then((res) => {
        setInvoiceList(res.data.data.results);
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    getInvoiceList();
  }, []);
  // console.log(data);

  const keys = ["number", "prepared_by", "delivery_status", "invoice_date"];
  const getData = invoiceList.filter((p) =>
    keys.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
  );

  const generateData = () => {
    if (!date1 && !date2) {
      swal("Please select two dates");
    } else if (
      (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") ||
      (date1 === "" && date2 === "")
    ) {
      swal("Please select two dates");
    } else if (date1 === "undefined/undefined/" || date1 === "") {
      swal("Please select  start Date");
    } else if (date2 === "undefined/undefined/" || date2 === "") {
      swal("Please select  end Date");
    } else {
      getInvoiceList();
    }
  };
  useEffect(() => {
    // getInvoiceList();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
  }, []);

  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  //   localStorage.setItem("invoicePageNbr", selected);
  // };
  // console.log(invoiceList);
  if (!has_permissions(can_view_invoice)) {
    return <Permission />;
  }
  if (isLoading) {
    return <Loader />;
  }

  const sourceList = ["Website", "Inventory", "Admin"];

  

  //imvoice print

  // const handleClick = (categorey) => {
  //   console.log("categorey", categorey);

  //   const printWindow = window.open("", "_blank");
  //   printWindow.document.write(
  //     "<html><head><title>Bill Receipt</title><title>KAARUJ</title></head><body>"
  //   );

  //   printWindow.document.write("<h1> BILL Receipt</h1>");
  //   printWindow.document.write("<h1> KAARUJ</h1>");
  //   printWindow.document.write("<ul>");

  //   // Iterate over products and add them to the receipt
  //   categorey?.invoice_products.forEach((product) => {
  //     printWindow.document.write(
  //       `<li>${product.product_name}-${product.variant_name} :  ৳${
  //         Number(product.total) / Number(product?.quantity)
  //       }</li>`
  //     );
  //   });

  //   printWindow.document.write("</ul>");

  //   // Calculate and display the total price
  //   const totalPrice = categorey?.invoice_products
  //     .forEach((curr) => Number(curr.total))
  //     .reduce((a, b) => a + b, 0);
  //   printWindow.document.write(`<p>Total: $${totalPrice.toFixed(2)}</p>`);

  //   printWindow.document.write("</body></html>");
  //   printWindow.document.close();
  //   printWindow.print();
  // };
  const handleClick = (category) => {
    console.log("category", category);

    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=212px, initial-scale=1.0">
          <title>${category.number}</title>
          <style>
            /* Add your CSS styles here */
            table, tr, td {
              border: none;
          }
          body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              width: 212px;
              font-weight: normal;
          }
  
          .container {
              width: 100%;
          }
          .invoice-note ul li {
            margin: 8px 0;
            font-size: 10px;
        }
  
          .header {
              text-align: center;
          }
  
          .address {
              margin: 5px 0;
              text-align: center;
          }
  
          .address p {
              margin: 3px;
              font-size: 10px;
          }
  
          .invoice-details {
              margin: 5px 0;
          }
  
          .invoice-details p {
              margin: 3px 0;
              font-size: 10px;
          }
  
          .invoice-table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 5px;
          }
  
          .invoice-table th,
          .invoice-table td {
              border: 1px solid #000;
              padding: 3px;
              text-align: center;
              font-size: 10px;
          }
  
          .invoice-table th {
              background-color: #f2f2f2;
          }
  
          .total-section {
              margin-top: 5px;
          }
  
          .total-section p {
              margin: 3px 0;
              text-align: right;
              font-size: 10px;
          }
          .header {
              text-align: center;
              margin-top: 5px; 
          }
  
          .address {
              margin: 5px 0 0 0; 
              text-align: center;
          }
  
          @media print {
              .invoice-table {
                  page-break-inside: avoid;
              }
          }
          </style>
        </head>
        <body>
          <div class="">
            <div class="header" style="margin-bottom: 5px; font-weight:bolder;">
              <p>Bill Receipt</p>
            </div>
  
            <div class="address">
              <div class="address" style="margin-bottom: 0px;font-weight:bolder;">
                <p4>${category.bill_from}</p4>
              </div>
              <p>${category.from_address}</p>
              <p>Mobile: ${category.from_mobile}</p>
    <p> Email: ${category.from_email}</p>
              <p>Date: ${category.invoice_date}</p>
            </div>
  
            <div class="invoice-details">
              <p>Invoice No: ${category.number}</p>
              <p>Name: ${category.bill_to}</p>
              <p>Address: ${
                category.to_address ? category.to_address : "Dhaka"
              }</p>
              <p>Phone: ${category.to_mobile} </p>
              ${category.to_email && `<p>Email: ${category.to_email}</p>`}
            
            </div>
  
            <table class="invoice-table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                ${category.invoice_products
                  .map(
                    (item) => `
                      <tr>
                        <td><p>${item.product_name}(${
                      item.variant_name
                    })</p></td>
                        <td><p>${
                          Number(item.total) / Number(item?.quantity)
                        }</p></td>
                        <td><p>${item.quantity}</p></td>
                        <td><p>${item.total}</p></td>
                      </tr>
                    `
                  )
                  .join("")}
              </tbody>
            </table>
  
            <div class="total-section">
              <p>Total Item: ${category.invoice_products.length}</p>
              <p>Total Amount: ${category.total_amount}</p>
        
              <p>Discount: ${category?.discount_type === 0 ? "৳" : ""}
              ${category.total_discount}
              ${category?.discount_type === 1 ? "%" : ""} </p>
              <p>Paid Amount: ${category.total_paid}</p>
              <p>Due Amount: ${category.total_due}</p>
            </div>
          </div>
          <div class='invoice-note'>
          <ul>
          <li>Product is non-refundable if there is no major manufacturing fault. Refund process will be completed within 10 business days after receiving faulty products, checked and accepted by the seller.</li>
          <li>Exchange only allowed within 7 days with the same or higher price product.</li>
          <li>Product will be delivered within Bangladesh upon receipt of full payment.</li>
        </ul>
    </div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  };

  const printInvoice = (categorey) => {
    let url = `${BASE_URL}api/v1/sales/inventory/invoice/${categorey.slug}`;
    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data, "---------single invoice---------");

        handleClick(res.data.data);

        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  return (
    <div>
      <div className="categorey-parent">
        {/* <JspmCom/> */}
        <div className="cotagorey-head mb-4">
          <div>
            <h3>Exchange List </h3>
            <input
              style={{ color: "black" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              onChange={(e) => {
                const selected = 0;
                // changePage({ selected });
                setQuery(e.target.value.toLocaleLowerCase());
                localStorage.setItem("invoicePageNbr", selected);
                // (updatedPageNumber = selected)
              }}
            />
          </div>
          <div className="filter_div">
            <div className="my-2 my-lg-0">
              <input
                type="date"
                format="DD-MM-YYYY"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;
                  setDate1(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <span className="mx-2 my-auto" placeholder="09-09-2022">
                {" "}
                To
              </span>
              <input
                type="date"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;

                  setDate2(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
            </div>
            <div
              className="button-div my-auto mx-2 "
              style={{ background: "black", cursor: "pointer" }}
              onClick={generateData}
            >
              <span> Generate</span>
            </div>
            {getData?.length > 0 && (
              <div
                className="button-div my-auto me-2 "
                style={{ background: "black", cursor: "pointer" }}
                onClick={() => handlePrints()}
              >
                <span> Print</span>
              </div>
            )}
            {has_permissions(can_add_invoice) && (
              <div
                className="button-div"
                onClick={() => history.push(path.create_invoice)}
              >
                <img src={plus} alt="" />
                <span> Create Invoice</span>
              </div>
            )}
          </div>
        </div>

        {/* categorey list table */}
        <div style={{ display: "block", overflowX: "auto" }}>
          <table className="table_my">
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px", borderTopLeftRadius: "20px !important" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "166px",
                  }}
                >
                  <p className="ms-3 my-auto">Invoice No</p>
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "147px",
                  }}
                >
                  Delivery Status
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "130px",
                  }}
                >
                  Delivery Type
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Payment Type
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                    textAlign: "center",
                  }}
                >
                  Mobile
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "187px",
                    textAlign: "center",
                  }}
                >
                  Total
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "130px",
                  }}
                >
                  Create On
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "160px",
                  }}
                >
                  Last Modified
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopRightRadius: "4px ",
                    width: "170px",
                  }}
                >
                  <span className="ms-4">Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {items_data.length > 0 && items_data ? (
                items_data.map((category, index) => (
                  <tr className="table-row " key={category?.slug}>
                    <td>
                      <p className="ms-3 my-auto">{category.number}</p>
                    </td>

                    <td>
                      <button
                        className={`status1 ${
                          (category.delivery_status === 0 &&
                            "bg-danger text-white") ||
                          (category.delivery_status === 1 && "c5 text-white") ||
                          (category.delivery_status === 2 &&
                            "bg-success text-white ")
                        }`}
                      >
                        {delivery_choices[category.delivery_status]}
                      </button>
                    </td>

                    <td>
                      <button
                        className={`status1 ${
                          (category.delivery_type === 0 && "c5 text-white") ||
                          (category.delivery_type === 1 &&
                            "bg-danger text-white")
                        }`}
                      >
                        {/* {category.delivery_status === 1 ? "Delivered" : "Processing"} */}
                        {delivery_type_choices[category.delivery_type]}
                      </button>
                    </td>
                    <td className="mx-auto">
                      <button
                        className={`status1 ${
                          (category.payment_status === 0 && "i1") ||
                          (category.payment_status === 1 && "c4 text-white") ||
                          (category.payment_status === 2 && "c3 text-white")
                        }`}
                      >
                        {invoice_choices[category.payment_status]}
                      </button>
                    </td>
                    <td className="mx-auto text-center">
                      {" "}
                      {paymentType[category?.payment_type]}
                    </td>
                    <td className="mx-2">{category?.to_mobile.slice(3, -1)}</td>
                    <td className="text-center">৳ {category?.total_amount}</td>
                    {/* <td>
                      <span className="ms-1">
                      0
                      </span>
                    </td> */}
                    <td>{category?.invoice_date}</td>
                    <td className="ms-1">{category.last_modified_by}</td>
                    <td>
                      <div className="d-flex gap-3 justify-content-center">
                        {category?.source === 0 ? (
                          <div
                            className="edit-del blue"
                            onClick={() =>
                              history.push(
                                `/sales/view-web-invoice/${category?.slug}`
                              )
                            }
                          >
                            <ViewIcon />
                          </div>
                        ) : (
                          <div
                            className="edit-del blue"
                            onClick={() =>
                              history.push(
                                `/sales/view-invoice/${category?.slug}`
                              )
                            }
                          >
                            <ViewIcon />
                          </div>
                        )}

                        {/* {has_permissions(can_change_invoice) ? (
                          <>
                            {category?.source === 0 ? (
                              <div
                                className="edit-del green"
                                onClick={() =>
                                  history.push(
                                    `/sales/edit-web-invoice/${category?.slug}`
                                  )
                                }
                              >
                                <EditIcon />
                              </div>
                            ) : (
                              <div
                                className="edit-del green"
                                onClick={() =>
                                  history.push(
                                    `/sales/edit-invoice/${category?.slug}`
                                  )
                                }
                              >
                                <EditIcon />
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            className="edit-del green"
                            onClick={() =>
                              swal("Sorry , You are not able to edit invoice.")
                            }
                          >
                            <EditIcon />
                          </div>
                        )} */}

                        <div
                          className="edit-del green"
                          // onClick={()=>download(category?.slug,category?.slug)}
                          onClick={() => printInvoice(category)}
                          // onClick={() => handleClick(category)}
                        >
                          <Download />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* ----------2nd Table for pdf print------*/}
        <div style={{ display: "none" }} className="pt-4">
          <table
            className="table_my pdf-table my-3 pt-4 print_table"
            ref={cRef}
          >
            <thead
              className="d-none  thed w-100 text-center me-4"
              style={{ marginTop: "20px" }}
            >
              <div className="mb-4 pb-4 d-flex justify-content-center align-items-center">
                <img className="pb-3" src={kaarujLogo} width={150} alt="" />
                <p className="mt-2 ms-4">
                  Invoice List -
                  <span className="ms-3">Print Date : {currentDate} </span>
                  <span className="ms-3">Total Item : {getData?.length}</span>
                </p>
              </div>
            </thead>
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px", borderTopLeftRadius: "20px !important" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "166px",
                  }}
                >
                  <p className="ms-3 my-auto">Invoice No</p>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "187px",
                  }}
                >
                  Prepared By
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "147px",
                  }}
                >
                  Delivery Status
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "130px",
                  }}
                >
                  Delivery Type
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Payment Type
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                  }}
                >
                  Total
                </th>
                {/* <th 
                 style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  width: "100px",
                }}>
                  Count
                </th> */}
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "130px",
                  }}
                >
                  Create On
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                  }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {getData.length > 0 ? (
                getData.map((category, index) => (
                  <tr className="table-row " key={category?.slug}>
                    <td>
                      <p className="ms-3 my-auto">{category.number}</p>
                    </td>
                    <td>{category?.prepared_by}</td>

                    <td>
                      <button
                        className={`status1 ${
                          (category.delivery_status === 0 &&
                            "bg-danger text-white") ||
                          (category.delivery_status === 1 && "c5 text-white") ||
                          (category.delivery_status === 2 &&
                            "bg-success text-white ")
                        }`}
                      >
                        {/* {category.delivery_status === 1 ? "Delivered" : "Processing"} */}
                        {delivery_choices[category.delivery_status]}
                      </button>
                    </td>

                    <td>
                      <button
                        className={`status1 ${
                          (category.delivery_type === 0 && "c5 text-white") ||
                          (category.delivery_type === 1 &&
                            "bg-danger text-white")
                        }`}
                      >
                        {/* {category.delivery_status === 1 ? "Delivered" : "Processing"} */}
                        {delivery_type_choices[category.delivery_type]}
                      </button>
                    </td>
                    <td> {paymentType[category?.payment_type]}</td>
                    <td>৳ {category?.total_amount}</td>

                    <td>{category?.invoice_date}</td>
                    <td className="">
                      <button
                        className={`status1 ${
                          (category.payment_status === 0 && "i1") ||
                          (category.payment_status === 1 && "c4 text-white") ||
                          (category.payment_status === 2 && "c3 text-white")
                        }`}
                      >
                        {invoice_choices[category.payment_status]}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* ----------2nd Table for pdf print------*/}
        {/* entites and paggination */}
        {totalData > 0 && (
          <div className="row pag" style={{ marginTop: "30px" }}>
            <div className="col-6 col-md-5">
              <p
                className="inter"
                style={{ color: "#AEAEB2", fontSize: "16px" }}
              >
                Total Entries:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {totalData}
                </span>
                <span className="ms-2"></span>
                Total Page:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {Math.ceil(totalData / 20)}
                </span>
              </p>
            </div>
            {/* <div className="col-12 col-md-6 d-flex paggination-button">
              <ReactPaginate
                previousLabel={<PreviousIcon />}
                nextLabel={<NextIcon />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"a"}
                nextLinkClassName={"a"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                forcePage={
                  query?.length > 0 || getData?.length < usersPerPage
                    ? 0
                    : updatedPageNumber
                }
              />
            </div> */}
            <div className="col-12 col-md-6 d-flex paggination-button">
              <>
                <div className="row lead_pagination bg-transparent">
                  <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                    <nav aria-label="...">
                      <ul className="pagination pagination-sm">
                        {!isLoading ? (
                          <li className="page-item ">
                            {prevUrl !== null ? (
                              <Link
                                onClick={handlePaginationPrevious}
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on first page")
                                }
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item ">
                            <Link
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          </li>
                        )}

                        {[...Array(pageCount === null ? 1 : pageCount)].map(
                          (elem, index) => (
                            <li
                              className="page-item"
                              style={{
                                display: `${
                                  index + 1 !== pageNumber ? "none" : "block"
                                }`,
                              }}
                            >
                              {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                              {/* setPageNumber(prev=> prev - 1) */}

                              <Link
                                onClick={() =>
                                  handleChangeCurrentPage(index + 1)
                                }
                                className={`${
                                  index + 1 == pageNumber
                                    ? "paginationActive"
                                    : ""
                                } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                to="#"
                              >
                                <span className="px-1 py-1">{pageNumber}</span>
                              </Link>
                            </li>
                          )
                        )}
                        {!isLoading ? (
                          <li className="page-item">
                            {nextUrl !== null ? (
                              <Link
                                onClick={handlePaginationNext}
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />{" "}
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on last page")
                                }
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item">
                            <Link
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExchangeList;

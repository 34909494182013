import React from "react";
import AreaChart from "../dashboard/AreaChart";
import AreaChartComponents from "./AreaChartComponents";
import BarChartComponents from "./BarChartComponents";
import "./Report.css";

const Reports = () => {
  
  

  return (
    <div className="categorey-parent ">
      
      <div className="row bg-white mr-box2 rounded">
          <AreaChart chartLabel='Sales Report' />
      </div>
      <div className="row mt-4 mr-box2   ">
        <div className="col-12 ">
          <div className="  bg-white rounded px-2">
            <BarChartComponents/>
          </div>
        </div>
        
      </div>
     
    </div>
  );
};

export default Reports;

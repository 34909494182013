import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SingleNav = ({
  name,
  icon: Icon,
  to,
  toggle,
  SetCollapseState,
  CollapseState,
  count,
}) => {
  const location = useLocation();
  // const is_open = localStorage.getItem("open").toString();
  const [is_open, Setis_open] = useState(0);
  const HandleClickDropdown = () => {
    SetCollapseState({
      Inventory: false,
      Sales: false,
      User: false,
      Reports: false,
    });
  };
  useEffect(() => {
    Setis_open(toggle);
  }, [toggle]);
  return (
    <Link
      onClick={HandleClickDropdown}
      to={to}
      className={`nav__link  ${location.pathname === to && "active__menu"}  ${
        !is_open ? "reduce_width" : ""
      }`}
    >
      <Icon
        className={`${
          location.pathname === to ? "active__menu_nav__icon" : "nav__icon"
        }`}
        size={24}
      />
      {is_open && (
        <span
          className={`${
            location.pathname === to ? "active__menu_nav__name" : "nav__name"
          }`}
        >
          {name}
          {count > 0 && (
            <span
              style={{
                width: "17px",
                height: "17px",
                borderRadius: "50%",
                backgroundColor: "red",
                color: "white",
                padding: "2px 6px",
                marginLeft: "5px",
                fontSize: "13px",
              }}
            >
              {count}
            </span>
          )}
        </span>
      )}{" "}
    </Link>
  );
};

export default SingleNav;

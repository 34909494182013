import { CaretRight, Cube, HouseLine, ShoppingCart } from "phosphor-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Sublink from "./Sublink";

const DropDownNav = ({
  name,
  icon: Icon,
  sublinks,
  toggle,
  SetCollapseState,
  CollapseState,
  count,
  object,
}) => {
  // const [collapse, setCollapse] = useState(0);
  const [active, setactive] = useState(false);
  const location = useLocation();
  const [is_open, Setis_open] = useState(0);
  const collapse = CollapseState !== undefined && CollapseState[name];
  useEffect(() => {
    Setis_open(toggle);
  }, [toggle]);
  useEffect(() => {
    const sublink_to = sublinks.map((curr) => curr.to);
    for (let index = 0; index < sublink_to.length; index++) {
      const curr = sublink_to[index];
      if (curr === location.pathname) {
        setactive(true);
        break;
      } else {
        setactive(false);
      }
    }
  }, [location]);

  const HandleClickDropdown = () => {
    SetCollapseState({
      Inventory: name !== "Inventory" ? false : !CollapseState[name],
      Sales: name !== "Sales" ? false : !CollapseState[name],
      User: name !== "User" ? false : !CollapseState[name],
      Reports: name !== "Reports" ? false : !CollapseState[name],
      Customers: name !== "Customers" ? false : !CollapseState[name],
      Custom: name !== "Custom" ? false : !CollapseState[name],
      Purchase: name !== "Purchase" ? false : !CollapseState[name],
      Settings: name !== "Settings" ? false : !CollapseState[name],
      Promotions: name !== "Promotions" ? false : !CollapseState[name],
      Requisition: name !== "Requisition" ? false : !CollapseState[name],
      Outlets: name !== "Outlets" ? false : !CollapseState[name],
      Relocation: name !== "Relocation" ? false : !CollapseState[name],
    });
  };

  return (
    <div className={`dropdown_container `}>
      <div
        className={`nav__link Nav_collapse   ${active && "active__menu"} ${
          !is_open ? "reduce_width" : ""
        } `}
        onClick={HandleClickDropdown}
      >
        <Icon
          className={`${active ? "active__menu_nav__icon" : "nav__icon"}`}
          size={24}
        />
        {is_open && (
          <span
            className={`${active ? "active__menu_nav__name" : "nav__name"}`}
          >
            {name}
            {count > 0 && name === 'Sales' && (
              <span
                style={{
                  width: "17px",
                  height: "17px",
                  borderRadius: "50%",
                  backgroundColor: "red",
                  color: "white",
                  padding: "2px 6px",
                  marginLeft: "5px",
                  fontSize: "13px",
                  justifyContent: "end",
                }}
              >
                {count }
              </span>
            )}
          </span>
        )}
        <CaretRight
          className={`collapse__link  ${
            active ? "active__down_arrow" : "down_arrow"
          } ${collapse && "rotate"}`}
        />
      </div>
      <ul className={`collapse__menu drop_down ${collapse && "showCollapse"}`}>
        {sublinks.map((current) => {
          // current.to === location.pathname && location.pathname!=="" && !active&& setactive(true);
          return (
            <>
              <div className="d-flex">
                <Sublink to={current.to} data={current.name} />
                {current?.name === "Invoice List" && object?.invoice > 0 && (
                  <span
                    style={{
                      width: "17px",
                      height: "17px",
                      borderRadius: "50%",
                      backgroundColor: "red",
                      color: "white",
                      textAlign: "center",
                      marginLeft: "5px",
                      fontSize: "13px",
                    }}
                  >
                    {object?.invoice > 9 ? '9+' : object?.invoice}
                  </span>
                )}
                {current?.name === "Review" && object?.review > 0 && (
                  <span
                    style={{
                      width: "17px",
                      height: "17px",
                      borderRadius: "50%",
                      backgroundColor: "red",
                      color: "white",
                      textAlign: "center",
                      marginLeft: "5px",
                      fontSize: "13px",
                    }}
                  >
                    {object?.review > 9 ? '9+' : object?.review}
                  </span>
                )}
               
                {current?.name === "Contact Us" && object?.contact_us > 0 && (
                  <span
                    style={{
                      width: "17px",
                      height: "17px",
                      borderRadius: "50%",
                      backgroundColor: "red",
                      color: "white",
                      textAlign: "center",
                      marginLeft: "5px",
                      fontSize: "13px",
                    }}
                  >
                    {object?.contact_us > 9 ? '9+' : object?.contact_us}
                  </span>
                )}
                {current?.name === "Work With Us" && object?.work_us > 0 && (
                  <span
                    style={{
                      width: "17px",
                      height: "17px",
                      borderRadius: "50%",
                      backgroundColor: "red",
                      color: "white",
                      textAlign: "center",
                      marginLeft: "5px",
                      fontSize: "13px",
                    }}
                  >
                    {object?.work_us > 9 ? '9+' : object?.work_us}
                  </span>
                )}
              </div>
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default DropDownNav;

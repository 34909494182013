import React from "react";
import "./css/category.css";
import add from "./../../../assets/Icon/add.svg";
// import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import eye from "./../../../assets/Icon/eye.svg";
import { InputGroup } from "react-bootstrap";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import SelectDropDown from "../../CustomCommons/SelectDropDown";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { handleInputs } from "../../../utils/HandleInputs";
import { showToast } from "../../../utils/ToastHelper";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import * as path from "../../Routes/RoutePaths";
import Loader from "../../CustomCommons/Loader";
import { logout_func2, logout_func3 } from "../../Const/logoutCommon";
import SelectDropDown2 from "../../CustomCommons/SelectDropDown2";

const EditCategory = () => {
  const history = useHistory();
  const { id } = useParams();
  const [categoryList, setcategoryList] = useState({});
  const [isLoading, SetisLoading] = useState(true);
  const [categoryAllData, setcategoryAllData] = useState({
    category_name: "",
    category_description: "",
  });
  console.log(categoryList);
  const [Categoryswitch, setCategoryswitch] = useState(true);

  const [selectedOptionCategory, setSelectedOptionCategory] = useState(null);
  const [mainCategory, setMainCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [fileName, setFileName] = useState("");
  const [URL, setURL] = useState("");
  const [Thumb, setThumb] = useState("");
  const [thumbId, setThumbId] = useState(null);
  const [editedThumb, setEditedThumb] = useState("");
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    console.log(e, "eeeee");
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setEditedThumb(e.target.files[0]);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
          }
          console.log(res.data.data, "img");
          showToast("success", "Image Uploaded");
        })
        .catch((error) => {
          // console.error("An error occurred:", error.response.data.errors.document[0]);
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };

 

  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const mainCategorySelect = [
    { value: "0", label: "Home Decor" },
    { value: "1", label: "In Style" },
  ];

  const [subMainCategoryType, setsubMainCategoryType] = useState([]);
  const [otherOption, setOtherOption] = useState(true);
  const subMainCategoryTypeSelect = [
    { value: "1", label: "Sub Main Category" },
    { value: "2", label: "Others Category" },
  ];
  const getCategory = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/category/${id}`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data;
        setcategoryList(result);
        // setURL(categoryList.thumb);
        setcategoryAllData({
          category_name: result.name,
          category_description: result.description,
          parent_value: result.parent_name,
          parent_label: result.parent,
        });
        setURL(result.thumb_url);
        SetisLoading(false);
        setSelectedOptionCategory(res.data.data.parent_name);
        setMainCategory(mainCategorySelect[res.data.data?.main_category]);
        setSubCategory(res.data.data?.sub_main_category);
        setsubMainCategoryType(
          res.data.data?.category_type === 1
            ? [{ value: "1", label: "Sub Main Category" }]
            : [{ value: "2", label: "Others Category" }]
        );
        setOtherOption(res.data.data?.category_type === 2 ? true : false);
        setCategoryswitch(result?.is_active);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
        // console.log(err.request.response);
      });
  };

  const [allCat, setAllCat] = useState([]);
  const [catDefval, setCatDifVal] = useState([]);

  
 

  const allCategorey = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/category/?category_type=1&main_category=${
      Array.isArray(mainCategory) === true
        ? mainCategory[0]?.value
        : mainCategory?.value
    }`;
    axios
      .get(url)
      .then((res) => {
        // console.log(res.data);

        SetisLoading(true);
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        setCatDifVal(
          options?.filter(
            (data) => data?.value === categoryList?.sub_main_category
          )
        );
       
        setAllCat(options);
        SetisLoading(false);

       
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        // console.log(err.request.response);
      });
  };
 
 
  const mainVal = (newVal) => {
    setMainCategory(newVal);
  };

 
  const updateCategory = async () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/category/${id}/`;
    const data = new FormData();
    data.append("name", categoryAllData["category_name"]);
    data.append("description", categoryAllData["category_description"]);

    if (thumbId) {
      data.append("thumb", thumbId);
    }

    if (
      selectedOptionCategory !== null &&
      selectedOptionCategory !== undefined &&
      selectedOptionCategory.value !== null &&
      selectedOptionCategory.value !== undefined &&
      typeof selectedOptionCategory.value !== "string"
    ) {
      data.append("parent_name", selectedOptionCategory.value);
    }
    if (mainCategory !== null && mainCategory !== undefined) {
      data.append(
        "main_category",
        Array.isArray(mainCategory) === true
          ? mainCategory[0]?.value
          : mainCategory?.value
      );
    }
    if (
      (subCategory !== null &&
        subCategory !== undefined &&
        subCategory.value !== null &&
        subCategory.value !== undefined) ||
      otherOption === true
    ) {
      data.append("sub_main_category", subCategory);
    }
    if (
      subMainCategoryType !== null &&
      subMainCategoryType !== undefined &&
      subMainCategoryType.value !== null &&
      subMainCategoryType.value !== undefined
    ) {
      data.append(
        "category_type",
        Array.isArray(subMainCategoryType) === true
          ? subMainCategoryType[0]?.value
          : subMainCategoryType?.value
      );
    }
    data.append("is_active", Categoryswitch);

    await axios
      .patch(url, data)
      .then((res) => {
        if (res.data.status) {
          // clearData();
          showToast("success", "Category Updated.");
          history.push(path.category_list);
        }
        getCategory();
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        // console.log(errorMsg);
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  useEffect(() => {
    getCategory();
  }, [otherOption]);
  useEffect(() => {
    allCategorey();
  }, [mainCategory, subMainCategoryType]);
  
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  if (isLoading || !catDefval) {
    return <Loader />;
  }

  
   

  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          {/* <img src={add} alt="" /> */}
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>Edit Category</h3>
        </div>
        {/* --------------category form--------- */}
        <div
          style={{
            padding: "20px 35px ",
            borderBottom: " 0.5px solid #E0E0E0",
            borderRight: " 0.5px solid #E0E0E0",
            borderLeft: "0.5px solid #E0E0E0",
          }}
        >
          {/* -----------name-------------- */}
          {/* <RequiredLabel text={"Name"} /> */}
          <p>Name</p>
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Username"
              aria-describedby="basic-addon1"
              style={{ backgroundColor: "#FAFAFA" }}
              name="category_name"
              value={categoryAllData["category_name"]}
              // placeholder={categoryList.name}
              onChange={(e) => handleInputs(e, setcategoryAllData)}
            />
          </InputGroup>
          {/* ---------------parent---------------- */}

         
            
          
          {/* main Category */}
          <RequiredLabel text={"Main Category"} />
          <div className="mb-3" style={{ backgroundColor: "#FAFAFA" }}>
            <Select
              placeholder="---Select---"
              options={mainCategorySelect}
              onChange={mainVal}
              defaultValue={
                categoryList?.main_category === 0
                  ? mainCategorySelect[0]
                  : mainCategorySelect[1]
              }
            />
          </div>
          <RequiredLabel text={"Category Type"} />
          <div className="mb-3" style={{ backgroundColor: "#FAFAFA" }}>
            <SelectDropDown2
              options={subMainCategoryTypeSelect}
              setSelectedOptionCategory={setsubMainCategoryType}
              deafaultV={subMainCategoryType}
            />
          </div>
          {/* Sub Category */}

          
          {subMainCategoryType[0]?.value === '2' || subMainCategoryType?.value === '2' ? (
            <>
              <RequiredLabel text={"Sub Category"} />
              {/*<div className="mb-3" style={{ backgroundColor: "#FAFAFA" }}>
                 <Select
                  placeholder="---Select---"
                  options={allCat}
                  onChange={subVal}
                  defaultValue={
                    // categoryList !== null &&
                    // subCategorySelect[categoryList?.sub_main_category]
                    catDefval[0]
                    
                  }
                /> 
              </div>*/}

              <div className="" style={{ height: "47px" }}>
                <Form.Select
                  size="lg"
                  placeholder="---Select---"
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  {allCat.map((data) => (
                    <option value={data?.value}>{data?.label}</option>
                  ))}
                </Form.Select>
              </div>
            </>
          ) : (
            <></>
          )}

          <p>Description</p>
          <Form.Control
            as="textarea"
            className="mb-3"
            style={{
              height: "100px",
              resize: "none",
              backgroundColor: "#FAFAFA",
            }}
            // placeholder={categoryList.description}
            name="category_description"
            value={categoryAllData["category_description"]}
            onChange={(e) => handleInputs(e, setcategoryAllData)}
          />
          {/* --------img----------- */}
          

          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Thumbnail image</h5>
              <p style={{ marginTop: "10px",color:'red' }}> Upload image in  (height-300px, width-300px) . </p>{" "}
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {fileName === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    You can upload or change
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {fileName}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => fileHandle(e)}
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {/* { (
                <div className="my-2">
                  <img height={90} width={90} src={URL ? URL : categoryList.thumb} alt="" />
                  
                </div>
              )} */}
              {URL && (
                <div className="my-2">
                  <img height={90} width={90} src={URL} alt="" /> <br />
                  <button
                    onClick={removeImg}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>

            {/* ----------status--------- */}
            <div className="col-12 col-md-5">
              <h5 style={{ marginTop: "30px" }}>Status</h5>
              <p style={{ color: "#8E8E93" }}>
                ( If the Category Option is available )
              </p>
              <div className="row mr-4">
                <div
                  className=" d-flex justify-content-between col-12 py-3"
                  style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
                >
                  <div className="d-flex w-100">
                    <div
                      className="d-flex py-1 justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#212121",
                        width: "32px",
                        height: "32",
                        borderRadius: "3px",
                      }}
                    >
                      <img src={eye} alt="" />
                    </div>
                    <span className="mx-3">
                      {Categoryswitch ? "ON" : "OFF"}
                    </span>
                  </div>

                  <div class="form-check form-switch ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      width={40}
                      height={40}
                      name="category_active"
                      checked={Categoryswitch}
                      onClick={toggleSwitch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
              onClick={updateCategory}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.category_list)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;

import axios from "axios";
import React, { useState } from "react";
import { MoonLoader } from "react-spinners";
import swal from "sweetalert";

const HelperExportComponent = ({ url, fileName, items_data }) => {
  const [isLoading, setIsLoading] = useState(false);

  const getExportFile = (url, fileName) => {
    setIsLoading(true);
    axios({
      url: url,
      method: "GET",
      responseType: "blob", // Important
    })
      .then((response) => {
        // Create blob object
        const blob = new Blob([response.data], { type: response.data.type });
        // Create URL for the blob
        const fileUrl = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = fileUrl;

        // Set the filename
        link.setAttribute("download", fileName);

        // Simulate click to download
        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(fileUrl);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        // Handle error
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {items_data?.length !== 0 ? (
        <>
          {isLoading ? (
            <span className="d-flex align-items-center gap-1">
              <MoonLoader color="white" size={10} /> {"Downloading..."}
            </span>
          ) : (
            <span onClick={() => getExportFile(url, fileName)}>{"Export"}</span>
          )}
        </>
      ) : (
        <span
          onClick={() =>
            swal({
              title: "Error!",
              text: "There Is No Data",
            })
          }
        >
          Export
        </span>
      )}
    </>
  );
};

export default HelperExportComponent;

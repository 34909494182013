import React from "react";
import { Line } from "react-chartjs-2";
// import * as faker from '@faker-js/faker'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import { BASE_URL } from "../Const/Url";
import { useState } from "react";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
  tooltips: {
    callbacks: {
      title: "hlw",
      label: "hi",
    },
  },
};

const CustomSalesChart = ({ chartLabel }) => {
  const [filterQuery, setFilterQuery] = useState("week");
  const [labels, setlabels] = useState([]);
  const [ChartData, setChartData] = useState([]);
  // const yData = [200, 20, 70, 100, 20, 200, 350];
  const [dailyReport, setDailyReport] = useState([]);

  const getReport = () => {
    const url = `${BASE_URL}api/v1/sales/inventory/chart_data1/?filter=${filterQuery}&is_custom=true`;
    axios
      .get(url)
      .then((res) => {
        setlabels(Object.keys(res.data.data.data));
        setChartData(Object.values(res.data.data.data));
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    getReport();
  }, [filterQuery]);

  // console.log(ChartData,'-=-------------=============')
  // console.log(labels,'-=-------------=============')

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        Tooltip: false,
        label: chartLabel,
        data: ChartData,
        borderColor: " rgba(144, 238, 2, 1)",
        backgroundColor: "#00aeef",
        lineTension: 0.2,
      },
    ],
  };
  return (
    <div className="invoice-main mb-4">
      <div className="d-flex justify-content-end pointer filter-select">
        <select
          name=""
          onChange={(e) => setFilterQuery(e.target.value)}
          id=""
          style={{ boderRadius: "4px" }}
        >
          <option value="week">Weekly</option>
          <option value="month">Monthly</option>
        </select>
      </div>
      <Line className="area-h" Tooltip options={options} data={data} />
    </div>
  );
};

export default CustomSalesChart;
